import React, {useState} from 'react';
import {Button, Col, Modal, Row, Table} from 'react-bootstrap';
import FilteredDatePicker from 'components/ui/FilteredDatePicker/FilteredDatePicker';
import SubmitButton from 'components/ui/Button/SubmitButton';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {formatNumber} from 'helpers/common';
import {IFxTransactionDetail} from 'types/transactions';
import {
    useChangeDealDateRequest,
    useConfirmDealRequest,
} from 'api/deal-request';
import {IDealRequest} from 'types/order';
import useModal from 'components/ui/ModalContainer/useModal';
import AlertOverlay from 'components/ui/Overlays/AlertOverlay/AlertOverlay';
import {formatDateInZurichTZ} from 'helpers/dates';
import Alert from 'components/ui/Alert/Alert';
import {TIMEOUT_DEAL_ERROR} from 'constants/common';

import styles from './SimpleOrderEditModal.module.scss';

interface IProps {
    transaction: IFxTransactionDetail;
    onClose: () => void;
}

const SimpleOrderEditModal: React.FC<IProps> = ({transaction, onClose}) => {
    const {openModalWithContent, closeModal} = useModal();
    const [error, setError] = useState('');
    const [activeDeal, setActiveDeal] = useState<IDealRequest>();
    const {
        id,
        platformRate,
        amountFrom,
        amountTo,
        currencyFrom,
        currencyTo,
        dealType,
        forwardPips,
        spotRate,
        valueDate: currentDate,
    } = transaction;

    const [
        changeDealDate,
        {isLoading: isChangeDateLoading},
    ] = useChangeDealDateRequest();
    const [
        confirmDealRequest,
        {isLoading: isChangeDateConfirmLoading},
    ] = useConfirmDealRequest();

    const [valueDate, setValueDate] = useState(
        formatDateInZurichTZ(currentDate),
    );
    const isConfirmation = Boolean(activeDeal);

    const handleError = (e: any) => {
        const message = e['hydra:description'] || 'Something wrong';
        setError(message);
        setTimeout(() => setError(''), TIMEOUT_DEAL_ERROR);
    };

    const handleDateChange = (trx: IFxTransactionDetail) => {
        changeDealDate(trx, {
            onSuccess: (deal: IDealRequest) => {
                setActiveDeal(deal);
            },
            onError: handleError, // TODO
        });
    };

    const handleConfirm = () => {
        if (isConfirmation) {
            confirmDealRequest(activeDeal, {
                onSuccess: () => {
                    closeModal('simpleOrder');
                    openModalWithContent(
                        'alert',
                        <AlertOverlay
                            onConfirm={() => closeModal('alert')}
                            title="Success"
                            description="You have successfully amended the transaction. Please be aware that any corresponding payments also have to be adjusted."
                        />,
                    );
                },
                onError: handleError,
            });
        } else {
            handleDateChange({...transaction, valueDate});
        }
    };

    const pickerProps = {
        currencyFrom,
        currencyTo,
        value: valueDate,
        onChange: setValueDate,
    };

    return (
        <>
            <Modal.Header>
                <Modal.Title as="h5">
                    Adjustment transaction no.{' '}
                    <var data-var="transactionId">{id}</var>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Table className={styles.table} responsive borderless>
                            <thead>
                                <tr>
                                    <th />
                                    <th>Fixed Deal</th>
                                    <th>New Value Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Value date</td>
                                    <td data-notranslate>
                                        {formatDateInZurichTZ(
                                            transaction.valueDate,
                                        )}
                                    </td>
                                    <td>
                                        {isConfirmation ? (
                                            activeDeal.valueDate
                                        ) : (
                                            <FilteredDatePicker
                                                {...pickerProps}
                                            />
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Spot</td>
                                    <td data-notranslate>
                                        {formatNumber(spotRate, 4)}
                                    </td>
                                    <td data-notranslate>
                                        {isConfirmation
                                            ? formatNumber(
                                                  activeDeal.spotRate,
                                                  4,
                                              )
                                            : '-'}
                                    </td>
                                </tr>
                                {dealType === 'Forward' ||
                                (isConfirmation &&
                                    activeDeal.dealType ===
                                        'forward_transaction') ? (
                                    <tr>
                                        <td>Forward points</td>
                                        <td data-notranslate>
                                            {formatNumber(forwardPips, 4)}
                                        </td>
                                        <td data-notranslate>
                                            {isConfirmation
                                                ? formatNumber(
                                                      activeDeal.forwardPips,
                                                      4,
                                                  )
                                                : '-'}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td className={styles.bold}>
                                        Your exchange rate
                                    </td>
                                    <td
                                        className={styles.bold}
                                        data-notranslate
                                    >
                                        {formatNumber(platformRate, 4)}
                                    </td>
                                    <td
                                        className={styles.bold}
                                        data-notranslate
                                    >
                                        {isConfirmation
                                            ? formatNumber(
                                                  activeDeal.platformRate,
                                                  4,
                                              )
                                            : '-'}
                                    </td>
                                </tr>
                                <tr>
                                    <td>You buy</td>
                                    <td>
                                        <CurrencyFlag
                                            code={currencyTo}
                                            text={formatNumber(amountTo)}
                                        />
                                    </td>
                                    <td>
                                        {isConfirmation ? (
                                            <CurrencyFlag
                                                code={currencyTo}
                                                text={formatNumber(
                                                    activeDeal.amountTo,
                                                )}
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                                <tr>
                                    <td>You sell</td>
                                    <td>
                                        <CurrencyFlag
                                            code={currencyFrom}
                                            text={formatNumber(amountFrom)}
                                        />
                                    </td>
                                    <td>
                                        {isConfirmation ? (
                                            <CurrencyFlag
                                                code={currencyFrom}
                                                text={formatNumber(
                                                    activeDeal.amountFrom,
                                                )}
                                            />
                                        ) : (
                                            '-'
                                        )}
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                {error ? (
                    <Row>
                        <Col md={12}>
                            <Alert className="text-center" message={error} />
                        </Col>
                    </Row>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
                <SubmitButton
                    onClick={handleConfirm}
                    disabled={
                        isChangeDateLoading ||
                        isChangeDateConfirmLoading ||
                        (!isConfirmation &&
                            valueDate ===
                                formatDateInZurichTZ(transaction.valueDate))
                    }
                    isLoading={
                        isChangeDateLoading || isChangeDateConfirmLoading
                    }
                >
                    {isConfirmation ? 'Confirm' : 'Change date'}
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};

export default SimpleOrderEditModal;
