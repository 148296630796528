import React from 'react';
import classNames from 'classnames';
import {Col, Form} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import {Controller} from 'react-hook-form';
import Label from '../Label/Label';
import styles from '../InputGroup/InputGroup.module.scss';

const NumberInput = ({
    control,
    name,
    label = '',
    error,
    className = '',
    xs = undefined,
    sm = undefined,
    md = undefined,
    lg = undefined,
    xl = undefined,
    rules = {},
    defaultValue = '',
    groupClassName = '',
    onValueChange = (e: any) => null,
    ...rest
}) => {
    const classNameNumberInput = error
        ? classNames(rest.className, 'is-invalid form-control')
        : classNames(className, 'form-control');

    const numberInputProps = {
        ...rest,
        name,
        className: classNameNumberInput,
        isNumericString: true,
        thousandSeparator: '’',
        decimalScale: 2,
    };

    return (
        <Form.Group
            as={Col}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            controlId={name}
            className={groupClassName}
        >
            {label ? <Label md={6}>{label}</Label> : null}
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={props => {
                    return (
                        <NumberFormat
                            {...props}
                            {...numberInputProps}
                            data-notranslate
                            onChange={e => {
                                if (onValueChange) {
                                    onValueChange(e);
                                }
                                props.onChange(e);
                            }}
                        />
                    );
                }}
            />
            {error ? (
                <Form.Control.Feedback type="invalid" className={styles.error}>
                    {error.message}
                </Form.Control.Feedback>
            ) : null}
        </Form.Group>
    );
};

export default NumberInput;
