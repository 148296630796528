// FIELDS
export const FIELD_BUY_CURR = 'currencyTo';
export const FIELD_BUY_AMOUNT = 'amountTo';
export const FIELD_SELL_CURR = 'currencyFrom';
export const FIELD_SELL_AMOUNT = 'amountFrom';
export const FIELD_DATE = 'valueDate';
export const FIELD_NEAR_DATE = 'nearDate';
export const FIELD_FAR_DATE = 'farDate';
export const FIELD_RATE = 'rate';

export const DEAL_TYPE_LIMIT = 'limit';
