import React from 'react';

interface IPageTitle {
    description?: React.ReactNode;
    /**
     * @deprecated
     */
    className?: string;
}

const PageTitle: React.FC<IPageTitle> = ({children, description}) => (
    <div>
        <h4>{children}</h4>
        {description ? <p>{description}</p> : null}
    </div>
);

export default PageTitle;
