import React from 'react';

const SuspendCards = (props: React.HTMLAttributes<SVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                d="M6.087 13.11a1.658 1.658 0 1 1 0-1.834 1.658 1.658 0 1 1 0 1.834Zm15.285.165h1.35V19.7h-1.35v-6.425Zm-3.062 0h1.35V19.7h-1.35v-6.425Z"
            />
            <path
                fill="currentColor"
                fillRule="evenodd"
                d="M18.343 4.318H1.732v2.065h16.611V4.318ZM1.732 15.039V9.16h16.611v2.816h1.317V3H.415v13.403h16.594v-1.364H1.732Zm16.578 0v1.364h1.323v-3.128h-1.29v1.764h-.035Z"
                clipRule="evenodd"
            />
        </svg>
    );
};

export default SuspendCards;
