import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Controller, useFormContext} from 'react-hook-form';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import NumberFormat from 'react-number-format';
import {translate} from 'helpers/localize';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './NumberInput.module.scss';

const NumberInput = (
    props: IDefaultControlProps & {
        defaultValue?: string;
        onFocus?: React.FocusEventHandler<HTMLInputElement>;
        getInputRef?: (el: HTMLInputElement) => void;
        onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>;
        labelClassName?: string;
        errorClassName?: string;
        containerClassName?: string;
        endAdornment?: React.ReactNode;
    },
) => {
    const {
        name,
        label,
        placeholder,
        labelClassName,
        errorClassName,
        info,
        disabled,
        readOnly,
        size,
        variant,
        rounded = true,
        registerOptions,
        defaultValue,
        onChange,
        onFocus,
        decimalScale = 2,
        maxLength,
        autoFocus,
        onKeyUp,
        getInputRef,
        containerClassName,
        className,
        endAdornment,
        onBlur,
    } = props;
    const {errors, control, setValue} = useFormContext();

    const numberInputProps = {
        name,
        isNumericString: true,
        decimalScale,
    };
    const [ref, setRef] = useState<HTMLInputElement>();
    const [shouldFocus, setShouldFocus] = useState(true);

    useEffect(() => {
        if (shouldFocus && autoFocus && ref) {
            ref.focus();
            setShouldFocus(false);
        }
    }, [autoFocus, ref, shouldFocus]);

    const message: string = getDeepValue(errors, name)?.message;

    return (
        <div
            className={classNames(styles.container, containerClassName, {
                [styles.adornmentContainer]: !!endAdornment,
            })}
        >
            {label ? (
                <label
                    htmlFor={name}
                    className={classNames(styles.label, labelClassName)}
                >
                    {label}
                    {info ? <InfoPopover content={info} /> : null}
                </label>
            ) : null}
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={registerOptions}
                render={controllerProps => {
                    return (
                        <>
                            <NumberFormat
                                getInputRef={(_ref: HTMLInputElement) => {
                                    setRef(_ref);
                                    if (getInputRef) {
                                        getInputRef(_ref);
                                    }
                                }}
                                {...controllerProps}
                                {...numberInputProps}
                                fixedDecimalScale
                                className={classNames(
                                    styles.input,
                                    styles.amountInput,
                                    className,
                                    {
                                        [styles.invalid]: getDeepValue(
                                            errors,
                                            name,
                                        ),
                                        [styles.dark]: variant === 'dark',
                                        [styles.rounded]: rounded,
                                        [styles.large]: size === 'large',
                                        [styles.disabled]: disabled || readOnly,
                                        [styles.withEndAdornment]: !!endAdornment,
                                    },
                                )}
                                readOnly={readOnly}
                                disabled={disabled}
                                placeholder={translate(placeholder) || '0.00'}
                                data-notranslate
                                onBlur={e => {
                                    setValue(name, e.target.value);
                                    if (onBlur) onBlur(e);
                                }}
                                onChange={event => {
                                    controllerProps.onChange(event);

                                    if (onChange) {
                                        onChange(event);
                                    }
                                }}
                                onKeyUp={onKeyUp}
                                onFocus={onFocus}
                                maxLength={maxLength}
                                autoFocus={autoFocus}
                            />
                            {endAdornment ? (
                                <div className={styles.endAdornment}>
                                    {endAdornment}
                                </div>
                            ) : null}
                        </>
                    );
                }}
            />
            {message ? (
                <ErrorFeedback
                    message={message}
                    errorClassName={
                        !message.includes('required') ? errorClassName : null
                    }
                />
            ) : null}
        </div>
    );
};

export default NumberInput;
