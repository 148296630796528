import {useQuery} from 'react-query';
import {translate} from 'helpers/localize';
import {useClient} from 'context/auth-context';
import {LEGAL_FORM} from 'constants/cache';

export interface ILegalForm {
    '@context': '/web_api/contexts/LegalForm';
    '@type': 'LegalForm';
    '@id': string;
    value: string;
}

function useLegalForms(): {legalForms: ILegalForm[]} {
    const client = useClient();

    const result = useQuery('legal-forms', () =>
        client('/web_api/legal_forms?order[position]=ASC', {})
            // fake the ID, API needs to be changed
            .then(legalForms =>
                legalForms.map((legalForm, index) => ({
                    ...legalForm,
                    value: legalForm['@id'],
                    label: translate(legalForm.value),
                })),
            ),
    );

    return {...result, legalForms: result.data ?? []};
}

const useLegalForm = (legalFormId?: string) => {
    const authenticatedClient = useClient();

    return useQuery<ILegalForm>(
        [LEGAL_FORM, {id: legalFormId}],
        () => authenticatedClient(legalFormId, {}),
        {enabled: !!legalFormId},
    );
};

export {useLegalForms, useLegalForm};
