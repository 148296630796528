import {queryCache, useMutation, useQuery} from 'react-query';
import qs from 'qs';
import {useClient} from 'context/auth-context';
import {
    AMNIS_BANK_ACCOUNTS,
    CONTACT_BANK_ACCOUNTS_CACHE,
    IBAN_QUERY_CACHE,
} from 'constants/cache';
import {IAmnisBankAccount, IBankAccount, IBankAccountCreate} from 'types/api';

const useCreateBankAccountRequest = () => {
    const client = useClient();

    return useMutation<IBankAccount, unknown, IBankAccountCreate>(
        data => {
            return client('/web_api/bank_accounts', {
                data,
                method: 'POST',
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([AMNIS_BANK_ACCOUNTS]);
                queryCache.invalidateQueries([CONTACT_BANK_ACCOUNTS_CACHE]);
            },
        },
    );
};

const useEditBankAccount = () => {
    const client = useClient();

    return useMutation<
        IBankAccount,
        unknown,
        Pick<IBankAccount, '@id' | 'name'>
    >(
        data => {
            return client(data['@id'], {
                data: {
                    name: data.name,
                },
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([AMNIS_BANK_ACCOUNTS]);
                queryCache.invalidateQueries([CONTACT_BANK_ACCOUNTS_CACHE]);
            },
        },
    );
};

const useCheckIban = (iban: string, onSucess: Function) => {
    const client = useClient();
    return useQuery(
        [IBAN_QUERY_CACHE, {id: iban}],
        () => client(`/web_api/iban_check/${iban}`, {}),
        {
            enabled: iban,
            onSuccess: data => onSucess(data),
        },
    );
};

export interface IAmnisBank {
    '@id': string;
    '@type': string;
    address: string;
    id: number;
    name: string;
    swift: string;
}

const useGetAmnisBankAccounts = (currencies: string[]) => {
    const client = useClient();
    const currenciesString = qs.stringify(
        {currency: currencies},
        {
            encode: false,
        },
    );

    return useQuery<IAmnisBankAccount[]>(
        [AMNIS_BANK_ACCOUNTS, {currenciesString}],
        () => client(`/web_api/amnis_bank_accounts?${currenciesString}`, {}),
        {
            enabled: currencies?.length,
        },
    );
};

export {
    useCreateBankAccountRequest,
    useCheckIban,
    useGetAmnisBankAccounts,
    useEditBankAccount,
};
