import {useMutation, useQuery, QueryResult, queryCache} from 'react-query';
import {client} from 'helpers/api-client';
import {useClient, useAuth} from 'context/auth-context';
import {
    GET_AUTH_FACTORS,
    GET_FUTURAE_INFO,
    GET_QR_AUTH_STATUS,
    USER_CACHE,
} from 'constants/cache';
import {Permission} from 'types/api';
import {PartialWithRequired} from 'types/utils/utilitytypes';
import {PATCH_HEADERS} from 'api/constants';

export type AuthFactors = 'approve' | 'sms';
export type AuthTypes =
    | 'login'
    | 'reset_password'
    | 'payout_transaction_detail'
    | 'get_pin'
    | 'secure_info'
    | 'onboarding'
    | 'update-permission';

interface IBaseAuth {
    type: AuthTypes;
    factor?: AuthFactors;
    passcode?: string;
    token?: string;
}
interface IOnboardingAuth extends IBaseAuth {
    type: 'onboarding';
}
interface ILoginAuth extends IBaseAuth {
    type: 'login';
}
interface IResetPwAuth extends IBaseAuth {
    type: 'reset_password';
}

interface IPayoutAuth extends IBaseAuth {
    type: 'payout_transaction_detail';
    transactionId: string;
}

interface IChangePermissionAuth extends PartialWithRequired<Permission, '@id'> {
    type: 'update-permission';
}

interface ICardAuth extends IBaseAuth {
    type: 'get_pin' | 'secure_info';
    cardId: number;
    pin?: string;
}

export type UseAuthenticateFuturaeRequest =
    | ILoginAuth
    | IResetPwAuth
    | IPayoutAuth
    | ICardAuth
    | IOnboardingAuth
    | IChangePermissionAuth;

export interface IUseAuthenticateFuturaeResponse {
    area: string;
    message: string;
    factor: AuthFactors;
    mfaClaimUUID: string;
    payload: Record<string, any>;
    status: string;
    qrcode_url?: string;
}

const getUrlByType = (
    type: AuthTypes,
    params: UseAuthenticateFuturaeRequest,
) => {
    const baseFuturauUrl = `/web_api/futurae/auth`;
    switch (type) {
        case 'payout_transaction_detail':
            return `${baseFuturauUrl}/${type}/${
                (params as IPayoutAuth).transactionId
            }`;
        case 'get_pin':
        case 'secure_info':
            return `${baseFuturauUrl}/card/${type}/${
                (params as ICardAuth).cardId
            }`;
        case 'update-permission':
            return `{bpPermissionId}/update_role`;
        default:
            return `${baseFuturauUrl}/${type}`;
    }
};

const useAuthenticateFuturae = () => {
    const authenticatedClient = useClient();
    return useMutation<
        IUseAuthenticateFuturaeResponse,
        unknown,
        UseAuthenticateFuturaeRequest
    >(params => {
        if (params.type === 'update-permission') {
            const {type: _, ...rest} = params;
            return authenticatedClient(`${params['@id']}/update_role`, {
                data: rest,
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        }
        const {type, factor, passcode, token} = params;

        return authenticatedClient(getUrlByType(type, params), {
            data: {factor, passcode},
            method: 'POST',
            token,
        });
    });
};

const useConfirmSMS = () => {
    return useMutation(
        ({
            user,
            token,
            passCode,
            mfaClaimUUID,
        }: {
            user: string;
            token: string;
            passCode: string;
            mfaClaimUUID: string;
        }) => {
            return client('/web_api/futurae/verify_sms_code', {
                data: {user, passCode, mfaClaimUUID},
                method: 'POST',
                token,
            });
        },
    );
};

interface IGetAuthFactors {
    '@id': string;
    id: string;
    factors: ('sms' | 'approve')[];
    active: boolean;
}

const useGetAuthFactors = (token: string): QueryResult<IGetAuthFactors> => {
    return useQuery(
        [GET_AUTH_FACTORS, {token}],
        () =>
            client('/web_api/futurae/get_auth_factors', {
                token,
            }),
        {
            enabled: token,
        },
    );
};

export interface ISmsDevice {
    factor: 'sms';
    deviceId: string;
    number: string;
}

export interface IApproveDevice {
    factor: 'approve';
    deviceId: string;
    type: string;
}

export type Device = ISmsDevice | IApproveDevice;

export interface IGetFuturaeInfo {
    '@context': '/web_api/contexts/Futurae';
    '@id': string;
    '@type': 'Futurae';
    createdAt: string;
    devices: Partial<Record<Device['factor'], Device>>;
    factor: AuthFactors;
    futuraeUserId: string;
    updatedAt: string;
}

const useGetFuturaeInfo = (
    authFactors?: IGetAuthFactors,
): QueryResult<IGetFuturaeInfo> => {
    const authenticatedClient = useClient();

    return useQuery(
        [GET_FUTURAE_INFO, authFactors],
        () =>
            authenticatedClient(
                `/web_api/futurae/get_info/${authFactors.id}`,
                {},
            ),
        {enabled: !!authFactors},
    );
};

const useGetQRAuthStatus = (enabled: boolean, token: string) => {
    return useQuery(
        [GET_QR_AUTH_STATUS, {token}],
        () => client('/web_api/futurae/auth_status', {token}),
        {
            enabled,
        },
    );
};
const useAddQRCode = () => {
    const authenticatedClient = useClient();
    const {user} = useAuth();

    return useMutation(() => {
        return authenticatedClient('/web_api/futurae/enroll_device', {
            data: {user: user['@id']},
            method: 'POST',
        });
    });
};

const useEnrollRegistration = () => {
    return useMutation(({token}: {token: string}) => {
        return client('/web_api/futurae/enroll_and_activate_by_phone', {
            data: {},
            token,
            method: 'POST',
        });
    });
};

const useEnrollOnResetPassword = () => {
    return useMutation(({token}: {token: string}) =>
        client('/web_api/futurae/enroll_and_activate_by_phone', {
            data: {userConfirmationToken: token},
            method: 'POST',
        }),
    );
};

const useConfirmMobileRegistration = () => {
    const {updateUserWith2fa} = useAuth();
    return useMutation(
        ({
            passCode,
            futuraeId,
            token,
            deviceId,
        }: {
            passCode: string;
            futuraeId: string;
            token: string;
            deviceId: string;
        }) => {
            return client(`/web_api/futurae/verify_enroll_sms/${futuraeId}`, {
                data: {passCode, deviceId},
                token,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: response => {
                updateUserWith2fa(response['@id']);
                queryCache.invalidateQueries([USER_CACHE]);
                queryCache.invalidateQueries([GET_AUTH_FACTORS]);
                queryCache.invalidateQueries([GET_FUTURAE_INFO]);
            },
        },
    );
};

export interface IUpdateDevice {
    id: string;
    factor: AuthFactors;
}

const useUnenrollDevice = () => {
    const authenticatedClient = useClient();

    return useMutation(
        (request: IUpdateDevice) =>
            authenticatedClient(
                `/web_api/futurae/unenroll_device/${request.id}`,
                {
                    data: {
                        factor: request.factor,
                    },
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                    },
                },
            ),
        {
            onSuccess: () => {
                queryCache.invalidateQueries([GET_FUTURAE_INFO]);
                queryCache.invalidateQueries([GET_AUTH_FACTORS]);
            },
        },
    );
};

const useUpdateDefaultDevice = () => {
    const authenticatedClient = useClient();

    return useMutation(
        (request: IUpdateDevice) =>
            authenticatedClient(
                `/web_api/futurae/update_factor/${request.id}`,
                {
                    data: {
                        factor: request.factor,
                    },
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                    },
                },
            ),
        {
            onSuccess: () => queryCache.invalidateQueries([GET_FUTURAE_INFO]),
        },
    );
};

export {
    useAuthenticateFuturae,
    useConfirmSMS,
    useAddQRCode,
    useGetQRAuthStatus,
    useGetAuthFactors,
    useGetFuturaeInfo,
    useEnrollRegistration,
    useConfirmMobileRegistration,
    useEnrollOnResetPassword,
    useUnenrollDevice,
    useUpdateDefaultDevice,
};
