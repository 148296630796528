import React from 'react';
import {OverlayTrigger, Popover} from 'react-bootstrap';
import IconSVG from '../Icon/IconSVG';

import styles from './InfoPopover.module.scss';

interface IInfoPopoverProps {
    content: string | React.ReactNode;
}

const InfoPopover = (props: IInfoPopoverProps) => {
    const {content} = props;
    const popover = (
        <Popover id={content.toString()}>
            <Popover.Content>{content}</Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger={['hover', 'focus', 'click']}
            placement="auto"
            overlay={popover}
            rootClose
        >
            <button type="button" className={styles.infoIconContainer}>
                <IconSVG name="newInfo" />
            </button>
        </OverlayTrigger>
    );
};

export default InfoPopover;
