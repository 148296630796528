import React from 'react';

const ArrowOutward = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M6.4 17.645 5.356 16.6l9.84-9.85H6.25v-1.5h11.5v11.5h-1.5V7.804l-9.85 9.84Z"
        />
    </svg>
);

export default ArrowOutward;
