import React from 'react';

const ChangePin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4 18v-7.846.402V6v12Zm0-9.596h16V6.308a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H4.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v2.096ZM11.421 19.5H4.308c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v4.998a6.897 6.897 0 0 0-1.773-.85 6.632 6.632 0 0 0-2.01-.302c-.764 0-1.49.128-2.178.384a6.334 6.334 0 0 0-1.847 1.059H4v6.096c0 .077.032.147.096.211a.294.294 0 0 0 .212.096h6.686c.044.267.1.524.168.77.067.247.154.49.26.73Zm5.546 1.904-.203-1.192a4.155 4.155 0 0 1-.726-.291 2.853 2.853 0 0 1-.644-.463l-1.142.392-.77-1.257.92-.789a3.389 3.389 0 0 1-.108-.842c0-.28.036-.562.108-.842l-.92-.789.77-1.258 1.142.393a2.63 2.63 0 0 1 .639-.463 4.31 4.31 0 0 1 .73-.291l.204-1.192h1.5l.204 1.192c.258.077.501.174.73.291.23.118.443.272.64.463l1.142-.393.769 1.258-.92.789a3.37 3.37 0 0 1 .001 1.684l.919.789-.77 1.257-1.142-.392a2.85 2.85 0 0 1-.643.463 4.155 4.155 0 0 1-.726.29l-.204 1.193h-1.5Zm.75-2.365c.576 0 1.066-.202 1.47-.607.405-.404.607-.894.607-1.47s-.202-1.066-.607-1.47a2.002 2.002 0 0 0-1.47-.607c-.575 0-1.065.202-1.47.607a2.002 2.002 0 0 0-.607 1.47c0 .576.203 1.066.607 1.47.405.405.895.607 1.47.607Z"
        />
    </svg>
);

export default ChangePin;
