import React from 'react';

const SvgDiamond = (props: React.SVGProps<SVGSVGElement>) => (
    <svg viewBox="0 0 28 26" height="1em" width="1em">
        <path
            fill="currentColor"
            d="M22.5252.8714h-17.05c-.1937 0-.3875.0969-.4843.2906L.147 8.2823c-.1453.1938-.1453.4844 0 .6782L13.516 25.4777a.5947.5947 0 00.9203 0L27.8049 8.9605c.1453-.1938.1453-.4844.0485-.6782L23.0096 1.162c-.1453-.1937-.2906-.2906-.4844-.2906zm-.9687 2.325l3.1 4.65h-3.3422l-2.5188-4.65h2.761zm-9.7844 0h4.4078l2.5188 4.65H9.3018l2.4703-4.65zm-5.3765 0h2.7609l-2.5188 4.65H3.344l3.0516-4.65zm-2.0829 6.975H6.783l3.3422 7.75-5.8125-7.75zm4.9891 0h9.3484l-4.65 11.8188-4.6984-11.8188zm8.5734 7.75l3.2938-7.75h2.4703l-5.7641 7.75z"
        />
    </svg>
);

export default SvgDiamond;
