import {create} from 'zustand';
import {immer} from 'zustand/middleware/immer';
import {createJSONStorage, devtools, persist} from 'zustand/middleware';

export type InviteType = 'contact' | 'business-partner';

type ResolveInviteState = {
    hash: string;
    type: InviteType;
};

type ResolveInviteActions = {
    setInvite: (data: ResolveInviteState) => void;
};

export const useResolveInviteStore = create(
    devtools(
        persist(
            immer<ResolveInviteState & ResolveInviteActions>(set => ({
                type: null,
                hash: null,
                setInvite: data => {
                    set(state => {
                        state.hash = data.hash;
                        state.type = data.type;
                    });
                },
            })),
            {
                name: '__invite_hash__',
                storage: createJSONStorage(() => localStorage),
            },
        ),
    ),
);
