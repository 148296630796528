import React from 'react';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';

import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {
    useGetBusinessPartnerCardPurposes,
    useUpdateBusinessPartnerOnboarding,
} from 'api/business-partner';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {handleTypedFormError} from 'helpers/handleFormError';
import {LegalType} from 'types/onboarding';
import {
    DebitCardsFormValues,
    debitCardsValidationSchema,
} from 'form/validation/businessPartner';
import {CARD_VOLUME} from 'constants/businessPartnerAttributes';
import {translate} from 'helpers/localize';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import styles from './Steps.module.scss';

const DebitCardStep = (props: IDefaultStepProps) => {
    const {
        activeBusinessPartner,
        currentStep,
        onSuccess,
        setActiveBusinessPartner,
        legalType,
    } = props;
    const {
        data: cardPurposes,
        isLoading: isCardPurposesLoading,
    } = useGetBusinessPartnerCardPurposes();
    const [
        addAdditionalInfo,
        {isLoading},
    ] = useUpdateBusinessPartnerOnboarding();

    const formMethods = useForm<DebitCardsFormValues>({
        defaultValues: {
            gwgInfo: {
                legalType,
                cardCount: activeBusinessPartner.gwgInfo?.cardCount,
                cardPurpose: activeBusinessPartner.gwgInfo?.cardPurpose,
                cardVolume: activeBusinessPartner.gwgInfo?.cardVolume,
                informationCollected: activeBusinessPartner.gwgInfo
                    ?.informationCollected
                    ? '1'
                    : '0',
            },
        },
        resolver: yupResolver(debitCardsValidationSchema),
        shouldUnregister: false,
    });
    formMethods.register('gwgInfo.legalType');
    formMethods.setValue('gwgInfo.legalType', legalType);

    const handleSubmit: FormOnSubmit<DebitCardsFormValues> = (
        data,
        setError,
    ) => {
        const {legalType: _, ...gwgInfo} = data.gwgInfo;
        addAdditionalInfo(
            {
                gwgInfo: {
                    ...gwgInfo,
                    informationCollected: +gwgInfo?.informationCollected === 1,
                    id: activeBusinessPartner.gwgInfo?.id,
                },
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
            },
            {
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess();
                },
                onError: (requestErrors: any) => {
                    handleTypedFormError(requestErrors, setError, data);
                },
            },
        );
    };

    return (
        <>
            <StepTitle title="Debit Cards" />
            <Form<DebitCardsFormValues>
                onSubmit={handleSubmit}
                formMethods={formMethods}
                className={styles.form}
            >
                <p className={styles.label}>
                    {legalType === LegalType.COMPANY
                        ? 'To issue debit cards to your company, we require the following data from the card holder:'
                        : 'To issue debit cards, we require the following data:'}
                </p>
                <p>Name, Surname, Date of Birth, Nationality</p>
                {legalType === LegalType.COMPANY ? (
                    <CheckBoxGroup
                        name="gwgInfo.informationCollected"
                        label="Does your company collect this information?"
                        options={[
                            {label: 'Yes', value: '1'},
                            {label: 'No', value: '0'},
                        ]}
                        type="radio"
                        variant="dark"
                        size="large"
                    />
                ) : null}
                <AutoComplete
                    label="What is the purpose of using the debit card(s)?"
                    name="gwgInfo.cardPurpose"
                    placeholder="Please select"
                    options={cardPurposes}
                    isLoading={isCardPurposesLoading}
                    sortKey="@id"
                    labelKey="value"
                    variant="dark"
                    size="large"
                    isMulti
                    showMultiMessage
                />
                <AutoComplete
                    name="gwgInfo.cardVolume"
                    label={
                        <span data-notranslate>
                            {translate(
                                'What is the estimated monthly turnover in %{currency} that you anticipate for your debit card(s)?',
                                {
                                    currency:
                                        activeBusinessPartner?.mainCurrency
                                            ?.split('/')
                                            .pop() || 'EUR',
                                },
                            )}
                        </span>
                    }
                    options={CARD_VOLUME}
                    size="large"
                    variant="dark"
                />
                {legalType === LegalType.COMPANY ? (
                    <AutoComplete
                        name="gwgInfo.cardCount"
                        label="How many card holders do you anticipate?"
                        options={[
                            {label: '<5', '@id': '0-5'},
                            {label: '5-20', '@id': '5-20'},
                            {label: '20-100', '@id': '20-100'},
                            {label: '>100', '@id': '100-'},
                        ]}
                        size="large"
                        variant="dark"
                    />
                ) : null}
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default DebitCardStep;
