import React from 'react';

const Calendar = (props: React.HTMLAttributes<SVGElement>) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            {...props}
        >
            <path
                fill="currentColor"
                d="M5.308 21.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h1.384V2.385h1.539V4.5h7.577V2.385h1.5V4.5h1.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v13.385c0 .505-.175.932-.525 1.282-.35.35-.778.525-1.283.525H5.308Zm0-1.5h13.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.211v-9.385H5v9.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096ZM5 8.808h14v-2.5a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H5.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v2.5Zm7 5.27a.853.853 0 0 1-.626-.26.853.853 0 0 1-.259-.625c0-.245.087-.454.259-.626a.853.853 0 0 1 .626-.259.85.85 0 0 1 .626.259.852.852 0 0 1 .259.626.852.852 0 0 1-.26.626.853.853 0 0 1-.625.258Zm-4 0a.853.853 0 0 1-.626-.26.853.853 0 0 1-.259-.625c0-.245.087-.454.259-.626A.853.853 0 0 1 8 12.308c.245 0 .453.086.626.259a.853.853 0 0 1 .259.626.853.853 0 0 1-.26.626.853.853 0 0 1-.625.258Zm8 0a.853.853 0 0 1-.626-.26.853.853 0 0 1-.259-.625c0-.245.087-.454.259-.626a.853.853 0 0 1 .626-.259c.245 0 .453.086.626.259a.852.852 0 0 1 .259.626.852.852 0 0 1-.26.626.853.853 0 0 1-.625.258ZM12 18a.853.853 0 0 1-.626-.258.853.853 0 0 1-.259-.626c0-.245.087-.454.259-.626a.853.853 0 0 1 .626-.26.85.85 0 0 1 .626.259.852.852 0 0 1 .259.626.853.853 0 0 1-.26.626A.853.853 0 0 1 12 18Zm-4 0a.853.853 0 0 1-.626-.258.853.853 0 0 1-.259-.626c0-.245.087-.454.259-.626A.852.852 0 0 1 8 16.23c.245 0 .453.086.626.259a.853.853 0 0 1 .259.626.853.853 0 0 1-.26.626A.853.853 0 0 1 8 18Zm8 0a.853.853 0 0 1-.626-.258.853.853 0 0 1-.259-.626c0-.245.087-.454.259-.626a.853.853 0 0 1 .626-.26c.245 0 .453.086.626.259a.852.852 0 0 1 .259.626.853.853 0 0 1-.26.626A.853.853 0 0 1 16 18Z"
            />
        </svg>
    );
};

export default Calendar;
