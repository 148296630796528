import React, {useCallback, useEffect, useRef} from 'react';
import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import {useForm} from 'react-hook-form';
import FileUploadArea from 'components/ui/Form/FileUploadArea/FileUploadArea';
import useFileUploadStatuses, {
    isNewFileUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {
    useUpdateBusinessPartnerOnboarding,
    useUploadBusinessPartnerDocument,
} from 'api/business-partner';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {handleTypedFormError} from 'helpers/handleFormError';
import {
    INVESTMENT_OPTIONS,
    CUSTOMER_RELATION_INCOMING_FLOW_OPTIONS,
    GENERATE_INCOMING_FLOW_OPTIONS,
    OWNER_SOURCE_OF_FUNDS,
    INVESTMENT_AMOUNTS,
} from 'constants/businessPartnerAttributes';
import SourceOfWealthInfo from 'components/pages/OnboardingPage/components/Infos/SourceOfWealthInfo';
import {yupResolver} from '@hookform/resolvers';
import {
    SourceOfWealthFormValues,
    sourceOfWealthValidationSchema,
} from 'form/validation/businessPartner';
import styles from './Steps.module.scss';

const SourceOfWealth = (props: IDefaultStepProps) => {
    const {
        activeBusinessPartner,
        bpDocuments,
        currentStep,
        setActiveBusinessPartner,
        onSuccess,
    } = props;
    const formMethods = useForm<WithFormError<SourceOfWealthFormValues>>({
        defaultValues: {
            gwgInfo: {
                extendedSoWQuestions:
                    activeBusinessPartner.gwgInfo?.extendedSoWQuestions,
                customerRelationIncomingFlow:
                    activeBusinessPartner.gwgInfo
                        ?.customerRelationIncomingFlow || [],
                generateIncomingFlow:
                    activeBusinessPartner.gwgInfo?.generateIncomingFlow || [],
                investedAmount: activeBusinessPartner.gwgInfo?.investedAmount,
                ownerSourceOfFund:
                    activeBusinessPartner.gwgInfo?.ownerSourceOfFund,
                privateInvestmentSource:
                    activeBusinessPartner.gwgInfo?.privateInvestmentSource,
            },
        },
        resolver: yupResolver(sourceOfWealthValidationSchema),
    });
    const {watch, setValue, register} = formMethods;

    register('gwgInfo.extendedSoWQuestions');

    const {updateStatus, uploadStatuses} = useFileUploadStatuses();
    const [uploadBpDocument] = useUploadBusinessPartnerDocument();

    const [
        updateBusinessPartner,
        {isLoading},
    ] = useUpdateBusinessPartnerOnboarding();

    const handleSubmit: FormOnSubmit<SourceOfWealthFormValues> = (
        data,
        setError,
    ) => {
        const {extendedSoWQuestions, ...gwgInfo} = data.gwgInfo;
        updateBusinessPartner(
            {
                gwgInfo: {
                    id: activeBusinessPartner.gwgInfo.id,
                    ...gwgInfo,
                },
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
            },
            {
                onError: (errorResponse: any) =>
                    handleTypedFormError(errorResponse, setError, data),
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess();
                },
            },
        );
    };

    const ownerSourceOfFund = watch('gwgInfo.ownerSourceOfFund');
    const documents = watch('file');
    const uploading = useRef<boolean>(false);

    const handleUploadFile = useCallback(
        (files: File[]) => {
            if (uploading.current === true) return;
            uploading.current = true;
            const needToUpload = files.filter(
                doc =>
                    uploadStatuses.find(
                        status =>
                            isNewFileUploadStatus(status) &&
                            status.document === doc,
                    ) === undefined,
            );
            if (!needToUpload.length) return;
            updateStatus({
                document: needToUpload[0],
                status: 'loading',
                name: needToUpload[0].name,
            });
            uploadBpDocument(
                {
                    activeBpId: activeBusinessPartner['@id'],
                    files,
                    type: 'onboarding',
                    category:
                        ownerSourceOfFund === 'inheritance'
                            ? 'edd_evidence_inheritance'
                            : 'edd_evidence_employment',
                },
                {
                    onSuccess: data => {
                        setValue(
                            'file',
                            files.filter(d => d !== files[0]),
                        );
                        updateStatus({
                            document: needToUpload[0],
                            name: needToUpload[0].name,
                            status: 'uploaded',
                        });
                    },
                    onError: errorResponse => {
                        updateStatus({
                            document: needToUpload[0],
                            status: 'error',
                            name: needToUpload[0].name,
                            errorMessage:
                                errorResponse['hydra:description'] ||
                                'Something went wrong',
                        });
                    },
                    onSettled: () => {
                        uploading.current = false;
                    },
                },
            );
        },
        [
            activeBusinessPartner,
            ownerSourceOfFund,
            setValue,
            updateStatus,
            uploadBpDocument,
            uploadStatuses,
        ],
    );

    useEffect(() => {
        if (documents?.length) {
            handleUploadFile(documents);
        }
    }, [documents, handleUploadFile]);

    const renderOwnerSourceOfFundBasedQuestions = () => {
        switch (ownerSourceOfFund) {
            case 'inheritance':
            case 'employment':
                return (
                    <FileUploadArea
                        uploadStatuses={uploadStatuses}
                        name="file"
                        label={
                            ownerSourceOfFund === 'inheritance'
                                ? 'Evidence of inheritance'
                                : 'Evidence of employment'
                        }
                        uploadedDocuments={bpDocuments
                            ?.filter(
                                doc =>
                                    doc.category ===
                                    (ownerSourceOfFund === 'inheritance'
                                        ? 'edd_evidence_inheritance'
                                        : 'edd_evidence_employment'),
                            )
                            .map(doc => ({
                                id: doc['@id'],
                                name: doc.originalFileName,
                            }))}
                    />
                );
            case 'private_investment_gain':
                return (
                    <AutoComplete
                        name="gwgInfo.privateInvestmentSource"
                        label="Private investment source"
                        options={INVESTMENT_OPTIONS}
                        size="large"
                        variant="dark"
                        isMulti
                        showMultiMessage
                    />
                );
            default:
                return null;
        }
    };

    return (
        <>
            <StepTitle title="Source of wealth" info={<SourceOfWealthInfo />} />
            <Form<SourceOfWealthFormValues>
                formMethods={formMethods}
                onSubmit={handleSubmit}
                className={styles.form}
            >
                <AutoComplete
                    name="gwgInfo.customerRelationIncomingFlow"
                    label="Please select all applicable to describe your customer relationship with regard to income flow?"
                    options={CUSTOMER_RELATION_INCOMING_FLOW_OPTIONS}
                    size="large"
                    variant="dark"
                    isMulti
                />
                <AutoComplete
                    name="gwgInfo.generateIncomingFlow"
                    label="Please select what is applicable in your company setup to generate the income flow (profit)?"
                    options={GENERATE_INCOMING_FLOW_OPTIONS}
                    size="large"
                    variant="dark"
                    isMulti
                />
                {activeBusinessPartner.gwgInfo?.extendedSoWQuestions ? (
                    <>
                        <AutoComplete
                            name="gwgInfo.ownerSourceOfFund"
                            label="How did the owner establish their wealth to fund the company?"
                            options={OWNER_SOURCE_OF_FUNDS}
                            size="large"
                            variant="dark"
                        />
                        <AutoComplete
                            name="gwgInfo.investedAmount"
                            label="What is the total amount of investment that the company has received, including statutory capital, working capital, credit lines, loans, and other forms of investment?"
                            options={INVESTMENT_AMOUNTS}
                            size="large"
                            variant="dark"
                        />
                        {renderOwnerSourceOfFundBasedQuestions()}
                    </>
                ) : null}
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default SourceOfWealth;
