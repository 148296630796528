export const CURRENCIES = [
    '',
    'EUR',
    'CHF',
    'USD',
    'GBP',
    'AUD',
    'DKK',
    'JPY',
    'CZK',
    'SAR',
] as const;
export enum OrderType {
    ORDER_EXCHANGE = 'Exchange',
    ORDER_LIMIT = 'Limit',
    ORDER_SWAP = 'Swap',
}
export const ORDER_TYPES: OrderType[] = [
    OrderType.ORDER_EXCHANGE,
    OrderType.ORDER_LIMIT,
    OrderType.ORDER_SWAP,
];

export const DEFAULT_ORDER_TYPE: OrderType = OrderType.ORDER_EXCHANGE;

export const DEAL_TIMEOUT = 15;
export const SWAP_DEAL_TIMEOUT = 120;
export const LIMIT_DEAL_TIMEOUT = 120;

export const DEAL_TYPE_FORWARD = 'forward_transaction';

export const DEAL_TYPE_PERMISSIONS = {
    [OrderType.ORDER_EXCHANGE]: 'fxTrade',
    [OrderType.ORDER_LIMIT]: 'limitTrade',
    [OrderType.ORDER_SWAP]: 'swapTrade',
};

export const DEAL_TYPE_PERMISSIONS_LABEL = {
    [OrderType.ORDER_EXCHANGE]: 'FX trade',
    [OrderType.ORDER_LIMIT]: 'Limit trade',
    [OrderType.ORDER_SWAP]: 'Swap trade',
};
