import React from 'react';
import classNames from 'classnames';
import Input from 'react-phone-number-input';
import {
    Controller,
    ControllerRenderProps,
    InputState,
    useFormContext,
} from 'react-hook-form';
import {translate} from 'helpers/localize';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {useLanguage} from 'context/language-context';
import {getDeepValue} from 'helpers/common';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './PhoneInput.module.scss';

type IPhoneInputProps = IDefaultControlProps;

const PhoneInput = (props: IPhoneInputProps) => {
    const {
        label,
        className,
        name,
        placeholder,
        info,
        rounded = true,
        variant,
        disabled,
        readOnly,
        hidden,
        size,
    } = props;

    const {selectedLanguageParams} = useLanguage();
    const {control, errors} = useFormContext();

    const inputProps = {
        placeholder: translate(placeholder),
    };

    const controllerProps = {
        control,
        name,
        render: (
            field: ControllerRenderProps<Record<string, unknown>>,
            state: InputState,
        ) => {
            return (
                <Input
                    {...inputProps}
                    value={field.value}
                    className={classNames(styles.phoneInputWrapper, {
                        [styles.invalid]: getDeepValue(errors, name),
                        [styles.dark]: variant === 'dark',
                        [styles.rounded]: rounded,
                        [styles.large]: size === 'large',
                        [styles.disabled]: disabled || readOnly,
                    })}
                    defaultCountry={selectedLanguageParams.phoneCode}
                    international
                    disabled={disabled || readOnly}
                    onChange={val =>
                        control.setValue(name, val, {
                            shouldValidate: true,
                            shouldDirty: true,
                        })
                    }
                />
            );
        },
    };

    return (
        <div
            className={classNames(
                styles.container,
                className,
                {
                    [styles.hidden]: hidden,
                    [styles.large]: size === 'large',
                },
                getDeepValue(errors, name)?.message
                    ? styles.errorContainer
                    : null,
            )}
        >
            <label htmlFor={name} className={styles.label}>
                {label}
                {info ? <InfoPopover content={info} /> : null}
            </label>
            <Controller {...controllerProps} data-notranslate defaultValue="" />
            {getDeepValue(errors, name)?.message ? (
                <ErrorFeedback
                    message={getDeepValue(errors, name).message}
                    errorClassName={styles.errorMessage}
                />
            ) : null}
        </div>
    );
};

export default PhoneInput;
