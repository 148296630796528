import {IBusinessPartnerWallet} from 'types/api';

export const hasAmnisBankAccount = (
    wallet: IBusinessPartnerWallet,
): boolean => {
    return wallet?.virtualIbans.some(
        viban =>
            viban.bankName.toLowerCase() ===
                'AMNIS Treasury Services AG'.toLowerCase() ||
            viban.bankName.toLowerCase() === 'AMNIS Europe AG'.toLowerCase(),
    );
};
