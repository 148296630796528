import React, {useCallback} from 'react';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {LEGAL_FORM_SINGLE_PERSON_ENTERPRISE} from 'constants/common';
import {handleFormError} from 'helpers/handleFormError';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import useModal from 'components/ui/ModalContainer/useModal';
import {ConfirmationModal} from 'components/modals';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import {queryCache} from 'react-query';
import {USER_CACHE} from 'constants/cache';
import OnboardingOverlay from 'components/ui/Overlays/OnboardingOverlay/OnboardingOverlay';
import {useAuth} from 'context/auth-context';
import {
    crpFormBaseValidationSchema,
    crpFormValidationSchema,
} from 'form/validation/businessPartner';
import {IUpdateBusinessPartnerOnboarding} from 'types/api';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {useForm} from 'react-hook-form';
import CrpOwnerInfo from 'components/pages/OnboardingPage/components/Infos/CrpOwnerInfo';
import {IDefaultStepProps} from '../useOnboarding';
import StepTitle from '../components/StepTitle';
import ControllingOptionsForm from '../components/ControllingOptionsForm';
import NextStepButton from '../components/NextStepButton';
import OwnerForm from '../components/OwnerForm';
import CrpTable from '../components/CrpTable';

import styles from './Steps.module.scss';
import CrpForm from '../components/CrpForm';
import useCrpTable from '../components/hooks/useCrpTable';

interface IFormValues {
    gwgInfo?: {
        formOfControl: string;
        assetsOwner: string;
    };
    'type-1'?: 'yes' | 'no';
    'type-2'?: 'yes' | 'no';
    'type-3'?: 'yes' | 'no';
    formErrors?: string;
}

const CrpOwner = (props: IDefaultStepProps) => {
    const {
        isIndividual,
        activeBusinessPartner,
        isCrpFetched,
        isCrpLoading,
        crpList,
        setActiveBusinessPartner,
        onSuccess,
        currentStep,
        legalType,
        inReview,
    } = props;
    const [
        addAdditionalInfo,
        {isLoading},
    ] = useUpdateBusinessPartnerOnboarding();
    const {openModalWithContent, closeModal} = useModal();
    const {user} = useAuth();
    const formMethods = useForm<IFormValues>({
        defaultValues: {
            gwgInfo: {
                assetsOwner: activeBusinessPartner.gwgInfo?.assetsOwner
                    ? 'yes'
                    : 'no',
                formOfControl:
                    activeBusinessPartner.gwgInfo?.formOfControl?.toString() ??
                    '',
            },
            'type-1': undefined,
            'type-2': undefined,
            'type-3': undefined,
        },
        shouldUnregister: false,
    });

    const {handleCrpEdit} = useCrpTable({crpList, currentStep});

    // special logic for this page, single person enterprise needs the same form here as private persons
    const isSinglePerson =
        isIndividual ||
        activeBusinessPartner.legalForm === LEGAL_FORM_SINGLE_PERSON_ENTERPRISE;

    const getSubtitle = useCallback(() => {
        if (isSinglePerson) {
            return activeBusinessPartner.amnisLegalEntityCountry
                .split('/')
                .pop() === 'CH' ? (
                'In accordance with Article 27 of the Agreement on the Swiss banks’ code of conduct with regard to the exercise of due diligence (CDB 20), the contracting partner hereby declares that the person(s) listed below is/are the beneficial owner(s) of the assets deposited under the above relationship. If the contracting partner is the beneficial owner of the assets, the contracting partner’s details must be set out below:'
            ) : (
                <span>
                    In the following section you will be asked to disclose the
                    ownership of{' '}
                    <var data-var="company-name">
                        {activeBusinessPartner.companyName}
                    </var>
                    . This information is being collected in order to comply
                    with the Due Diligence Ordinance (Art. 3 Para. 1 Lit. a.)
                </span>
            );
        }
        return activeBusinessPartner.amnisLegalEntityCountry
            .split('/')
            .pop() === 'CH' ? (
            'Pursuant to Article 20 of the Agreement on the Swiss banks’ code of conduct with regard to the exercise of due diligence (CDB 20), the contracting partner(s) hereby declare(s) (tick the appropriate box):'
        ) : (
            <span>
                In the following section you will be asked to disclose the
                ownership of{' '}
                <var data-var="company-name">
                    {activeBusinessPartner.companyName}
                </var>
                . This information is being collected in order to comply with
                the Due Diligence Ordinance (Art. 3 Para. 1 Lit. b.)
            </span>
        );
    }, [isSinglePerson, activeBusinessPartner]);

    const checkAllOwners = (formOfControl: string): boolean => {
        const owners = crpList.filter(crp => crp.isOwner);
        let result = true;

        owners.forEach(owner => {
            try {
                crpFormValidationSchema(
                    CrpVariants.OWNER,
                    owner.confidential,
                    legalType,
                    formOfControl?.toString(),
                ).validateSync({...owner.crp, ...owner});
            } catch (err) {
                result = false;
                openModalWithContent(
                    'crpForm',
                    <OnboardingOverlay fullHeight>
                        <CrpForm
                            legalType={legalType}
                            variant={CrpVariants.OWNER}
                            handleSubmit={(crpValues, setCrpError) => {
                                handleCrpEdit(
                                    CrpVariants.OWNER,
                                    owner.crp.user === user['@id'],
                                    crpValues,
                                    true,
                                    owner,
                                    setCrpError,
                                );
                            }}
                            crpBpId={owner['@id']}
                            isLoggedInUser={owner.crp.user === user['@id']}
                            formOfControl={formOfControl?.toString()}
                            inReview={inReview}
                        />
                    </OnboardingOverlay>,
                );
            }
        });

        return result;
    };

    const handleFormSubmit: FormOnSubmit<IFormValues> = (values, setError) => {
        if (!checkAllOwners(values.gwgInfo.formOfControl)) return;

        if (isIndividual) {
            const userCrp = crpList?.find(
                crpBp => crpBp.crp.user === user['@id'],
            );
            if (!userCrp) return;
            try {
                crpFormBaseValidationSchema(CrpVariants.OWNER).validateSync({
                    ...userCrp.crp,
                    ...userCrp,
                });
            } catch (error) {
                openModalWithContent(
                    'crpForm',
                    <OnboardingOverlay fullHeight>
                        <CrpForm
                            legalType={legalType}
                            variant={CrpVariants.OWNER}
                            handleSubmit={(crpValues, setCrpError) => {
                                handleCrpEdit(
                                    CrpVariants.OWNER,
                                    true,
                                    crpValues,
                                    true,
                                    userCrp,
                                    setCrpError,
                                );
                            }}
                            crpBpId={userCrp['@id']}
                            isLoggedInUser
                            formOfControl={values.gwgInfo?.formOfControl}
                            inReview={inReview}
                        />
                    </OnboardingOverlay>,
                );
                return;
            }
        }
        openModalWithContent(
            'confirmation',
            <OnboardingOverlay hideCloseButton>
                <ConfirmationModal
                    title="Confirmation"
                    description={
                        activeBusinessPartner.amnisLegalEntityCountry
                            .split('/')
                            .pop() === 'CH'
                            ? 'It is a criminal offence to deliberately provide false information on this form (Article 251 of the Swiss Criminal Code, document forgery). Any changes must be immediately notified to the person subject to due diligence.'
                            : 'Deliberately providing false information on this form constitutes a criminal offence under the Liechtenstein Criminal Code (Strafgesetzbuch). Any changes must be immediately notified to the person subject to due diligence.'
                    }
                    onClose={() => closeModal('confirmation')}
                    onSubmit={() => handleConfirm(values, setError)}
                />
            </OnboardingOverlay>,
        );
    };

    const handleConfirm: FormOnSubmit<IFormValues> = (values, setError) => {
        let stepData: IUpdateBusinessPartnerOnboarding;
        if (isSinglePerson) {
            stepData = {
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
                gwgInfo: {
                    id: activeBusinessPartner.gwgInfo.id,
                    formOfControl: 4,
                    assetsOwner: values.gwgInfo.assetsOwner === 'yes',
                },
            };
        } else {
            const {
                gwgInfo: {formOfControl, assetsOwner},
            } = values;

            stepData = {
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
                gwgInfo: {
                    id: activeBusinessPartner.gwgInfo.id,
                    assetsOwner: assetsOwner === 'yes',
                    formOfControl: parseInt(formOfControl, 10),
                },
            };
        }

        addAdditionalInfo(stepData, {
            onSuccess: response => {
                setActiveBusinessPartner(response);
                closeModal();
                onSuccess();
                queryCache.invalidateQueries([USER_CACHE]);
            },
            onError: (requestErrors: any) => {
                handleFormError(requestErrors, setError);
            },
        });
    };

    const hasOwners = crpList?.some(crp => crp.isOwner);

    const getStepTitle = () => {
        if (inReview)
            return 'Has there been a change in the controlling person(s)?';
        if (isSinglePerson)
            return 'Declaration of identity of the beneficial owner';

        return 'Determining the Controlling Person(s)';
    };

    return (
        <Form<IFormValues>
            onSubmit={handleFormSubmit}
            confirmLeave
            formMethods={formMethods}
        >
            <div className={styles.form}>
                <StepTitle
                    title={getStepTitle()}
                    subTitle={getSubtitle()}
                    info={<CrpOwnerInfo />}
                />
                {!isSinglePerson ? (
                    <ControllingOptionsForm
                        name="gwgInfo.formOfControl"
                        defaultValue={
                            activeBusinessPartner.gwgInfo?.formOfControl ??
                            undefined
                        }
                        label=""
                        variant="dark"
                    />
                ) : null}
            </div>
            <OwnerForm
                activeBusinessPartner={activeBusinessPartner}
                crpList={crpList}
                isCrpFetched={isCrpFetched}
                isSinglePerson={isSinglePerson}
            >
                <CrpTable
                    crpList={crpList}
                    isCrpLoading={isCrpLoading}
                    variant={CrpVariants.OWNER}
                    currentStep={currentStep}
                    legalType={legalType}
                    inReview={inReview}
                />
            </OwnerForm>
            <div className={styles.form}>
                <NextStepButton loading={isLoading} disabled={!hasOwners} />
            </div>
        </Form>
    );
};

export default CrpOwner;
