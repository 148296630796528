import React from 'react';
import classnames from 'classnames';
import {createMaps} from 'helpers/common';
import {LayoutVariants} from 'components/layout/UnauthorisedLayout/UnauthorisedLayout';

import logo from 'assets/images/amnis_logo_white.svg';
import logoBlue from 'assets/images/amnis_logo_blue.svg';
import logo_wir from 'assets/images/amnis_wir.svg';
import logo_a352 from 'assets/images/A352_logo.png';

import styles from './Logo.module.sass';

interface ILogoProps {
    className?: string;
    small?: boolean;
    blue?: boolean;
    variant?: LayoutVariants;
    linkTo?: string;
    target?: string;
}

const createLogoVariants = (blue?: boolean) =>
    createMaps<Record<LayoutVariants, string>>({
        [LayoutVariants.DEFAULT]: blue ? logoBlue : logo,
        [LayoutVariants.WIR]: logo_wir,
        [LayoutVariants.A352]: logo_a352,
    });

const Logo: React.FC<ILogoProps> = ({
    className,
    blue,
    small,
    linkTo = '/',
    target = '_self',
    variant = LayoutVariants.DEFAULT,
}) => {
    const LOGO_VARIANTS = createLogoVariants(blue);
    return (
        <a
            href={linkTo}
            target={target}
            className={classnames(styles.link, className, {
                [styles.small]: small,
                [styles.wir]: variant === LayoutVariants.WIR,
            })}
            data-notranslate
        >
            <img
                className={styles.img}
                src={LOGO_VARIANTS[variant]}
                alt="Amnis logo"
            />
        </a>
    );
};

export default Logo;
