import React, {useEffect} from 'react';
import {useGetQRAuthStatus} from 'api/futurae';
import {useAuth} from 'context/auth-context';
import {Image} from 'react-bootstrap';
import security from 'assets/images/security.svg';
import CountdownTimer from '../CountdownTimer/CountdownTimer';

import styles from './FuturaeAuth.module.scss';

export interface IFuturaeAuth {
    futuraImgUrl: string;
    onSuccessful?: (enroll: boolean, token?: string) => void;
    enroll?: boolean;
}

const FuturaeAuth = (props: IFuturaeAuth) => {
    const {token} = useAuth();
    const {enroll = false, onSuccessful, futuraImgUrl} = props;

    const {data: authStatus, refetch: refetchAuth} = useGetQRAuthStatus(
        false,
        token,
    );

    useEffect(() => {
        const timeOut = setTimeout(() => {
            refetchAuth();
        }, 40000);

        return () => clearTimeout(timeOut);
    }, [refetchAuth]);

    useEffect(() => {
        if (authStatus && onSuccessful) {
            onSuccessful(true, authStatus.token);
        }
    }, [onSuccessful, authStatus]);

    return (
        <div className={styles.container}>
            <img
                className={styles.illustration}
                src={security}
                alt="security-illustration"
            />
            <p className={styles.title}>One Touch Authentication</p>
            {enroll ? (
                <p className={styles.text}>
                    Open the Futurae app and scan the QR code presented below
                </p>
            ) : (
                <p className={styles.text}>
                    Open the Futurae app and tap “Approve” to authenticate or
                    “Reject” if you have not made this request.
                </p>
            )}
            <div className={styles.timerContainer}>
                <CountdownTimer />
            </div>
            <div className={styles.imageContainer}>
                {futuraImgUrl ? (
                    <Image src={futuraImgUrl} fluid rounded />
                ) : null}
            </div>
        </div>
    );
};

export default FuturaeAuth;
