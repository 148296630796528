import React from 'react';
import {Alert, Col, Form, FormLabel, Row} from 'react-bootstrap';
import {Controller, UseFormMethods} from 'react-hook-form';
import NumberInput from 'components/ui/NumberInput/NumberInput';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import SubmitButton from 'components/ui/Button/SubmitButton';
import DatePicker from 'components/ui/DatePicker/DatePicker';

import {translate} from 'helpers/localize';
import styles from './CollectEBill.module.sass';

export interface ICollectEBillProps {
    handleSubmitEbill: ({
        values,
        onSuccess,
    }: {
        values: any;
        onSuccess?: () => any;
    }) => void;
    isLoading: boolean;
    isSuccess: boolean;
    form: UseFormMethods<{
        amount: any;
        invoiceDate: string;
    }>;
    currencyFrom: string;
    hideLabel?: boolean;
    onDealCapture?: boolean;
}

const CollectEBill = ({
    handleSubmitEbill,
    isLoading,
    isSuccess,
    form,
    hideLabel,
    currencyFrom,
    onDealCapture = false,
}: ICollectEBillProps) => {
    const {handleSubmit, control, errors} = form;
    return (
        <>
            {!hideLabel ? (
                <Row>
                    <FormLabel column xs={12}>
                        Collect with EBill
                    </FormLabel>
                </Row>
            ) : null}

            {isSuccess ? (
                <Alert variant="success">Ebill is sent</Alert>
            ) : (
                <form
                    onSubmit={handleSubmit(values =>
                        handleSubmitEbill({values}),
                    )}
                >
                    <Form.Row className={styles.inputRow}>
                        <CurrencyFlag
                            className={styles.flag}
                            code={currencyFrom.split('/').pop()}
                            title={false}
                        />
                        <NumberInput
                            xs={6}
                            md={6}
                            className={styles.price}
                            control={control}
                            error={errors.amount}
                            name="amount"
                            placeholder={translate('Amount')}
                        />
                        <Form.Group as={Col} xs={4} md={4}>
                            <Controller
                                value=""
                                control={control}
                                as={DatePicker}
                                placeholderText={translate('Value Date')}
                                name="invoiceDate"
                                minDate={new Date()}
                            />
                        </Form.Group>
                        {onDealCapture ? null : (
                            <Col xs={2} md={2}>
                                <SubmitButton
                                    className={styles.submitButton}
                                    onClick={handleSubmit(values =>
                                        handleSubmitEbill({values}),
                                    )}
                                    isLoading={isLoading}
                                    disabled={isLoading}
                                    block
                                >
                                    Send
                                </SubmitButton>
                            </Col>
                        )}
                    </Form.Row>
                </form>
            )}
        </>
    );
};

export default CollectEBill;
