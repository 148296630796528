import {useMutation, queryCache} from 'react-query';
import {useClient} from 'context/auth-context';
import {LimitOrderConfirmation} from 'types/order';
import {
    PENDING_TRANSACTIONS_CACHE,
    PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE,
} from 'constants/cache';

const useLimitDealRequest = () => {
    const client = useClient();

    return useMutation(data =>
        client('/web_api/limit_deal_requests', {data, method: 'POST'}),
    );
};

const useLimitDealConfirmRequest = () => {
    const client = useClient();

    return useMutation(
        (deal: LimitOrderConfirmation) => {
            const {'@id': id, expireOn} = deal;
            return client(`${id}/confirm`, {
                data: {
                    expireOn,
                },
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([PENDING_TRANSACTIONS_CACHE]);
                queryCache.invalidateQueries([
                    PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE,
                ]);
            },
        },
    );
};

interface IUseEditLimitDealRequest {
    transaction: any;
    expireOn: string;
}

const useLimitDealEditRequest = () => {
    const client = useClient();

    return useMutation<unknown, unknown, IUseEditLimitDealRequest>(
        ({transaction, expireOn}) => {
            return client(
                `/web_api/limit_deal_requests/${transaction.dealRequest.limitDealRequestId}`,
                {
                    data: {
                        expireOn,
                    },
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                        Accept: 'application/ld+json',
                    },
                },
            );
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([PENDING_TRANSACTIONS_CACHE]);
                queryCache.invalidateQueries([
                    PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE,
                ]);
            },
        },
    );
};

export {
    useLimitDealRequest,
    useLimitDealConfirmRequest,
    useLimitDealEditRequest,
};
