import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {ReactQueryConfigProvider} from 'react-query';
import {CookiesProvider} from 'react-cookie';
import {AuthProvider} from './auth-context';
import {BusinessPartnerProvider} from './business-partner-context';
import {LanguageProvider} from './language-context';
import {NotificationProvider} from './notification-context';
import {EventSourceProvider} from './event-source-context';

const queryConfig = {
    queries: {
        refetchOnWindowFocus: false,
        retry(failureCount, error) {
            if (error.status > 400) {
                return false;
            }
            if (failureCount < 2) {
                return true;
            }

            return false;
        },
    },
};

const AppProviders: React.FC = ({children}) => {
    return (
        <CookiesProvider>
            <ReactQueryConfigProvider config={queryConfig}>
                <Router>
                    <LanguageProvider>
                        <AuthProvider>
                            <BusinessPartnerProvider>
                                <EventSourceProvider>
                                    <NotificationProvider>
                                        {children}
                                    </NotificationProvider>
                                </EventSourceProvider>
                            </BusinessPartnerProvider>
                        </AuthProvider>
                    </LanguageProvider>
                </Router>
            </ReactQueryConfigProvider>
        </CookiesProvider>
    );
};

export default AppProviders;
