import React from 'react';

import {useCreateWallet} from 'api/wallets';
import {ICurrencies, IWalletCreate, WalletTypes} from 'types/api';
import Button from 'components/ui/Button';
import CurrencyAutoComplete from 'components/ui/Form/AutoComplete/CurrencyAutoComplete';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import {useBusinessPartner} from 'context/business-partner-context';
import useModal from 'components/ui/ModalContainer/useModal';
import {handleTypedFormError} from 'helpers/handleFormError';

import styles from './CreateWalletModal.module.scss';

export interface ICreateWalletFormValues {
    currency: string;
}

export interface ICreateWalletModalProps {
    currencies: ICurrencies[];
    walletType: WalletTypes;
}

const CreateWalletModal = ({
    currencies,
    walletType,
}: ICreateWalletModalProps) => {
    const {closeModal} = useModal();
    const [
        createWalletReq,
        {isLoading: isCreateWalletLoading},
    ] = useCreateWallet(walletType);
    const {activeBusinessPartner} = useBusinessPartner();

    const handleSubmit: FormOnSubmit<ICreateWalletFormValues> = (
        values,
        setError,
    ) => {
        const data: IWalletCreate = {
            ...values,
            businessPartner: activeBusinessPartner['@id'],
        };

        createWalletReq(data, {
            onSuccess: _ => {
                closeModal('createWallet');
            },
            onError: formErrors =>
                handleTypedFormError(formErrors as any, setError, values),
        });
    };

    return (
        <div className={styles.container}>
            <h3>Create new account</h3>
            <Form<ICreateWalletFormValues>
                onSubmit={handleSubmit}
                defaultValues={{currency: ''}}
            >
                <CurrencyAutoComplete
                    name="currency"
                    placeholder="Please select a currency"
                    label=""
                    options={currencies}
                    registerOptions={{
                        required: 'Required',
                    }}
                />
                <Button
                    type="submit"
                    className="mt-2"
                    isLoading={isCreateWalletLoading}
                >
                    Create account
                </Button>
            </Form>
        </div>
    );
};

export default CreateWalletModal;
