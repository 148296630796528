import React from 'react';

import {useLanguage} from 'context/language-context';
import {createFaqLink} from 'helpers/url';
import Button from '../Button';
import IconSVG from '../Icon/IconSVG';

import styles from './FaqLink.module.scss';

export interface IFaqLinkProps {
    url: string;
    title?: string;
    color?: string;
}

const FaqLink = ({url, color = '#7a7a7a', title}: IFaqLinkProps) => {
    const {selectedLanguageParams} = useLanguage();
    return (
        <Button
            icon={<IconSVG name="newInfo" color={color} />}
            iconOnly
            color="secondary"
            variant="text"
            onClick={() =>
                window.open(
                    createFaqLink(selectedLanguageParams, url),
                    '_blank',
                )
            }
            className={styles.button}
            title={title}
        />
    );
};

export default FaqLink;
