import Button from 'components/ui/Button';
import useModal from 'components/ui/ModalContainer/useModal';
import React, {useRef} from 'react';
import FA from 'react-fontawesome';

import styles from './AlertOverlay.module.scss';

export interface IAlertOverlay {
    onConfirm: () => void;
    title: string;
    description: React.ReactNode;
}

const AlertOverlay = ({onConfirm, title, description}: IAlertOverlay) => {
    const {closeModal} = useModal();
    const containerRef = useRef<HTMLDivElement>(null);

    const handleOutsideClick: React.MouseEventHandler = event => {
        if (event.target === containerRef?.current) {
            closeModal();
        }
    };

    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
        <div
            onClick={handleOutsideClick}
            ref={containerRef}
            className={styles.overlayContainer}
        >
            <div className={styles.modal}>
                <button
                    className={styles.closeButton}
                    onClick={() => closeModal()}
                >
                    <FA size="lg" name="times" />
                </button>
                <h2>{title}</h2>
                <p>{description}</p>
                <div className={styles.buttonRow}>
                    <Button variant="primary" onClick={onConfirm}>
                        Ok
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default AlertOverlay;
