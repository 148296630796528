import React from 'react';

const Download = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 25 24"
        {...props}
    >
        <mask
            id="a"
            width="25"
            height="24"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
            style={{maskType: 'alpha'}}
        >
            <path fill="#D9D9D9" d="M.53 0h24v24h-24z" />
        </mask>
        <g mask="url(#a)">
            <path
                fill="currentColor"
                d="M12.53 15.788 8.261 11.52l1.054-1.084L11.78 12.9V4.5h1.5v8.4l2.466-2.465 1.053 1.084-4.269 4.27ZM6.838 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.283v-2.711h1.5v2.711c0 .077.032.148.096.212a.294.294 0 0 0 .212.096h11.384a.294.294 0 0 0 .212-.096.294.294 0 0 0 .096-.212v-2.711h1.5v2.711c0 .505-.175.933-.525 1.283-.35.35-.777.525-1.282.525H6.838Z"
            />
        </g>
    </svg>
);

export default Download;
