import {
    STATE_OPTIONS,
    COUNTRY_CODES_FOR_STATE,
} from 'constants/statesProvinces';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import AutoComplete, {IAutoCompleteProps} from './AutoComplete';

export interface IStateAutocompleteGroup
    extends Omit<IAutoCompleteProps, 'options'> {
    countryControlKey: string;
}

const StateAutoComplete = (props: IStateAutocompleteGroup) => {
    const {countryControlKey, size, ...restProps} = props;
    const {watch} = useFormContext();
    const country = watch(countryControlKey);

    if (!COUNTRY_CODES_FOR_STATE.includes(country?.split('/').pop())) {
        return null;
    }

    return (
        <AutoComplete
            isClearable
            labelKey="label"
            valueKey="value"
            sortKey="label"
            options={STATE_OPTIONS[country.split('/').pop()] || []}
            size={size}
            {...restProps}
        />
    );
};

export default StateAutoComplete;
