import React from 'react';

const SvgNewInfo = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="none"
        {...props}
    >
        <circle cx="12" cy="12" r="8" stroke="currentColor" strokeWidth="2" />
        <path
            fill="currentColor"
            d="M12.027 9.72A1.02 1.02 0 0 1 11 8.693c0-.277.1-.52.299-.728a.998.998 0 0 1 .728-.312.97.97 0 0 1 .728.312c.208.208.312.45.312.728 0 .277-.104.52-.312.728a.99.99 0 0 1-.728.299ZM11.195 17v-6.5h1.677V17h-1.677Z"
        />
    </svg>
);

export default SvgNewInfo;
