import React from 'react';

const Pin = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M11.188 19.596v-6.18l-5.343 3.085-.814-1.41L10.388 12 4.964 8.867l.814-1.41 5.41 3.124V4.405h1.627v6.192l5.371-3.1L19 8.905l-5.357 3.092 5.29 3.055-.814 1.41-5.304-3.063v6.196h-1.627Z"
            clipRule="evenodd"
        />
    </svg>
);

export default Pin;
