import {useCallback} from 'react';

import {IAmnisBankAccount} from 'types/api';
import {
    HARD_CODED_CZK_CSOB_BANK_ACCOUNT_NUMBER,
    HARD_CODED_CZK_KOMECNI_BANK_ACCOUNT_NUMBER,
} from 'constants/bankAccounts';
import {formatIban} from 'helpers/common';
import {ROUTING_TYPE_MAPPING} from 'constants/wallet';

type FormattedBankAccountData = {
    currency: string;
    iban: string;
    bank: string;
    owner: string;
    routingType: string;
    routingCode: string;
    address: string;
    country: string;
    beneficiaryAddress: string;
};

const useBankAccount = () => {
    const getBankAccountNumber = (account: IAmnisBankAccount) => {
        if (account.currency.split('/').pop() !== 'CZK')
            return formatIban(account.bankAccountId);
        if (account.amnisBank.swift === 'KOMBCZPP')
            return HARD_CODED_CZK_KOMECNI_BANK_ACCOUNT_NUMBER;
        if (account.amnisBank.swift === 'CEKOCZPP')
            return HARD_CODED_CZK_CSOB_BANK_ACCOUNT_NUMBER;
        return formatIban(account.bankAccountId);
    };
    const formatAmnisBankAccountData = useCallback(
        (account: IAmnisBankAccount): FormattedBankAccountData => {
            return {
                currency: account.currency.split('/').pop(),
                iban: getBankAccountNumber(account),
                bank: account.amnisBank.name,
                owner: account.ownerName,
                routingType: ROUTING_TYPE_MAPPING.bic_swift,
                routingCode: account.amnisBank.swift,
                address: account.amnisBank.address,
                country: account.amnisBank.country,
                beneficiaryAddress: `${account.entityAddress}, ${
                    account.entityZip
                } ${account.entityCity}, ${
                    account.ownerName.toLowerCase().includes('europe')
                        ? 'Liechtenstein'
                        : 'Switzerland'
                }`,
            };
        },
        [],
    );

    return {formatAmnisBankAccountData};
};

export default useBankAccount;
