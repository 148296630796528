import React from 'react';
import Form from 'components/ui/Form/Form/Form';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import CrpAdminInfo from 'components/pages/OnboardingPage/components/Infos/CrpAdminInfo';
import StepTitle from '../components/StepTitle';
import {IDefaultStepProps} from '../useOnboarding';
import NextStepButton from '../components/NextStepButton';
import CrpTable from '../components/CrpTable';

import styles from './Steps.module.scss';

const CrpAdmin = (props: IDefaultStepProps) => {
    const {
        crpList,
        onSuccess,
        isCrpLoading,
        currentStep,
        legalType,
        isSubmitLoading,
        inReview,
    } = props;

    const hasAdmin = crpList?.find(crp => crp.admin) !== undefined;

    return (
        <Form onSubmit={onSuccess}>
            <div className={styles.form}>
                <StepTitle
                    title={
                        inReview
                            ? 'Is the administrator the right person?'
                            : 'Administrator'
                    }
                    subTitle={
                        <div>
                            <p>
                                The administrator of your account has the
                                following rights:
                            </p>
                            <ul>
                                <li>Inviting additional users</li>
                                <li>
                                    Editing user rights for payments and
                                    currency exchanges
                                </li>
                                <li>
                                    Defining which users are allowed to add new
                                    beneficiaries
                                </li>
                                <li>
                                    Defining card administrator to order debit
                                    cards for your team
                                </li>
                            </ul>
                        </div>
                    }
                    info={<CrpAdminInfo />}
                />
                <div className={styles.labelContainer}>
                    <span className={styles.label}>
                        Choose an administrator for your Amnis account
                    </span>
                </div>
            </div>
            <CrpTable
                crpList={crpList}
                isCrpLoading={isCrpLoading}
                variant={CrpVariants.ADMIN}
                currentStep={currentStep}
                legalType={legalType}
                inReview={inReview}
            />
            <div className={styles.form}>
                <NextStepButton
                    disabled={!hasAdmin}
                    loading={isSubmitLoading}
                />
            </div>
        </Form>
    );
};

export default CrpAdmin;
