import React from 'react';

const SvgMagnifier = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M6 11.234C6 8.366 8.345 6 11.243 6c2.873 0 5.243 2.366 5.243 5.234 0 1.232-.428 2.34-1.11 3.222l-.278.36h.877a.52.52 0 0 1 .297.128l2.615 2.61c.159.18.146.429-.013.572l-.005.004-.744.743-.004.004c-.143.159-.388.172-.568-.008L14.95 16.27a.39.39 0 0 1-.118-.288v-.904l-.362.281a5.214 5.214 0 0 1-3.227 1.108A5.234 5.234 0 0 1 6 11.234Zm5.243-3.583a3.592 3.592 0 0 0-3.589 3.583 3.575 3.575 0 0 0 3.59 3.582 3.592 3.592 0 0 0 3.588-3.582c0-1.96-1.625-3.583-3.589-3.583Z"
            clipRule="evenodd"
        />
    </svg>
);

export default SvgMagnifier;
