import React from 'react';

import Alert from 'components/ui/Alert/Alert';
import {createFaqLink} from 'helpers/url';
import {useLanguage} from 'context/language-context';

import styles from './RestrictedBusinessAlert.module.scss';

const RestrictedBusinessAlert = () => {
    const {selectedLanguageParams} = useLanguage();
    return (
        <Alert
            variant="info"
            message={
                <div data-isolate>
                    In line with our anti-money laundering policy and regulatory
                    requirements, we cannot accept customers (companies and / or
                    private individuals) involved in high-risk industries and/or
                    customers with payments involving restricted business
                    activities.&nbsp;
                    <span className={styles.bold}>
                        If you are unsure whether we support your business
                        activities, please check the following list:
                    </span>
                    &nbsp;
                    <a
                        href={createFaqLink(
                            selectedLanguageParams,
                            '/articles/6554682-restricted-industries-business-activities',
                        )}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        Restricted Industries & Business Activities
                    </a>
                </div>
            }
        />
    );
};

export default RestrictedBusinessAlert;
