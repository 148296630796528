import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';

import {
    useAddBusinessPartner,
    useUpdateBusinessPartnerOnboarding,
    useGetCompaniesList,
    useGetCompanyData,
} from 'api/business-partner';
import {useLegalForms} from 'api/legal-forms';
import {useAuth} from 'context/auth-context';
import {useFilteredCountries} from 'hooks/countries';
import {BusinessPartner, ICountry} from 'types/api';
import {
    CompanyFormValues,
    companyFormValidationSchema,
} from 'form/validation/businessPartner';
import {
    LEGAL_FORM_NATURAL_PERSON,
    LOCAL_STORAGE_BP_KEY,
} from 'constants/common';
import {handleFormError} from 'helpers/handleFormError';
import {setItemToStorage} from 'helpers/localStorage';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import AsyncAutoComplete from 'components/ui/Form/AutoComplete/AsyncAutoComplete';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import Form, {FormOnSubmit, WithFormError} from 'components/ui/Form/Form/Form';
import {OnboardingSuccessCallback} from 'types/onboarding';
import DatePicker from 'components/ui/Form/DatePicker/DatePicker';
import StateAutoComplete from 'components/ui/Form/AutoComplete/StateAutoComplete';
import {formatBirthDate} from 'helpers/dates';
import useAnalytics from 'hooks/useAnalytics';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {useBusinessIndustries} from 'api/business-industries';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import womanWithLaptop from 'assets/images/woman_with_laptop.svg';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay/ModalOverlay';
import Button from 'components/ui/Button';
import FA from 'react-fontawesome';
import useModal from 'components/ui/ModalContainer/useModal';
import NextStepButton from './NextStepButton';
import StepTitle from './StepTitle';
import styles from './CompanyForm.module.scss';

interface ICompanyFormProps {
    isEdit: boolean;
    activeBusinessPartner: BusinessPartner;
    onSuccess: OnboardingSuccessCallback;
    className?: string;
    setActiveBusinessPartner: Dispatch<SetStateAction<BusinessPartner>>;
    shouldBlockNavigation?: boolean;
    inReview?: boolean;
    currentStep: number;
}

const COUNTRIES_FOR_REGISTRY = ['CH'];

const DEFAULT_FORM_VALUES: CompanyFormValues = {
    companyName: '',
    countryOfIncorporation: '',
    address: '',
    zip: '',
    city: '',
    legalForm: '',
    gwgInfo: {
        incorporationNumber: '',
        businessIndustry: '',
    },
    WIRCustomerId: null,
    dateOfIncorporation: '',
    stateOrProvince: '',
    WIRCustomer: false,
};

const CompanyForm = (props: ICompanyFormProps) => {
    const {
        activeBusinessPartner,
        isEdit,
        onSuccess,
        setActiveBusinessPartner,
        className,
        shouldBlockNavigation,
        inReview,
        currentStep,
    } = props;

    const {openModalWithContent, closeModal} = useModal();
    const [companyId, setCompanyId] = useState('');
    const {countries, isLoading: isCountriesLoading} = useFilteredCountries(
        'onboardingCorporate',
    );
    const {
        data: businessIndustries,
        isLoading: businessIndustriesIsLoading,
    } = useBusinessIndustries();
    const {legalForms} = useLegalForms();
    const {user} = useAuth();
    const [purpose, setPurpose] = useState('');
    const [defaultValues, setDefaultValues] = useState<
        Partial<CompanyFormValues>
    >(DEFAULT_FORM_VALUES);
    const {addEvent} = useAnalytics();
    const formMethods = useForm<WithFormError<CompanyFormValues>>({
        resolver: yupResolver(companyFormValidationSchema),
    });
    const [triggerEEAModal, setTriggerEEAModal] = useState<boolean>(!isEdit);
    const [acceptedEEAModal, setAcceptedEEAModal] = useState<boolean>(false);

    const {register, setValue, watch, getValues} = formMethods;

    const countryOfIncorporation = watch('countryOfIncorporation');
    const businessIndustry = watch('gwgInfo.businessIndustry');

    register('WIRCustomer');

    useEffect(() => {
        setValue('WIRCustomer', user.WIRCustomer);
    }, [setValue, user]);

    useEffect(() => {
        if (
            triggerEEAModal &&
            businessIndustry &&
            countryOfIncorporation &&
            !acceptedEEAModal
        ) {
            openModalWithContent(
                'eeaOnboarding',
                <ModalOverlay modalKey="eeaOnboarding" closeIcon={false}>
                    <EeaInfoModal
                        onAccept={() => {
                            setAcceptedEEAModal(true);
                            closeModal('eeaOnboarding');
                        }}
                        onCancel={() => {
                            setAcceptedEEAModal(false);
                            closeModal('eeaOnboarding');
                        }}
                    />
                </ModalOverlay>,
            );
        }
    }, [
        triggerEEAModal,
        businessIndustry,
        countryOfIncorporation,
        acceptedEEAModal,
        openModalWithContent,
        closeModal,
    ]);

    const [
        getCompanyList,
        {isLoading: isCompaniesLoading},
    ] = useGetCompaniesList();

    const {data: companyData} = useGetCompanyData({
        id: companyId,
        country: countryOfIncorporation,
    });

    useEffect(() => {
        if (companyData) {
            setPurpose(companyData.purpose);
            setDefaultValues({
                ...getValues(),
                companyName: companyData.name,
                zip: companyData.zipCode,
                city: companyData.city,
                stateOrProvince: companyData.stateOrProvince,
                address: `${companyData.street} ${companyData.houseNumber}`,
                gwgInfo: {
                    ...getValues().gwgInfo,
                    incorporationNumber: companyData.incorporationNumber,
                },
                dateOfIncorporation: formatBirthDate(
                    companyData.dateOfIncorporation,
                ),
            });
        }
    }, [companyData, getValues]);

    useEffect(() => {
        if (isEdit) {
            setDefaultValues({
                companyName: activeBusinessPartner.companyName,
                countryOfIncorporation:
                    activeBusinessPartner.countryOfIncorporation,
                address: activeBusinessPartner.address,
                zip: activeBusinessPartner.zip,
                city: activeBusinessPartner.city,
                stateOrProvince: activeBusinessPartner.stateOrProvince,
                legalForm: activeBusinessPartner.legalForm,
                gwgInfo: {
                    incorporationNumber:
                        activeBusinessPartner.gwgInfo?.incorporationNumber,
                    businessIndustry:
                        activeBusinessPartner.gwgInfo?.businessIndustry,
                },
                dateOfIncorporation: formatBirthDate(
                    activeBusinessPartner.dateOfIncorporation,
                ),
                WIRCustomerId: activeBusinessPartner.WIRCustomerId ?? null,
            });
        } else {
            setDefaultValues(DEFAULT_FORM_VALUES);
        }
    }, [activeBusinessPartner, isEdit]);

    const [addBusinessPartner, {isLoading}] = useAddBusinessPartner();
    const [
        addAdditionalInfo,
        {isLoading: isEditLoading},
    ] = useUpdateBusinessPartnerOnboarding();

    const handleCompanySelected = company => {
        setCompanyId(company?.id || '');
    };

    const handleAddBusinessPartner: FormOnSubmit<CompanyFormValues> = (
        {WIRCustomer, ...data},
        setError,
    ) => {
        if (isEdit) {
            addAdditionalInfo(
                {
                    businessPartnerId: activeBusinessPartner['@id'],
                    ...data,
                    gwgInfo: {
                        ...data.gwgInfo,
                        id: activeBusinessPartner.gwgInfo?.id,
                    },
                    step: findOnboardingStepByNumber(currentStep)?.type,
                },
                {
                    onSuccess: _ => {
                        onSuccess();
                    },
                    onError: (requestErrors: any) => {
                        handleFormError(requestErrors, setError);
                    },
                },
            );
        } else {
            addBusinessPartner(
                {...data, purpose},
                {
                    onSuccess: response => {
                        // Set the new Business Partner like active
                        setActiveBusinessPartner(response);
                        setItemToStorage(LOCAL_STORAGE_BP_KEY, response.id);
                        addEvent('open_business_account');

                        onSuccess();
                    },
                    onError: (requestErrors: any) => {
                        handleFormError(requestErrors, setError);
                    },
                },
            );
        }
    };

    const handleSearch = (value: string, callBack) => {
        if (value.length > 3) {
            getCompanyList({name: value, country: countryOfIncorporation}).then(
                response => {
                    if (response.length > 0) {
                        callBack(response);
                    } else {
                        callBack([{name: value}]);
                    }
                },
            );
        } else {
            callBack(undefined);
        }
    };

    const renderCompanyNameField = () =>
        COUNTRIES_FOR_REGISTRY.includes(
            countryOfIncorporation.split('/').pop(),
        ) && !isEdit ? (
            <AsyncAutoComplete
                label="Company name according to the register"
                name="companyName"
                isLoading={isCompaniesLoading}
                onSelect={handleCompanySelected}
                onSearch={handleSearch}
                valueKey="name"
                labelKey="name"
                info="The company name must match the name on the company's certificate of incorporation. Do not use any abbreviations or brand names of your company name."
                variant="dark"
                size="large"
            />
        ) : (
            <TextInput
                label="Company name according to the register"
                name="companyName"
                placeholder="Company"
                info="The company name must match the name on the company's certificate of incorporation. Do not use any abbreviations or brand names of your company name."
                variant="dark"
                size="large"
            />
        );

    const renderAdditionalFields = () => {
        return (
            <>
                {renderCompanyNameField()}
                <AutoComplete
                    label="What is the legal form of your company?"
                    name="legalForm"
                    options={legalForms.filter(
                        form => form['@id'] !== LEGAL_FORM_NATURAL_PERSON,
                    )}
                    placeholder="Please select"
                    variant="dark"
                    disabled={inReview && isEdit}
                    size="large"
                />
                <TextInput
                    label="Company address"
                    name="address"
                    placeholder="Street"
                    variant="dark"
                    size="large"
                />
                <StateAutoComplete
                    countryControlKey="countryOfIncorporation"
                    name="stateOrProvince"
                    label="State"
                    placeholder="State"
                    variant="dark"
                    valueKey="@id"
                    size="large"
                />
                <Form.Group>
                    <TextInput
                        label="Post code"
                        name="zip"
                        placeholder="1234"
                        variant="dark"
                        className={styles.zip}
                        size="large"
                    />
                    <TextInput
                        label="City"
                        name="city"
                        placeholder="City"
                        variant="dark"
                        className={styles.city}
                        size="large"
                    />
                </Form.Group>
                <TextInput
                    label="What is your company register number?"
                    info="Please provide the local registration number of your company"
                    name="gwgInfo.incorporationNumber"
                    placeholder={
                        countryOfIncorporation?.split('/').pop() === 'CH'
                            ? 'HR ID'
                            : 'Incorporation Id'
                    }
                    variant="dark"
                    size="large"
                />
                <DatePicker
                    label="Date of incorporation"
                    name="dateOfIncorporation"
                    placeholder="01.01.1999"
                    readOnly={
                        !!activeBusinessPartner?.dateOfIncorporation &&
                        inReview &&
                        isEdit
                    }
                    maxDate={new Date()}
                    variant="dark"
                    size="large"
                />
                {user.WIRCustomer ? (
                    <TextInput
                        label="WIR ID"
                        name="WIRCustomerId"
                        placeholder="WIR ID"
                        variant="dark"
                        size="large"
                    />
                ) : null}
            </>
        );
    };

    return (
        <>
            <StepTitle
                title={
                    inReview
                        ? 'Is the information about your company up to date?'
                        : 'Tell us about your business'
                }
            />
            <Form<CompanyFormValues>
                onSubmit={handleAddBusinessPartner}
                formMethods={formMethods}
                resetValues={defaultValues}
                className={className}
                confirmLeave={shouldBlockNavigation !== false}
            >
                <CountryAutoComplete
                    name="countryOfIncorporation"
                    label="In which country is your company incorporated?"
                    placeholder="Switzerland"
                    options={countries}
                    isLoading={isCountriesLoading}
                    variant="dark"
                    disabled={inReview && isEdit}
                    size="large"
                    onChange={value => {
                        if (value && !(value as ICountry).eeaMember) {
                            setTriggerEEAModal(true);
                            setAcceptedEEAModal(false);
                        } else {
                            setTriggerEEAModal(false);
                        }
                    }}
                />
                <AutoComplete
                    label="What industry is your company in?"
                    info="If you can't find the exact industry type, please choose the closest option."
                    name="gwgInfo.businessIndustry"
                    placeholder="Please select"
                    isLoading={businessIndustriesIsLoading}
                    options={businessIndustries}
                    variant="dark"
                    sortKey="label"
                    size="large"
                />
                {countryOfIncorporation &&
                businessIndustry &&
                (!triggerEEAModal || acceptedEEAModal)
                    ? renderAdditionalFields()
                    : null}
                <NextStepButton loading={isLoading || isEditLoading} />
            </Form>
        </>
    );
};

export default CompanyForm;

const EeaInfoModal = ({
    onAccept,
    onCancel,
}: {
    onAccept: () => void;
    onCancel: () => void;
}) => {
    return (
        <div className={styles.eeaModal}>
            <div className={styles.title}>
                <img src={womanWithLaptop} alt="illustration" />
                <h3>
                    We require more information about the company to ensure
                    compliance.
                </h3>
            </div>
            <p>
                Due to the company domicile outside the European Economic Area,
                we need to charge an upfront onboarding fee of EUR 1’000 to pay
                the cost for an enhanced due diligence.
            </p>
            <p>
                We will do our best to successfully onboard your company.
                However, even with complete documentation, we cannot guarantee
                the account activation as we must comply with the legally
                mandated regulations.
            </p>
            <p>In any case, the onboarding fee is non-refundable.</p>
            <Button onClick={onAccept}>
                Agree and continue
                <FA name="arrow-right" className={styles.icon} />
            </Button>
            <Button variant="text" onClick={onCancel}>
                Cancel
            </Button>
        </div>
    );
};
