import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import SubmitButton from 'components/ui/Button/SubmitButton';

interface IProps {
    title: string;
    description: string;
    translateDescription?: boolean;
    isLoading?: boolean;
    onClose: () => void;
    onSubmit: () => void;
}

const ConfirmationModal: React.FC<IProps> = ({
    title,
    description,
    translateDescription = true,
    onClose,
    onSubmit,
    isLoading = false,
}) => (
    <>
        <Modal.Header closeButton>
            <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body translate={translateDescription ? 'yes' : 'no'}>
            {description}
        </Modal.Body>
        <Modal.Footer>
            <Button variant="light" onClick={onClose}>
                Cancel
            </Button>
            <SubmitButton
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onSubmit}
            >
                Confirm
            </SubmitButton>
        </Modal.Footer>
    </>
);

export default ConfirmationModal;
