import classNames from 'classnames';
import Button, {IButtonProps} from 'components/ui/Button';
import IconSVG from 'components/ui/Icon/IconSVG';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import React, {useEffect, useRef, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import FA from 'react-fontawesome';
import {
    isNewFileUploadStatus,
    IUploadStatus,
} from 'components/ui/Form/FileUploadButton/useFileUploadStatuses';
import {documentShape} from 'form/validation/common';
import {ValidationError} from 'yup';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './FileUploadButton.module.scss';

export interface IFileUploadButtonProps extends IDefaultControlProps {
    buttonLabel?: string;
    multi?: boolean;
    buttonVariant?: IButtonProps['variant'];
    uploadStatuses?: IUploadStatus[];
    uploadedDocument?: {id: string; name: string};
    updateStatus?: (status: IUploadStatus) => void;
    removeStatus?: (idOrDocument: string | File) => void;
    containerClassname?: string;
    hideHelperText?: boolean;
}

export const getIconForStatus = (status: IUploadStatus['status']) => {
    switch (status) {
        case 'error':
            return <IconSVG name="close" />;
        case 'loading':
            return <FA spin name="spinner" />;
        default:
            return <IconSVG name="close" />;
    }
};

const FileUploadButton = (props: IFileUploadButtonProps) => {
    const {
        name,
        label,
        info,
        disabled,
        readOnly,
        multi,
        buttonLabel = 'Upload document',
        buttonVariant = 'outline',
        uploadStatuses = [],
        updateStatus = () => false,
        removeStatus,
        containerClassname,
        hideHelperText,
        onChange,
        uploadedDocument,
    } = props;
    const [files, setFiles] = useState<File[]>([]);
    const {register, errors, setValue} = useFormContext();
    register(name);
    const firstRender = useRef(true);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = event => {
        event.persist();
        const newFiles = Array.from(event.target.files);
        newFiles.forEach(file => {
            try {
                documentShape.validateSync(file);
            } catch (err) {
                updateStatus({
                    document: file,
                    status: 'error',
                    name: file.name,
                    errorMessage:
                        err instanceof ValidationError
                            ? err.message
                            : 'Invalid file',
                });
            }
        });
        if (multi) {
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        } else {
            setFiles(newFiles);
        }
        if (onChange) {
            onChange(event);
        }
    };

    useEffect(() => {
        setValue(name, files, {
            shouldDirty: !firstRender.current,
            shouldValidate: !firstRender.current,
        });
        inputRef.current.value = null;
        if (firstRender.current) firstRender.current = false;
    }, [files, name, setValue]);

    const removeFile = (targetFile: File) => {
        setFiles(prevFiles => prevFiles.filter(file => file !== targetFile));
        if (removeStatus) {
            removeStatus(targetFile);
        }
    };

    return (
        <>
            <div className={classNames(styles.container, containerClassname)}>
                {label ? (
                    <label htmlFor={name} className={styles.label}>
                        {label}
                        {info ? <InfoPopover content={info} /> : null}
                    </label>
                ) : null}
                <div className={styles.input}>
                    {!multi && !files?.length && !uploadedDocument ? (
                        <Button
                            icon="upload"
                            variant={buttonVariant}
                            onClick={() => inputRef.current?.click()}
                            color={
                                getDeepValue(errors, name)
                                    ? 'danger'
                                    : 'primary'
                            }
                            disabled={disabled || readOnly}
                        >
                            {buttonLabel}
                        </Button>
                    ) : null}
                </div>
                <div className={styles.fileList}>
                    <div className={styles.fileNames}>
                        {uploadedDocument ? (
                            <div
                                key={uploadedDocument.id}
                                className={classNames(
                                    styles.file,
                                    styles.success,
                                )}
                            >
                                <div
                                    className={styles.fileName}
                                    data-notranslate
                                >
                                    {uploadedDocument.name}
                                </div>
                            </div>
                        ) : null}
                        {files.map(file => {
                            const upload = uploadStatuses.find(
                                doc =>
                                    isNewFileUploadStatus(doc) &&
                                    doc.document === file,
                            );
                            if (
                                uploadedDocument &&
                                upload.status === 'uploaded'
                            ) {
                                return null;
                            }

                            return (
                                <>
                                    <div
                                        key={`${file.name}${file.size}${file.lastModified}`}
                                        className={classNames(styles.file, {
                                            [styles.error]:
                                                upload?.status === 'error',
                                            [styles.success]:
                                                upload?.status === 'uploaded',
                                        })}
                                    >
                                        <div
                                            className={styles.fileName}
                                            data-notranslate
                                        >
                                            {file.name}
                                        </div>
                                        {upload?.status !== 'uploaded' ? (
                                            <Button
                                                variant="text"
                                                color="secondary"
                                                iconOnly
                                                icon={getIconForStatus(
                                                    upload?.status,
                                                )}
                                                onClick={() => removeFile(file)}
                                            />
                                        ) : null}
                                    </div>
                                    {upload?.status === 'error' ? (
                                        <p className={styles.error}>
                                            {upload?.errorMessage}
                                        </p>
                                    ) : null}
                                </>
                            );
                        })}
                    </div>
                </div>
                <input
                    data-notranslate
                    type="file"
                    multiple={multi}
                    hidden
                    name={name}
                    ref={inputRef}
                    onChange={handleChange}
                />
                {getDeepValue(errors, name)?.message ? (
                    <ErrorFeedback
                        message={getDeepValue(errors, name).message}
                    />
                ) : null}
            </div>
            {hideHelperText ? null : (
                <p className={styles.helperText}>
                    Max file size 8MB. Supported file formats are PDF, JPG, JPEG
                    or PNG.
                </p>
            )}
        </>
    );
};

export default FileUploadButton;
