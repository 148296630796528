/* eslint-disable */
import * as React from 'react';

const SvgDocument = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        className="document_svg__svg-inline--fa document_svg__fa-file document_svg__fa-w-12 document_svg__fa-lg document_svg__text-center"
        aria-hidden="true"
        data-prefix="fal"
        data-icon="file"
        viewBox="0 0 384 512"
        width="1em"
        height="1em"
        {...props}
    >
        <path
            fill="currentColor"
            d="M369.9 97.9L286 14C277 5 264.8-.1 252.1-.1H48C21.5 0 0 21.5 0 48v416c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V131.9c0-12.7-5.1-25-14.1-34zm-22.6 22.7c2.1 2.1 3.5 4.6 4.2 7.4H256V32.5c2.8.7 5.3 2.1 7.4 4.2l83.9 83.9zM336 480H48c-8.8 0-16-7.2-16-16V48c0-8.8 7.2-16 16-16h176v104c0 13.3 10.7 24 24 24h104v304c0 8.8-7.2 16-16 16z"
        />
    </svg>
);

export default SvgDocument;
