import React, {FocusEvent, InputHTMLAttributes, useState} from 'react';
import classNames from 'classnames';
import {translate} from 'helpers/localize';
import {useFormContext} from 'react-hook-form';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './TextInput.module.scss';

interface ITextInputProps extends IDefaultControlProps {
    capitalized?: boolean;
    type?: InputHTMLAttributes<HTMLInputElement>['type'];
    behavior?: 'website';
    helperText?: string;
    defaultValue?: string;
    maxLength?: number;
    showCharacterCounter?: boolean;
    errorClassName?: string;
}

const TextInput = (props: ITextInputProps) => {
    const {
        name,
        label,
        placeholder,
        className,
        info,
        disabled,
        readOnly,
        type = 'text',
        size,
        variant,
        rounded = true,
        autoComplete,
        behavior,
        registerOptions,
        onBlur,
        onChange,
        helperText,
        hidden,
        defaultValue,
        maxLength,
        capitalized = false,
        showCharacterCounter = true,
        errorClassName,
    } = props;
    const {register, errors, watch, setValue} = useFormContext();
    const value: undefined | string =
        behavior || maxLength ? watch(name) : undefined;

    const [count, setCount] = useState(
        value?.length || defaultValue?.length || 0,
    );

    const handleWebsiteBlur = () => {
        if (behavior !== 'website') return;
        if (!value) return;

        const regexp = new RegExp(/(https|http)(:\/\/)/);
        const match = regexp.exec(value);
        if (match === null) {
            setValue(name, `https://${value}`);
            return;
        }
        if (match[0] === 'http://') {
            setValue(name, `https://${value.split(match[0])[1]}`);
        }
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setValue(name, e.target.value.trim());
        if (onBlur) onBlur(e);
        handleWebsiteBlur();
    };

    const isErrorMessage = () => {
        return getDeepValue(errors, name)?.message;
    };

    const message: string = getDeepValue(errors, name)?.message;

    return (
        <div
            className={classNames(
                styles.container,
                className,
                {
                    [styles.hidden]: hidden,
                    [styles.requiredTextExtraSpace]: isErrorMessage(),
                },
                isErrorMessage() ? styles.errorContainer : null,
            )}
        >
            {label ? (
                <label htmlFor={name} className={styles.label}>
                    {label}
                    {info ? <InfoPopover content={info} /> : null}
                </label>
            ) : null}
            <input
                className={classNames(styles.input, {
                    [styles.invalid]: getDeepValue(errors, name),
                    [styles.dark]: variant === 'dark',
                    [styles.rounded]: rounded,
                    [styles.large]: size === 'large',
                    [styles.disabled]: disabled || readOnly,
                    [styles.capitalized]: capitalized,
                })}
                name={name}
                type={type}
                ref={register(registerOptions)}
                placeholder={translate(placeholder)}
                disabled={disabled}
                readOnly={readOnly}
                data-notranslate
                autoComplete={autoComplete}
                onBlur={handleBlur}
                defaultValue={defaultValue}
                autoCapitalize="characters"
                onChange={e => {
                    setCount(e.target.value.length);
                    if (onChange) {
                        onChange(e);
                    }
                }}
                maxLength={maxLength}
            />
            {maxLength && showCharacterCounter ? (
                <span
                    data-notranslate
                    className={classNames(
                        styles.characterCount,
                        info ? styles.characterCountWithInfo : null,
                    )}
                >
                    {count}/{maxLength}
                </span>
            ) : null}

            {helperText ? (
                <p className={styles.helperText}>{helperText}</p>
            ) : null}
            {isErrorMessage() ? (
                <ErrorFeedback
                    message={message}
                    errorClassName={
                        !message.includes('required') ? errorClassName : null
                    }
                />
            ) : null}
        </div>
    );
};

export default TextInput;
