import React, {ReactNode} from 'react';
import {Form} from 'react-bootstrap';

interface ILabel {
    md?: number;
    children: ReactNode;
}

const Label = ({md, children}: ILabel) => (
    <Form.Label md={md} style={{fontWeight: 'bold'}}>
        {children}
    </Form.Label>
);

export default Label;
