import React from 'react';

const SvgGroups = (props: any) => (
    <svg
        width="23"
        height="15"
        viewBox="0 0 23 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M3 6.42857C4.09375 6.42857 5 5.49107 5 4.28571C5 3.11384 4.09375 2.14286 3 2.14286C1.875 2.14286 1 3.11384 1 4.28571C1 5.49107 1.875 6.42857 3 6.42857ZM10 7.5C11.9062 7.5 13.5 5.82589 13.5 3.75C13.5 1.70759 11.9062 0 10 0C8.0625 0 6.5 1.70759 6.5 3.75C6.5 5.82589 8.0625 7.5 10 7.5ZM12.375 8.57143H12.125C11.4688 8.90625 10.75 9.10714 10 9.10714C9.21875 9.10714 8.5 8.90625 7.84375 8.57143H7.59375C5.59375 8.57143 4 10.3125 4 12.4554V13.3929C4 14.2969 4.65625 15 5.5 15H14.5C15.3125 15 16 14.2969 16 13.3929V12.4554C16 10.3125 14.375 8.57143 12.375 8.57143ZM5.40625 8.13616C5.03125 7.76786 4.53125 7.5 4 7.5H2C0.875 7.5 0 8.47098 0 9.64286V10.7143C0 11.317 0.4375 11.7857 1 11.7857H3.03125C3.25 10.2121 4.125 8.87277 5.40625 8.13616Z"
            fill="#3333FF"
        />
        <path
            d="M18 5L15 5L15 6.5L18 6.5L18 9.5L19.5 9.50001L19.5 6.5L22.5 6.50001V5.00001L19.5 5.00001L19.5 2.00001L18 2L18 5Z"
            fill="#3333FF"
        />
    </svg>
);

export default SvgGroups;
