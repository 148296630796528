import React, {useEffect} from 'react';
import {LegalType, OnboardingPageType} from 'types/onboarding';
import {IDefaultStepProps} from '../useOnboarding';
import {ReactComponent as Illustration} from '../components/illustrations/1.svg';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';

import styles from './Steps.module.scss';

const StartOnboarding = (props: IDefaultStepProps) => {
    const {
        setOnboardingPageType,
        onSuccess,
        activeBusinessPartner,
        legalType,
    } = props;

    useEffect(() => {
        setOnboardingPageType(OnboardingPageType.BLUE);

        return () => setOnboardingPageType(OnboardingPageType.WHITE);
    }, [setOnboardingPageType]);

    return (
        <div className={styles.form}>
            <div className={styles.centered}>
                <Illustration className={styles.centeredIllustration} />
            </div>
            <p className={styles.stepIllustrationSummary}>
                Section <var data-var="current-section-number">1</var> of{' '}
                <var data-var="total-section-number">4</var>
            </p>
            <StepTitle
                title="Great, the first part is done!"
                subTitle="Please have the following ready for the next section:"
            />
            <div className={styles.stepIllustrationCard}>
                {legalType === LegalType.COMPANY ? (
                    <p className={styles.listItem}>
                        Information on the beneficial owners of{' '}
                        <var data-var="companyName">
                            {activeBusinessPartner.companyName}
                        </var>
                    </p>
                ) : (
                    <p className={styles.listItem}>
                        Information on the beneficial owners
                    </p>
                )}
                {legalType === LegalType.COMPANY ? (
                    <p className={styles.listItem}>
                        Information about the authorized signatories of{' '}
                        <var data-var="companyName">
                            {activeBusinessPartner.companyName}
                        </var>
                    </p>
                ) : (
                    <p className={styles.listItem}>
                        Information about the authorized signatories
                    </p>
                )}
            </div>
            <NextStepButton
                onClick={() => onSuccess()}
                className={styles.bluePageButton}
                containerClassName={styles.bluePageButtonContainer}
            />
        </div>
    );
};

export default StartOnboarding;
