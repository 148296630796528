import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import {Button as BSButton} from 'react-bootstrap';
import FA from 'react-fontawesome';

import BusinessPartner from 'components/ui/BusinessPartner/BusinessPartner';
import UserMenu from 'components/layout/UserMenu/UserMenu';
// import NotificationButton from 'components/ui/IconButtons/NotificationButton';
import {useBusinessPartner} from 'context/business-partner-context';
import Logo from 'components/ui/Logo/Logo';
import {Link, useHistory} from 'react-router-dom';
import Button from 'components/ui/Button';
import DropdownButton from 'components/ui/DropdownButton/DropdownButton';
import IconSVG from 'components/ui/Icon/IconSVG';
import {useLanguage} from 'context/language-context';
import {PRODUCT_TOURS} from 'constants/productTours';
import {PATH} from 'constants/common';
import useCobrowse from 'components/layout/Header/useCobrowse';
import {useHeader} from '../SidebarLayout/SidebarLayout';

import styles from './Header.module.scss';

const Header = React.forwardRef<any, any>((props, forwardRef) => {
    const {handleStartCobrowse} = useCobrowse();
    const [scrollOnTop, setScrollOnTop] = useState<boolean>(true);
    const {className, onHamburgerClick, pricing} = props;
    const {activeBusinessPartner} = useBusinessPartner();
    const history = useHistory();
    const {backPath} = useHeader();
    const {selectedLanguage} = useLanguage();

    useEffect(() => {
        const handleScroll = () => {
            setScrollOnTop(window.scrollY < 20);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const productTour = PRODUCT_TOURS.find(
        tour => tour.path === history.location.pathname,
    );

    const showTour = () => {
        window.Intercom('startTour', productTour.links[selectedLanguage]);
    };

    return (
        <div
            className={classNames(styles.header, className, {
                [styles.scrolledAway]: !scrollOnTop,
                [styles.onlyCardUser]:
                    activeBusinessPartner?.permission?.onlyCardUser,
            })}
        >
            {activeBusinessPartner?.permission?.onlyCardUser ? (
                <div>
                    <Logo className={styles.logo} />
                </div>
            ) : null}
            <div className={styles.routeButtons}>
                {!pricing &&
                backPath &&
                (!activeBusinessPartner?.permission?.onlyCardUser ||
                    (activeBusinessPartner?.permission?.onlyCardUser &&
                        backPath.path === PATH.CARDS)) ? (
                    <Button
                        variant="text"
                        icon="chevron-left"
                        iconOnly={!backPath.label}
                        onClick={() => history.push(backPath.path)}
                        color={
                            activeBusinessPartner?.permission?.onlyCardUser
                                ? 'white'
                                : 'primary'
                        }
                    >
                        {backPath.label}
                    </Button>
                ) : null}
                {productTour ? (
                    <>
                        <span data-notranslate>&nbsp;|&nbsp;</span>
                        <Button variant="text" onClick={showTour}>
                            Show tour
                        </Button>
                    </>
                ) : null}
                {pricing ? (
                    <div className={styles.pricingControl}>
                        <Logo blue />
                        <button
                            className={styles.backButton}
                            type="button"
                            onClick={() => history.goBack()}
                        >
                            <FA name="chevron-left" />
                            Back
                        </button>
                    </div>
                ) : null}
            </div>
            {activeBusinessPartner?.sandbox ? (
                <div className={styles.demoContainer}>
                    <span className={styles.demo}>DEMO</span>
                </div>
            ) : null}
            <BusinessPartner
                className={styles.bpSelect}
                white={activeBusinessPartner?.permission?.onlyCardUser}
            />
            <DropdownButton
                className={styles.helpToggle}
                button={
                    <Button
                        icon={
                            <IconSVG
                                name="question"
                                customDimensions
                                width="32px"
                                height="32px"
                            />
                        }
                        iconOnly
                        className={classNames(styles.faqIcon, {
                            [styles.onlyCardUser]:
                                activeBusinessPartner?.permission?.onlyCardUser,
                        })}
                    />
                }
                dropdownElements={
                    <div className={styles.helpMenu}>
                        <a
                            href={`https://help.amnistreasury.com/${
                                selectedLanguage === 'de' ? 'de' : 'en'
                            }`}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            FAQ
                        </a>
                        <a
                            href="https://ideas.amnistreasury.com/tabs/1-under-consideration"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Product Roadmap
                        </a>
                        <a
                            href="https://ideas.amnistreasury.com/tabs/1-under-consideration/submit-idea"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Submit an idea
                        </a>
                        <button onClick={() => window.Intercom('show')}>
                            Live Chat
                        </button>
                        <button onClick={() => handleStartCobrowse()}>
                            Start Co-browsing
                        </button>
                        <Link to={PATH.PRODUCT_TOURS}>Product Tours</Link>
                    </div>
                }
            />
            <div className={styles.menu}>
                {/* <NotificationButton className={styles.notification} /> */}
                <UserMenu
                    white={activeBusinessPartner?.permission?.onlyCardUser}
                />
                {activeBusinessPartner?.sandbox ? (
                    <div className={styles.mobileDemoContainer}>
                        <span className={styles.demo}>DEMO</span>
                    </div>
                ) : null}
                {!activeBusinessPartner?.permission?.onlyCardUser ? (
                    <BSButton
                        ref={forwardRef}
                        className="d-md-none"
                        onClick={onHamburgerClick}
                    >
                        <FA className={styles.bars} name="bars" />
                    </BSButton>
                ) : null}
            </div>
        </div>
    );
});

export default Header;
