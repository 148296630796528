export interface IBankAccountTableRow {
    currency: string;
    owner: string;
    bank: string;
    address: string;
    country: string;
    routingCode: string;
    iban: string;
    beneficiaryAddress?: string;
    scheme:
        | 'SEPA only'
        | 'SEPA'
        | 'ACH (up to $5 million)'
        | 'FedWire (up to $1 million)'
        | 'Faster Payments'
        | 'SIC'
        | 'EFT'
        | 'SWIFT'
        | 'CERTIS';
}

type AmnisEntities = 'AMNIS_TREASURY' | 'AMNIS_EUROPE';
const amnisBanks = ['zurcher_kantonalbank', 'komercni_banka', 'erste'] as const;
type AmnisBanks = typeof amnisBanks[number];

export const AMNIS_BANK_ACCOUNTS: Record<
    AmnisEntities,
    Partial<Record<AmnisBanks, Omit<IBankAccountTableRow, 'currency' | 'iban'>>>
> = {
    AMNIS_TREASURY: {
        zurcher_kantonalbank: {
            address: 'Postfach, 8010 Zürich',
            bank: 'Zürcher Kantonalbank',
            country: 'CH',
            owner: 'Amnis Treasury Services AG',
            routingCode: 'ZKBKCHZZ80A',
            scheme: 'SIC',
            beneficiaryAddress: 'Baslerstrasse 60, 8048 Zürich',
        },
    },
    AMNIS_EUROPE: {
        komercni_banka: {
            address: 'Na Prikope 33 cp. 969, 114 07 Praha 1',
            bank: 'Komercni banka, a.s.',
            country: 'CZ',
            owner: 'AMNIS Europe AG',
            routingCode: 'KOMBCZPP',
            scheme: 'CERTIS',
            beneficiaryAddress: 'Gewerbeweg 15, 9490 Vaduz, Liechtenstein',
        },
        erste: {
            address: 'Am Belvedere 1, 1100 Wien',
            bank: 'Erste Group Bank AG',
            country: 'AT',
            owner: 'Amnis Europe AG',
            routingCode: 'GIBAATWGXXX',
            scheme: 'SEPA',
            beneficiaryAddress: 'Gewerbeweg 15, 9490 Vaduz, Liechtenstein',
        },
    },
};

export const HARD_CODED_CZK_KOMECNI_BANK_ACCOUNT_NUMBER = '123-6946360207/0100';
export const HARD_CODED_CZK_CSOB_BANK_ACCOUNT_NUMBER = '312264101/0300';
