import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import styles from './CountryFlag.module.sass';

interface ICountryFlagProps {
    code: string;
}

const CountryFlagSelect: React.FC<ICountryFlagProps> = ({code}) => {
    const props = {
        countryCode: code,
        svg: true,
        styles: {
            width: '100%',
            height: '100%',
        },
    };

    return (
        <span className={styles.countryFlagSelect}>
            <span className={styles.flag}>
                <ReactCountryFlag {...props} />
            </span>
        </span>
    );
};

export default CountryFlagSelect;
