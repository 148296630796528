import {useCallback, useMemo} from 'react';

import {ICountry} from 'types/api';
import {useCountries} from 'api/countries';

export function useFilteredCountries(
    filter?: keyof Pick<
        ICountry,
        | 'onboardingCorporate'
        | 'onboardingIndividual'
        | 'sendPayment'
        | 'sendPeer'
        | 'receivePayment'
        | 'receivePeer'
    >,
) {
    const {countries, isLoading} = useCountries();

    const filtered = useMemo(() => countries.filter(c => c[filter] === true), [
        countries,
        filter,
    ]);

    return {countries: filtered, isLoading};
}

export type UseCountryList = () => {
    /**
     * @param id - can be both the ID or the IRI
     * @returns the country name as string, empty string if not found
     */
    getCountryName: (id: string) => string;
    getCountryCode: (id: string) => string;
    isLoading: boolean;
};

export const useCountryList: UseCountryList = () => {
    const {countries, isLoading} = useCountries();

    const getCountryName = useCallback(
        (id: string) => {
            if (!id) return '';
            const realId = id.split('/').pop();
            return (
                countries?.find(
                    country => country['@id'].split('/').pop() === realId,
                )?.label || ''
            );
        },
        [countries],
    );

    const getCountryCode = useCallback(
        (id: string) => {
            if (!id) return '';
            const realId = id.split('/').pop();
            return (
                countries?.find(
                    country => country['@id'].split('/').pop() === realId,
                )?.code || ''
            );
        },
        [countries],
    );

    return {getCountryName, getCountryCode, isLoading};
};
