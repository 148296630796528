import React from 'react';
import {Alert, AlertProps, Button, Modal} from 'react-bootstrap';

interface IProps {
    title: string;
    description: React.ReactNode;
    variant: AlertProps['variant'];
    onClose: () => void;
}

const AlertModal: React.FC<IProps> = ({
    title,
    description,
    variant,
    onClose,
}) => (
    <>
        <Modal.Header closeButton>
            <Modal.Title as="h5">{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Alert className="mb-0" variant={variant}>
                {description}
            </Alert>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={onClose}>Ok</Button>
        </Modal.Footer>
    </>
);

export default AlertModal;
