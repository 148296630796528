import axios, {AxiosError, AxiosRequestConfig} from 'axios';
import * as Sentry from '@sentry/react';
import qs from 'qs';
import {API_URL} from '../config';
import {parseJwt} from './common';

async function client(
    endpoint: string,
    {
        data,
        token,
        headers: customHeaders,
        method,
        keepOriginal,
        logout,
        ...customConfig
    }: {
        data?: {};
        token?: string;
        headers?: any;
        method?: any;
        keepOriginal?: boolean;
        customConfig?: any;
        logout?: (errorMessage?: string) => void;
    } = {},
    fullResponse = false,
    fullError = false,
) {
    const url = new URL(`${API_URL}${endpoint}`);

    if (process.env.REACT_APP_HTTP_CLIENT_XDEBUG_QUERY_PARAMETER === '1') {
        url.searchParams.set('XDEBUG_SESSION', 'PHPSTORM');
    }

    if (window.__env__.MAINTENANCE_ACTIVE === '1') {
        if (logout) logout();
        return null;
    }
    const config: AxiosRequestConfig = {
        method: data || keepOriginal ? method : 'GET',
        // eslint-disable-next-line no-nested-ternary
        data: data ? (keepOriginal ? data : JSON.stringify(data)) : undefined,
        url: url.toString(),
        withCredentials: true,
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined,
            Accept: 'application/ld+json',
            'Content-Type': data ? 'application/ld+json' : undefined,
            ...customHeaders,
        },
        paramsSerializer: params =>
            qs.stringify(params, {
                encode: false,
                skipNulls: true,
                arrayFormat: 'brackets',
                filter: (prefix, value) => (value === '' ? undefined : value),
            }),
        ...customConfig,
    };

    if (token) {
        const tokenExpired = parseJwt(token)
            ? new Date().getTime() >= parseJwt(token).exp * 1000
            : true;

        if (tokenExpired && logout) {
            return logout();
        }
    }

    return axios(config)
        .then(response => {
            return fullResponse
                ? response.data
                : response.data['hydra:member'] ?? response.data;
        })
        .catch((error: AxiosError) => {
            if (!error.response) {
                return {};
            }

            if (error.response.status === 401) {
                Sentry.captureException(error, scope => {
                    scope.setContext('data', {
                        url: config.url,
                        method: config.method,
                        errorResponse: error.response.data,
                    });

                    return scope;
                });

                const text =
                    error.response?.data?.message === 'Invalid credentials.'
                        ? 'Invalid credentials.'
                        : 'Please re-authenticate.';

                if (logout) logout(text);
                return Promise.reject(new Error(text));
            }

            if (error.response.status === 403) {
                Sentry.captureException(error, scope => {
                    scope.setContext('data', {
                        url: config.url,
                        method: config.method,
                        payload: JSON.parse(config.data),
                    });

                    return scope;
                });
            }

            return Promise.reject(
                fullError ? error.response : error.response.data,
            );
        });
}

export {client};
