import React from 'react';

const Automation = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M4.308 19.5c-.505 0-.933-.175-1.283-.525a1.745 1.745 0 0 1-.525-1.282V6.308c0-.505.175-.933.525-1.283.35-.35.778-.525 1.283-.525h15.384c.505 0 .933.175 1.283.525.35.35.525.778.525 1.283v5.89H20v-5.89a.294.294 0 0 0-.096-.211.294.294 0 0 0-.212-.097H4.308a.294.294 0 0 0-.212.097.294.294 0 0 0-.096.211v11.385c0 .077.032.147.096.211a.294.294 0 0 0 .212.096h9.89v1.5h-9.89ZM19.5 20.635l-.985-2.15-2.15-.985 2.15-.984.985-2.15.985 2.15 2.15.984-2.15.985-.985 2.15ZM12 15.712l1.154-2.558L15.71 12l-2.557-1.153L12 8.289l-1.154 2.558L8.288 12l2.558 1.154L12 15.712Z"
        />
    </svg>
);

export default Automation;
