import React, {useCallback, useMemo, useState} from 'react';
import classNames from 'classnames';
import {
    Alert,
    Button,
    Col,
    FormControl,
    FormGroup,
    FormLabel,
    Modal,
    ModalBody,
    Row,
} from 'react-bootstrap';
import FilteredDatePicker from 'components/ui/FilteredDatePicker/FilteredDatePicker';
import {formatDateInZurichTZ, formatTime} from 'helpers/dates';
import SubmitButton from 'components/ui/Button/SubmitButton';
import {formatNumber} from 'helpers/common';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {IFxTransactionDetail} from 'types/transactions';
import {useLimitDealEditRequest} from 'api/limit-deal';
import styles from './LimitOrderEditModal.module.scss';

interface IProps {
    transaction: IFxTransactionDetail;
    onClose: () => void;
}

const LimitOrderEditModal: React.FC<IProps> = ({transaction, onClose}) => {
    const {
        currencyFrom,
        currencyTo,
        amountTo,
        amountFrom,
        platformRate,
    } = transaction;
    const expireOn = transaction.dealRequest.expireAt;
    const usedRate = transaction.midRate;
    const xigniteMidRate = transaction.midRate;

    const [expiryDate, setExpiryDate] = React.useState(
        formatDateInZurichTZ(expireOn),
    );
    const [expiryTime, setExpiryTime] = React.useState(formatTime(expireOn));
    const onChangeExpiryDate = useCallback(date => {
        setExpiryDate(date);
    }, []);

    const [editLimitDeal, {isLoading}] = useLimitDealEditRequest();

    const [error, setError] = useState<string>('');

    const handleConfirm = () => {
        const expireOnNew = `${expiryDate}T${expiryTime}`;
        editLimitDeal(
            {
                expireOn: expireOnNew,
                transaction,
            },
            {
                onSuccess: () => onClose(),
                onError: (errorResponse: any) => {
                    const message =
                        errorResponse['hydra:description'] || 'Something wrong';
                    setError(message);
                },
            },
        );
    };

    const currFrom = useMemo(() => currencyFrom.split('/').pop(), [
        currencyFrom,
    ]);
    const currTo = useMemo(() => currencyTo.split('/').pop(), [currencyTo]);
    const marketRateNumber = Number(xigniteMidRate).toFixed(4);
    const diffInPercent = (
        ((Number(usedRate) - Number(xigniteMidRate)) / Number(xigniteMidRate)) *
        100
    ).toFixed(4);
    const isDiffRed = Number.parseFloat(diffInPercent) > 10;
    const [limitRateText] = React.useState(formatNumber(usedRate, 4));
    const onChangeExpiryTime = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setExpiryTime(e.target.value);
        },
        [],
    );
    return (
        <>
            <Modal.Header closeButton={false}>
                <Modal.Title as="h5">Edit Limit-Order</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Row className={styles.rowData}>
                    <Col md={3}>Order type</Col>
                    <Col md={4}>
                        <b>Limit order</b>
                    </Col>
                    <Col md={5}>
                        <small>
                            The order will be executed as soon as the market
                            touches your limit rate.
                        </small>
                    </Col>
                </Row>
                <Row className={classNames(styles.rowData, styles.highlighted)}>
                    <Col md={3}>
                        <div>Limit rate</div>
                        <small>interbank rate</small>
                    </Col>
                    <Col md={4}>
                        <span>{limitRateText}</span>
                    </Col>
                    <Col md={5}>
                        <small>
                            <span>
                                Market rate:{' '}
                                <span data-notranslate>
                                    {currFrom} / {currTo} {marketRateNumber}
                                </span>
                            </span>
                            <span className={styles.diffBlock}>
                                <span>Difference: </span>
                                <span
                                    className={classNames(styles.diff, {
                                        [styles.red]: isDiffRed,
                                    })}
                                    data-notranslate
                                >
                                    {diffInPercent} %
                                </span>
                            </span>
                        </small>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>You buy</Col>
                    <Col md={4}>
                        <b>
                            <CurrencyFlag code={currTo} />{' '}
                        </b>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>You sell</Col>
                    <Col md={4}>
                        <b>
                            <CurrencyFlag code={currFrom} />{' '}
                        </b>
                    </Col>
                </Row>
                <Row className={styles.rowData}>
                    <Col md={3}>Expiry date</Col>
                    <FormGroup as={Col} md={4}>
                        <FormLabel className={styles.small}>
                            Expiry date
                        </FormLabel>
                        <FilteredDatePicker
                            currencyFrom={currencyFrom}
                            currencyTo={currencyTo}
                            value={expiryDate}
                            onChange={onChangeExpiryDate}
                        />
                    </FormGroup>
                    <FormGroup as={Col} md={4}>
                        <FormLabel className={styles.small}>
                            Expiry time (i.E. 12:00)
                        </FormLabel>
                        <FormControl
                            type="time"
                            value={expiryTime}
                            onChange={onChangeExpiryTime}
                        />
                    </FormGroup>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={styles.text}>
                            The order is executed at interbank rate. The usual
                            margin will be charged additionally.
                        </div>
                    </Col>
                    <Col md={12}>
                        <Alert variant="info" className="text-center">
                            Expected execution: Buy{' '}
                            <var data-var="buy-amount-currency">
                                <span className="pl-1">{`${currTo
                                    .split('/')
                                    .pop()
                                    .toUpperCase()} ${formatNumber(
                                    amountTo,
                                )}`}</span>
                            </var>{' '}
                            /{' '}
                            <var data-var="sell-amount-currency">
                                <span className="pl-1">{`${currFrom
                                    .split('/')
                                    .pop()
                                    .toUpperCase()} ${formatNumber(
                                    amountFrom,
                                )}`}</span>
                            </var>{' '}
                            at{' '}
                            <var data-var="platform-rate">
                                <span className={styles.bold}>
                                    {platformRate}
                                </span>
                            </var>
                        </Alert>
                    </Col>
                </Row>
                {error ? (
                    <Row>
                        <Col md={12}>
                            <Alert variant="danger" className="text-center">
                                <div>{error}</div>
                            </Alert>
                        </Col>
                    </Row>
                ) : null}
            </ModalBody>
            <Modal.Footer>
                <SubmitButton isLoading={isLoading} onClick={handleConfirm}>
                    Confirm
                </SubmitButton>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
            </Modal.Footer>
        </>
    );
};

export default LimitOrderEditModal;
