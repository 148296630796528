import {useQuery, useMutation, queryCache} from 'react-query';
import {useClient} from 'context/auth-context';
import {
    CRP_BP_LIST_CACHE,
    USER_CRP_CACHE,
    CRP_LIST_CACHE,
    CONNECTED_CPRS,
} from 'constants/cache';
import {BusinessPartner} from 'types/api';
import {ICrp, ICrpBpListItem} from 'api/business-partner';

export interface ICrpBpPersonalData {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    gender?: string;
    nationality?: string;
    dateOfBirth?: string;
}
export interface ICrpBpData {
    ['@id']: string;
    crp: ICrpBpPersonalData;
    admin: boolean;
    businessPartner: string;
    collectiveSigningRight: boolean;
    confidential: boolean;
    directOwner: boolean;
    hasSigningRights: boolean;
    id: number;
    isOwner: boolean;
    ownerPercentage: string | null;
    role: string | null;
}

const useCreateSigner = () => {
    const client = useClient();

    return useMutation(
        (data: any) => {
            const {phoneNumber, nationality, dateOfBirth, ...rest} = data;
            return client('/web_api/crps/signer', {
                data: rest,
                method: 'POST',
            });
        },
        {
            onSuccess: () => queryCache.invalidateQueries(CRP_LIST_CACHE),
        },
    );
};

const useCreateOwner = () => {
    const client = useClient();

    return useMutation(
        (data: any) => {
            return client('/web_api/crps/owner', {
                data,
                method: 'POST',
            });
        },
        {
            onSuccess: () => queryCache.invalidateQueries(CRP_LIST_CACHE),
        },
    );
};

const useCreateAdmin = () => {
    const client = useClient();

    return useMutation(
        (data: any) => {
            return client('/web_api/crps/admin', {
                data,
                method: 'POST',
            });
        },
        {
            onSuccess: () => queryCache.invalidateQueries(CRP_LIST_CACHE),
        },
    );
};

export interface IUseSearchCrpRequest {
    firstName: string;
    lastName: string;
    email: string;
    isOwner?: boolean;
    hasSigningRights?: boolean;
    onboardBusinessPartner: string;
}

const useSearchCrp = () => {
    const client = useClient();

    return useMutation<ICrpBpListItem, unknown, IUseSearchCrpRequest>(data => {
        return client('/web_api/crps/search', {
            data,
            method: 'POST',
        });
    });
};

export interface ICrpListItem {
    '@context': string;
    '@id': string;
    '@type': 'Crp';
    createdAt: string;
    updatedAt: string;
    lastName: string;
    firstName: string;
    phoneNumber: string;
    street: string;
    city: string;
    zip: string;
    country: string;
    email: string;
    gender: string;
    nationality: string;
    dateOfBirth: string;
    user: string;
    idType: string;
    idNumber: string;
    idIssueDate: string;
    idExpiryDate: string;
    identifiedFirstName: string;
    identifiedLastName: string;
    identified: boolean;
    role: string;
    isOwner: boolean;
    hasSigningRights: boolean;
    admin: boolean;
    pep: boolean | null;
    pepDescription: string | null;
    canChangeName: boolean;
}

const useGetCrpList = (activeBusinessPartner: BusinessPartner) => {
    const client = useClient();

    return useQuery<ICrpListItem[]>(
        [CRP_LIST_CACHE, {bp: activeBusinessPartner['@id']}],
        () =>
            client(
                `/web_api/crps?crpBusinessPartners.businessPartner=${activeBusinessPartner[
                    '@id'
                ]
                    .split('/')
                    .pop()}`,
                {},
            ),
        {
            enabled: !activeBusinessPartner.sandbox,
        },
    );
};

const useGetUserCrp = (crpId: string) => {
    const client = useClient();

    return useQuery<ICrp>([USER_CRP_CACHE, {crpId}], () => client(crpId, {}), {
        enabled: !!crpId,
    });
};

const useSetAdmin = () => {
    const client = useClient();

    return useMutation(
        (crpData: any) => {
            return client(`${crpData['@id']}/set_admin`, {
                data: crpData,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: () => queryCache.invalidateQueries(CRP_LIST_CACHE),
        },
    );
};

const useUpdateSigner = () => {
    const client = useClient();

    return useMutation((data: any) => {
        const {'@id': id} = data;

        return client(`${id}/signer`, {
            data,
            method: 'PUT',
        });
    });
};

const useUpdateOwner = () => {
    const client = useClient();

    return useMutation((data: any) => {
        const {'@id': id} = data;

        return client(`${id}/owner`, {
            data,
            method: 'PUT',
        });
    });
};

const useUpdateContractSigner = () => {
    const client = useClient();

    return useMutation((data: any) => {
        const {'@id': id} = data;

        return client(`${id}/contract_signer`, {
            data,
            method: 'PUT',
        });
    });
};

export interface IUploadCrpDocumentRequest {
    files: File[];
    description: string;
    crpId: string;
    category?: string;
}

const useUploadCrpDocument = () => {
    const client = useClient();

    return useMutation<unknown, unknown, IUploadCrpDocumentRequest>(
        ({files, description, crpId, category}) => {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('file', file);
                if (category) {
                    formData.append('category', category);
                }
            });
            formData.append('description', description);
            return client(`${crpId}/documents/upload`, {
                data: formData,
                method: 'POST',
                headers: {'Content-Type': 'multipart/form-data'},
                keepOriginal: true,
            });
        },
    );
};

const useDeleteCrpBp = () => {
    const client = useClient();

    return useMutation(
        (crpBpId: string) =>
            client(crpBpId, {
                data: {},
                method: 'DELETE',
            }),
        {
            onSuccess: () => queryCache.invalidateQueries(CRP_BP_LIST_CACHE),
        },
    );
};

const useUpdateCrp = () => {
    const client = useClient();

    return useMutation<
        unknown,
        unknown,
        Pick<ICrpListItem, '@id' | 'pep' | 'pepDescription' | 'firstName'>
    >(data => {
        const {'@id': crpId, ...rest} = data;
        return client(crpId, {
            data: rest,
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
        });
    });
};

const useGetConnectedCrps = () => {
    const client = useClient();

    return useQuery<ICrp[]>([CONNECTED_CPRS], () =>
        client('/web_api/crps/list_connected', {
            params: {
                pagination: false,
            },
        }),
    );
};

const useAddNewCrpBp = () => {
    const client = useClient();

    return useMutation<
        ICrpBpListItem,
        unknown,
        {businessPartner: string; crp: string}
    >(data =>
        client('/web_api/crp_business_partners', {
            method: 'POST',
            data,
        }),
    );
};

export {
    useCreateSigner,
    useCreateOwner,
    useCreateAdmin,
    useGetCrpList,
    useSetAdmin,
    useUpdateSigner,
    useUpdateOwner,
    useSearchCrp,
    useGetUserCrp,
    useUploadCrpDocument,
    useDeleteCrpBp,
    useUpdateCrp,
    useUpdateContractSigner,
    useGetConnectedCrps,
    useAddNewCrpBp,
};
