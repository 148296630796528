import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import styles from './AcceptTermsCheckbox.module.scss';

export interface IAcceptTermCheckboxProps {
    value: string | number | string[];
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    linkToTerms: string;
    isError: boolean;
    className?: string;
    termsName: string;
    text?: string;
    textClassName?: string;
}

const AcceptTermsCheckbox = ({
    value,
    onChange,
    linkToTerms = '/#',
    isError,
    className,
    termsName,
    text,
    textClassName,
}: IAcceptTermCheckboxProps) => {
    return (
        <div className={classNames(styles.checkboxContainer, className)}>
            <input
                id="acceptTerms"
                type="checkbox"
                value={value}
                onChange={onChange}
                className={classNames(styles.input, {
                    [styles.invalid]: isError,
                })}
                checked={value === 1}
            />
            <label
                htmlFor="acceptTerms"
                className={classNames(styles.label, {
                    [styles.invalid]: isError,
                })}
            >
                <span className={textClassName}>
                    {text || 'Please accept our'}{' '}
                    <Link target="_blank" to={linkToTerms}>
                        {termsName}
                    </Link>
                </span>
            </label>
        </div>
    );
};

export default AcceptTermsCheckbox;
