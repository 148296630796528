import React, {useEffect, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import Select, {
    ActionMeta,
    OptionProps,
    SingleValueProps,
    components,
} from 'react-select';
import {ariaLiveMessages} from 'constants/reactSelectAriaMessages';
import classNames from 'classnames';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {translate, useSortStringForLocale} from 'helpers/localize';
import {getDeepValue} from 'helpers/common';
import ErrorFeedback from '../ErrorFeedback/ErrorFeedback';
import {IDefaultControlProps} from '../Form/Form';

import styles from './AutoComplete.module.scss';

export interface IAutoCompleteProps
    extends Omit<IDefaultControlProps, 'onChange'> {
    options: {[key: string]: any}[];
    valueKey?: string;
    labelKey?: string;
    secondLabelKey?: string;
    onChange?: (
        value: unknown,
        action: ActionMeta<unknown>,
        name: string,
    ) => void;
    isMulti?: boolean;
    isClearable?: boolean;
    isLoading?: boolean;
    components?: {
        Option?: (optionProps: OptionProps<any, any>) => JSX.Element; // TODO
        SingleValue?: (singleValueProps: SingleValueProps<any>) => JSX.Element;
    };
    defaultValue?: string | string[];
    sortKey?: string;
    numericSort?: boolean;
    disableOn?: {
        field: string;
        equals: string;
    };
    containerClassname?: string;
    menuPosition?: 'fixed' | 'absolute';
    grouped?: boolean;
    translateInputContent?: boolean;
    showMultiMessage?: boolean;
}

const AutoComplete = (props: IAutoCompleteProps) => {
    const [disabledState, setDisabledState] = useState<boolean>(false);
    const {control, errors, watch, clearErrors} = useFormContext();
    const {
        name,
        label,
        valueKey = '@id',
        labelKey = 'label',
        secondLabelKey,
        options,
        defaultValue,
        rounded = true,
        variant,
        info,
        disabled,
        sortKey,
        numericSort,
        size,
        disableOn,
        registerOptions,
        containerClassname,
        className,
        menuPosition = 'absolute',
        hidden,
        readOnly,
        grouped,
        translateInputContent = true,
        placeholder,
        showMultiMessage,
        isMulti,
        ...rest
    } = props;

    const checkValue = disableOn ? watch(disableOn?.field) : undefined;

    useEffect(() => {
        if (disableOn && checkValue && checkValue === disableOn.equals) {
            setDisabledState(true);
        } else {
            setDisabledState(false);
        }
    }, [checkValue, disableOn]);

    const isErrorMessage = () => {
        return getDeepValue(errors, name)?.message;
    };

    const sortedOptions = useSortStringForLocale(options, sortKey, numericSort);
    return (
        <div
            className={classNames(styles.container, containerClassname, {
                [styles.hidden]: hidden,
                [styles.requiredTextExtraSpace]: isErrorMessage(),
            })}
        >
            {label ? (
                <label htmlFor={name} className={styles.label}>
                    {label}
                    {info !== undefined ? <InfoPopover content={info} /> : null}
                </label>
            ) : null}
            {showMultiMessage && isMulti ? (
                <p className={styles.label}>Multiple choice possible</p>
            ) : null}
            <Controller
                control={control}
                name={name}
                rules={registerOptions}
                defaultValue={defaultValue}
                translate="no"
                render={(field, state) => {
                    const {value, ...restRenderProps} = field;
                    let selectValue = null;
                    if (Array.isArray(value) && isMulti) {
                        if (grouped) {
                            const allOptions = sortedOptions?.flatMap(o =>
                                o.options.map(o2 => o2),
                            );
                            selectValue = allOptions.filter(o =>
                                value.includes(o[valueKey]),
                            );
                        } else {
                            selectValue =
                                sortedOptions?.filter(o =>
                                    value.includes(o[valueKey]),
                                ) ?? null;
                        }
                    } else if (sortedOptions) {
                        if (grouped) {
                            const allOptions = sortedOptions.flatMap(o =>
                                o.options.map(o2 => o2),
                            );
                            selectValue =
                                allOptions.find(o => o[valueKey] === value) ??
                                null;
                        } else {
                            selectValue =
                                sortedOptions.find(
                                    o => o[valueKey] === value,
                                ) ?? null;
                        }
                    }

                    return (
                        <div
                            className={styles.selectContainer}
                            translate={translateInputContent ? 'yes' : 'no'}
                        >
                            <Select
                                {...rest}
                                {...restRenderProps}
                                isMulti={isMulti}
                                components={{
                                    ...rest.components,
                                    NoOptionsMessage,
                                }}
                                placeholder={translate(
                                    placeholder || 'Select...',
                                )}
                                isDisabled={
                                    disabled || readOnly || disabledState
                                }
                                options={sortedOptions}
                                className={classNames(styles.input, className, {
                                    [styles.invalid]: getDeepValue(
                                        errors,
                                        name,
                                    ),
                                    [styles.dark]: variant === 'dark',
                                    [styles.rounded]: rounded,
                                    [styles.disabled]: disabled,
                                    [styles.large]: size === 'large',
                                })}
                                ariaLiveMessages={ariaLiveMessages}
                                classNamePrefix="amnis-new"
                                value={selectValue}
                                getOptionValue={option => option[valueKey]}
                                getOptionLabel={option =>
                                    !secondLabelKey
                                        ? option[labelKey]
                                        : `${option[labelKey]} ${option[secondLabelKey]}`
                                }
                                onChange={(changeValue, action) => {
                                    if (props.onChange) {
                                        props.onChange(
                                            changeValue,
                                            action,
                                            name,
                                        );
                                    }

                                    let newValue = null;
                                    if (changeValue !== null) {
                                        if (props.isMulti) {
                                            newValue = Array.isArray(
                                                changeValue,
                                            )
                                                ? changeValue.map(
                                                      item => item[valueKey],
                                                  )
                                                : null;
                                        } else {
                                            newValue =
                                                valueKey in changeValue
                                                    ? changeValue[valueKey]
                                                    : null;
                                        }
                                    }
                                    control.setValue(name, newValue, {
                                        shouldDirty: true,
                                        shouldValidate: true,
                                    });
                                    clearErrors('formError');
                                }}
                                menuPosition={menuPosition}
                            />
                        </div>
                    );
                }}
            />
            {isErrorMessage() ? (
                <ErrorFeedback message={getDeepValue(errors, name).message} />
            ) : null}
        </div>
    );
};

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span>{translate('No options')}</span>
        </components.NoOptionsMessage>
    );
};

export default AutoComplete;
