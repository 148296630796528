import React from 'react';

const KeyboardArrowDown = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 15.054 6.346 9.4 7.4 8.347l4.6 4.6 4.6-4.6L17.654 9.4 12 15.054Z"
        />
    </svg>
);

export default KeyboardArrowDown;
