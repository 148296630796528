import React from 'react';

const Check = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M9.55 17.654 4.215 12.32l1.07-1.07 4.265 4.266 9.165-9.166 1.07 1.07L9.55 17.654Z"
        />
    </svg>
);

export default Check;
