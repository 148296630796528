import {DragEventHandler, useRef, useState} from 'react';

export interface IUploadStatus {
    document: File;
    status: 'uploaded' | 'error' | 'loading';
    errorMessage?: string;
}

interface IProps {
    multi?: boolean;
    disabled?: boolean;
}

const useFileUpload = ({multi, disabled}: IProps) => {
    const [files, setFiles] = useState<File[]>([]);
    const [dragOver, setDragOver] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const clearUploadFile = (targetFile: File) => {
        if (inputRef.current) inputRef.current.value = '';
        setFiles(prevFiles => prevFiles.filter(file => file !== targetFile));
        setDragOver(false);
    };

    const dropHandler: DragEventHandler<HTMLDivElement> = event => {
        event.nativeEvent.preventDefault();
        if (disabled) return;
        changeHandler(Array.from(event.dataTransfer.files));
        setDragOver(false);
    };

    const dragOverHandler: DragEventHandler<HTMLDivElement> = event => {
        event.nativeEvent.preventDefault();
        if (disabled) return;
        setDragOver(true);
    };

    const dragLeaveHandler: DragEventHandler<HTMLDivElement> = event => {
        event.nativeEvent.preventDefault();
        if (disabled) return;
        setDragOver(false);
    };

    const changeHandler = (newFiles: File[]) => {
        if (multi) {
            setFiles(prevFiles => [...prevFiles, ...newFiles]);
        } else {
            setFiles(newFiles);
        }
    };

    return {
        dragHandlers: {
            onDrop: dropHandler,
            onDragOver: dragOverHandler,
            onDragLeave: dragLeaveHandler,
        },
        clearUploadFile,
        changeHandler,
        setDragOver,
        dragOver,
        inputRef,
        files,
    };
};

export default useFileUpload;
