import React from 'react';
import Col from 'react-bootstrap/Col';
import {Alert, ButtonToolbar} from 'react-bootstrap';
import Btn from '../Button';
import styles from './VerifyError.module.scss';

const VerifyError = ({title, text = '', onClick, btnTitle}) => (
    <>
        <Col md style={{textAlign: 'center', margin: '50px 0px'}}>
            <Alert variant="danger">
                <Alert.Heading>{title}</Alert.Heading>
            </Alert>
            {text && <p>{text}</p>}
        </Col>
        <Col md>
            <ButtonToolbar className="justify-content-center">
                <Btn className={styles.button} onClick={() => onClick()}>
                    {btnTitle}
                </Btn>
            </ButtonToolbar>
        </Col>
    </>
);

export default VerifyError;
