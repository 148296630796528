import {IBankAccount, IContact} from 'types/api';

export enum PayoutType {
    PEER = 'PEER',
    TRADITIONAL = 'TRADITIONAL',
}

export type PayoutRouting = {
    '@id': string;
    '@type': 'PayoutRouting';
    bank: string;
    country: string;
    currency: string;
    swift: boolean;
    addressRequired: boolean;
};

export interface IPaymentGroupMember {
    '@id': string;
    '@type': 'PaymentGroupMember';
    amount: string;
    bankAccount: IBankAccount | null;
    contact: IContact;
    peerPayment: boolean;
    allowed: boolean;
    /**
     * @property swift - null means it can be both swift and regular, true means only swift, false means no swift
     */
    swift: boolean | null;
    hasCurrencyCloudRoute: boolean;
    feeSchedule: string | null;
}

export interface IPaymentGroup {
    '@context': '/web_api/contexts/PaymentGroup';
    '@id': string;
    '@type': 'PaymentGroup';
    id: number;
    name: string;
    businessPartner: string;
    reference: string;
    feeSchedule: boolean | null;
    executionDate?: string;
    members: IPaymentGroupMember[];
}

export type CreatePaymentGroupMemberDTO = {
    contact: string;
    paymentGroup: string;
    amount?: number;
    bankAccount?: string;
};

export type UpdatePaymentGroupMemberDTO = CreatePaymentGroupMemberDTO & {
    paymentGroupMemberId: string;
};

export type MultiPayoutSummaryMember = Pick<
    IPaymentGroupMember,
    'bankAccount' | 'contact'
> & {
    id: string;
    amount: number;
    currency: string;
    reference: string;
    feeSchedule: string | null;
};
export type MultiPayoutSummary = {
    id: string;
    executionDate: string;
    swift?: boolean;
    reference: string;
    members: MultiPayoutSummaryMember[];
};
