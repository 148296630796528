import React from 'react';
import {RouteComponentProps, withRouter} from 'react-router';
import classNames from 'classnames';

import Logo from 'components/ui/Logo/Logo';
import {SIDEBAR_MENU, IMenuItem} from 'constants/menu';
import SidebarWalletDropdown from 'components/layout/Sidebar/components/SidebarWalletDropdown/SidebarWalletDropdown';
import {useBusinessPartner} from 'context/business-partner-context';

import AmnisNavLink from 'components/layout/Sidebar/components/AmnisNavLink/AmnisNavLink';
import styles from './Sidebar.module.scss';

export interface ISidebarProps extends RouteComponentProps<any> {
    handleCloseSidebar: () => void;
}

const Sidebar = ({handleCloseSidebar}: ISidebarProps) => {
    const {activeBusinessPartner} = useBusinessPartner();

    const renderNavLink = ({title, icon, link = ''}: IMenuItem) => {
        return (
            <AmnisNavLink
                icon={icon}
                label={title}
                onClick={handleCloseSidebar}
                to={link}
            />
        );
    };

    return (
        <div className={styles.sidebar}>
            <div className={styles.logoWrapper}>
                <Logo className={styles.logo} />
            </div>

            <div
                className={classNames(
                    styles.menu,
                    activeBusinessPartner?.permission?.onlyCardUser
                        ? styles.menuWithoutBalance
                        : null,
                )}
            >
                {activeBusinessPartner?.permission?.onlyCardUser ? (
                    <>{renderNavLink(SIDEBAR_MENU.cards)}</>
                ) : (
                    <>
                        <div className={styles.menuGroup}>
                            <SidebarWalletDropdown
                                onClickWallet={handleCloseSidebar}
                            />
                            {renderNavLink(SIDEBAR_MENU.dashboard)}
                            {renderNavLink(SIDEBAR_MENU.accounts)}
                            {renderNavLink(SIDEBAR_MENU.cards)}
                            {renderNavLink(SIDEBAR_MENU.payments)}
                            {renderNavLink(SIDEBAR_MENU.collections)}
                            {renderNavLink(SIDEBAR_MENU.currencies)}
                            {renderNavLink(SIDEBAR_MENU.contacts)}
                            {renderNavLink(SIDEBAR_MENU.automation)}
                            {renderNavLink(SIDEBAR_MENU.tools)}
                        </div>
                        <div className={styles.menuGroup}>
                            {renderNavLink(SIDEBAR_MENU.refer)}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default withRouter(Sidebar);
