import React, {useEffect, useState} from 'react';
import {useGetCrpBusinessPartner} from 'api/business-partner';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import {crpFormValidationSchema} from 'form/validation/businessPartner';
import AmnisLoader from 'components/ui/AmnisLoader/AmnisLoader';
import {formatBirthDate} from 'helpers/dates';
import {useAuth} from 'context/auth-context';
import {useGetUserCrp} from 'api/crp';
import {Accordion} from 'components/ui/Accordion/Accordion';
import {
    CrpVariants,
    CRP_ROLE_MAPPING,
} from 'constants/businessPartnerAttributes';
import {LegalType} from 'types/onboarding';
import CrpPersonalForm from 'components/pages/OnboardingPage/components/crp-form-parts/CrpPersonalForm';
import CrpOwnerForm from 'components/pages/OnboardingPage/components/crp-form-parts/CrpOwnerForm';
import CrpSignerForm from 'components/pages/OnboardingPage/components/crp-form-parts/CrpSignerForm';
import {useCrpStore} from 'components/pages/OnboardingPage/components/store/crpStore';
import CrpAdminForm from 'components/pages/OnboardingPage/components/crp-form-parts/CrpAdminForm';
import NextStepButton from './NextStepButton';
import styles from './CrpForm.module.scss';

export interface ICrpFormProps {
    crpBpId?: string;
    handleSubmit: FormOnSubmit<ICrpFormValues>;
    variant: CrpVariants;
    isLoggedInUser?: boolean;
    legalType: LegalType;
    formOfControl?: string;
    inReview: boolean;
}
export enum CrpAccordionSteps {
    PERSONAL = 'personal',
    OWNER = 'owner',
    SIGNER = 'signer',
}
export interface ICrpFormValues {
    ['@id']?: string;
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber?: string;
    gender?: string;
    nationality?: string;
    dateOfBirth?: string;
    street?: string;
    zip?: string;
    city?: string;
    country?: string;
    directOwner?: string;
    ownerPercentage?: string;
    role?: string;
    collectiveSigningRight?: string;
    highestEducation?: string;
    professionalPosition?: string;
}
const DEFAULT_EMPTY_VALUES = {
    lastName: '',
    firstName: '',
    email: '',
    phoneNumber: '',
    gender: '',
    nationality: '',
    dateOfBirth: '',
    street: '',
    zip: '',
    city: '',
    country: '',
    directOwner: undefined,
    ownerPercentage: '',
    professionalPosition: '',
    highestEducation: '',
    role: '',
};
const CrpForm = (props: ICrpFormProps) => {
    const [defaultValues, setDefaultValues] = useState<
        Partial<ICrpFormValues>
    >();
    const {
        crpBpId,
        handleSubmit,
        variant,
        isLoggedInUser = false,
        legalType,
        formOfControl,
        inReview,
    } = props;
    const isLoading = useCrpStore(state => state.isLoading);
    const {user} = useAuth();
    const {data: userCrp} = useGetUserCrp(user.crp);
    const {
        data: crpBpData,
        isLoading: isCrpBpDataLoading,
    } = useGetCrpBusinessPartner(crpBpId);

    useEffect(() => {
        if (crpBpData) {
            let directOwner: string;
            if (crpBpData?.directOwner === null) {
                directOwner = undefined;
            } else if (crpBpData?.directOwner === true) {
                directOwner = 'yes';
            } else if (crpBpData?.directOwner === false) {
                directOwner = 'no';
            } else {
                directOwner = '';
            }
            const defaultCrpValues: Partial<ICrpFormValues> = {
                ...crpBpData.crp,
                ...crpBpData,
                '@id': crpBpData.crp['@id'],
                dateOfBirth: formatBirthDate(crpBpData.crp.dateOfBirth),
                directOwner,
                ownerPercentage: crpBpData?.ownerPercentage || '',
                collectiveSigningRight: crpBpData.collectiveSigningRight
                    ? 'collective'
                    : 'individual',
            };
            if (
                isLoggedInUser &&
                variant === CrpVariants.CONTRACT_SIGNER &&
                !crpBpData.crp.email
            ) {
                defaultCrpValues.email = user.email;
            }
            if (
                isLoggedInUser &&
                variant === CrpVariants.CONTRACT_SIGNER &&
                !crpBpData.crp.phoneNumber
            ) {
                defaultCrpValues.phoneNumber = user.phone;
            }
            setDefaultValues(defaultCrpValues);
        }
    }, [crpBpData, isLoggedInUser, user, variant]);

    useEffect(() => {
        if (isLoggedInUser && !crpBpData) {
            if (user.crp && userCrp) {
                const existingCrpValues = {
                    ...userCrp,
                    dateOfBirth: formatBirthDate(userCrp.dateOfBirth),
                };
                if (
                    isLoggedInUser &&
                    variant === CrpVariants.CONTRACT_SIGNER &&
                    !userCrp.email
                ) {
                    existingCrpValues.email = user.email;
                }
                if (
                    isLoggedInUser &&
                    variant === CrpVariants.CONTRACT_SIGNER &&
                    !userCrp.phoneNumber
                ) {
                    existingCrpValues.phoneNumber = user.phone;
                }
                setDefaultValues(existingCrpValues);
            } else {
                const defaultCrpValues: Partial<ICrpFormValues> = {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    gender: user.gender === 1 ? 'male' : 'female',
                };
                if (isLoggedInUser && variant === CrpVariants.CONTRACT_SIGNER) {
                    defaultCrpValues.email = user.email;
                }
                if (isLoggedInUser && variant === CrpVariants.CONTRACT_SIGNER) {
                    defaultCrpValues.phoneNumber = user.phone;
                }
                setDefaultValues({
                    ...defaultCrpValues,
                    email: user.email,
                });
            }
        }
    }, [crpBpData, isLoggedInUser, user, userCrp, variant]);

    if (isCrpBpDataLoading) {
        return <AmnisLoader />;
    }

    return (
        <>
            <div className={styles.formHeader}>
                {defaultValues?.firstName && defaultValues?.lastName ? (
                    <h2 data-notranslate>
                        {defaultValues.firstName} {defaultValues.lastName}
                    </h2>
                ) : null}
                <p>{CRP_ROLE_MAPPING[variant]}</p>
            </div>
            <Form<ICrpFormValues>
                onSubmit={(values, setError) => handleSubmit(values, setError)}
                defaultValues={{...DEFAULT_EMPTY_VALUES, ...defaultValues}}
                className={styles.form}
                validationSchema={crpFormValidationSchema(
                    variant,
                    !!crpBpData?.confidential,
                    legalType,
                    formOfControl?.toString(),
                )}
                customFields={['@id']}
            >
                <Accordion toggleMode="multi">
                    <CrpPersonalForm
                        crpData={crpBpData}
                        isLoggedInUser={isLoggedInUser}
                        userCrp={userCrp}
                        variant={variant}
                        legalType={legalType}
                    />
                    {variant === CrpVariants.ADMIN &&
                    legalType === LegalType.COMPANY ? (
                        <CrpAdminForm crpData={crpBpData} />
                    ) : null}
                    {variant === CrpVariants.OWNER &&
                    legalType === LegalType.COMPANY ? (
                        <CrpOwnerForm
                            crpData={crpBpData}
                            formOfControl={formOfControl}
                            inReview={inReview}
                        />
                    ) : null}
                    {variant === CrpVariants.SIGNER ||
                    variant === CrpVariants.CONTRACT_SIGNER ? (
                        <CrpSignerForm crpData={crpBpData} />
                    ) : null}
                </Accordion>
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};
export default CrpForm;
