import {useEffect, useState} from 'react';

import {useGetAmnisBankAccounts} from 'api/bank-accounts';
import {useCountries} from 'api/countries';
import {useBusinessPartner} from 'context/business-partner-context';
import {createMaps, formatIban} from 'helpers/common';
import useBankAccount from 'hooks/useBankAccount';
import {IBusinessPartnerWallet} from 'types/api';
import {ROUTING_TYPE_MAPPING} from 'constants/wallet';
import {hasAmnisBankAccount} from 'helpers/wallets';

export interface IUseAccountDetailsModal {
    title: string | null;
    subTitle?: string;
    currency: string;
    iban: string;
    bank: string;
    owner?: string;
    routingType?: string;
    routingCode?: string;
    address: string;
    country: string;
    beneficiaryAddress?: string;
}

export const WALLET_IBAN_MAPPING = createMaps<
    Record<string, Record<'title' | 'subTitle', string>>
>({
    EUR: {
        title: 'SEPA Payment',
        subTitle:
            'Use this account to collect funds domestically from anywhere in the European Union through SEPA. For EUR collections outside the SEPA region please use the SWIFT account.',
    },
    ach_routing_number: {
        title: 'ACH Payment',
        subTitle:
            'Use this account to collect funds domestically from anywhere in the United States. Accept payments originating in the US up to $5,000,000.',
    },
    wire_routing_number: {
        title: 'FedWire Payment',
        subTitle:
            'Use this account to collect funds domestically from anywhere in the United States. Accept payments originating in the US up to $1,000,000.',
    },
    GBP: {
        title: 'UK Faster Payment',
        subTitle:
            'Accepts payments up to £250,000 originating in the UK. Funds typically arrive within a few minutes of being sent, 24/7.',
    },
    bic: {
        title: 'Domestic Swiss Franc payments (SIC)',
        subTitle:
            'Use this Swiss Francs account to collect funds domestically in Switzerland through SIC. Funds typically arrive within a few minutes of being sent. For CHF collections from outside Switzerland, please use the SWIFT account.',
    },
    CAD: {
        title: 'EFT Payment',
        subTitle:
            'Use this account to collect funds domestically from anywhere in Canada.',
    },
});

const useAccountDetailsModal = (wallet: IBusinessPartnerWallet) => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {
        data: amnisBankAccounts,
        isLoading: isBankAccountsLoading,
    } = useGetAmnisBankAccounts([wallet?.currency]);
    const [state, setState] = useState<IUseAccountDetailsModal[]>([]);
    const {countries} = useCountries();
    const {
        formatAmnisBankAccountData: formatBankAccountData,
    } = useBankAccount();

    useEffect(() => {
        if (!wallet || !amnisBankAccounts?.length || !countries?.length) return;

        if (wallet.virtualIbans === null || wallet.virtualIbans.length === 0) {
            setState(
                amnisBankAccounts.map(amnisBankAccount => {
                    return {
                        title: 'Bank account details',
                        ...formatBankAccountData(amnisBankAccount),
                        country: countries.find(
                            country =>
                                country['@id'] ===
                                amnisBankAccount.amnisBank.country,
                        )?.label,
                    };
                }),
            );
        } else {
            const result = [...wallet.virtualIbans]
                .sort((a, b) => (a.paymentType === 'regular' ? -1 : 1))
                .reduce<IUseAccountDetailsModal[]>((acc, curr) => {
                    const obj: IUseAccountDetailsModal = {
                        title:
                            curr.paymentType === 'priority'
                                ? 'SWIFT Payment'
                                : (
                                      WALLET_IBAN_MAPPING[curr.routingType] ??
                                      WALLET_IBAN_MAPPING[
                                          wallet.currency.split('/').pop()
                                      ]
                                  )?.title,
                        subTitle:
                            curr.paymentType === 'priority'
                                ? 'Use this IBAN to receive funds from everywhere'
                                : (
                                      WALLET_IBAN_MAPPING[curr.routingType] ??
                                      WALLET_IBAN_MAPPING[
                                          wallet.currency.split('/').pop()
                                      ]
                                  )?.subTitle,
                        currency: wallet.currency,
                        iban: formatIban(curr.iban),
                        bank: curr.bankName,
                        routingType:
                            ROUTING_TYPE_MAPPING[curr.routingType] ??
                            curr.routingType,
                        routingCode: curr.routingCode,
                        address: curr.address,
                        country: countries.find(
                            country => country.code === curr.country,
                        )?.label,
                    };

                    acc.push(obj);
                    return acc;
                }, []);
            if (
                wallet.currency.split('/').pop() === 'CHF' &&
                hasAmnisBankAccount(wallet) === false
            ) {
                const amnisBankAccount = amnisBankAccounts.find(
                    account =>
                        account.amnisBank['@id'] === '/web_api/amnis_banks/1',
                );

                if (amnisBankAccount) {
                    result.unshift({
                        title: 'Amnis settlement account',
                        subTitle:
                            'Fund your wallet yourself locally in Switzerland through SIC',
                        currency: wallet.currency,
                        iban: formatIban(amnisBankAccount.bankAccountId),
                        bank: amnisBankAccount.amnisBank.name,
                        owner: amnisBankAccount.ownerName,
                        address: amnisBankAccount.amnisBank.address,
                        country: 'Switzerland',
                    });
                }
            }
            setState(result);
        }
    }, [
        wallet,
        amnisBankAccounts,
        activeBusinessPartner,
        countries,
        formatBankAccountData,
    ]);

    return {
        topUpModelData: state,
        isLoading: isBankAccountsLoading,
    };
};

export default useAccountDetailsModal;
