import {useMutation} from 'react-query';

import {useClient} from 'context/auth-context';
import {IToken} from 'types/api';

const useGetMercureToken = () => {
    const client = useClient();

    return useMutation<IToken, unknown, string>(token =>
        client('/web_api/mercure/token', {token, method: 'GET'}),
    );
};

export {useGetMercureToken};
