import React from 'react';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import * as Yup from 'yup';
import {isPossiblePhoneNumber} from 'react-phone-number-input';
import Form, {FormOnSubmit} from '../Form/Form/Form';
import PhoneInput from '../Form/PhoneInput/PhoneInput';

const validationSchema = Yup.object().shape({
    phone: Yup.string()
        .required('required')
        .test('valid_phonenumber', 'Invalid phone number', value =>
            isPossiblePhoneNumber(value),
        ),
});

export interface ITwoFAPhoneEditFormValues {
    phone: string;
}

export interface ITwoFAPhoneEditProps {
    phone: string;
    handleFormSubmit: FormOnSubmit<ITwoFAPhoneEditFormValues>;
    isLoading: boolean;
    onboarding?: boolean;
}

const TwoFAPhoneEdit = ({
    phone,
    handleFormSubmit,
    isLoading,
    onboarding,
}: ITwoFAPhoneEditProps) => {
    return (
        <Form<ITwoFAPhoneEditFormValues>
            onSubmit={handleFormSubmit}
            defaultValues={{phone}}
            validationSchema={validationSchema}
            confirmLeave
        >
            <PhoneInput
                name="phone"
                label="Phone number (mobile)"
                placeholder="123456789"
                variant="dark"
                size={onboarding ? 'large' : undefined}
            />
            <NextStepButton loading={isLoading} />
        </Form>
    );
};

export default TwoFAPhoneEdit;
