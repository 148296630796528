import React from 'react';

const FilterList = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M10.25 17.75v-1.5h3.5v1.5h-3.5Zm-4-5.192v-1.5h11.5v1.5H6.25Zm-3-5.192v-1.5h17.5v1.5H3.25Z"
        />
    </svg>
);

export default FilterList;
