import React, {useCallback, useEffect, useState} from 'react';
import {usePopper} from 'react-popper';

import styles from './DropdownButton.module.scss';

export interface IDropdownButton {
    button: React.ReactElement;
    dropdownElements: React.ReactNode;
    className?: string;
}

const DropdownButton = ({
    button,
    dropdownElements,
    className,
}: IDropdownButton) => {
    const [open, setOpen] = useState<boolean>(false);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const {styles: popperStyles, attributes} = usePopper(
        referenceElement,
        popperElement,
        {
            placement: 'bottom-start',
            modifiers: [
                {
                    name: 'offset',
                    enabled: true,
                    options: {
                        offset: [0, 2],
                    },
                },
            ],
        },
    );

    const handleDocumentClick = useCallback(
        (e: MouseEvent) => {
            if (
                referenceElement === e.target ||
                referenceElement?.contains(e.target)
            ) {
                return;
            }
            setOpen(false);
        },
        [referenceElement],
    );

    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, [handleDocumentClick]);

    return (
        <>
            <div className={className}>
                {React.cloneElement(button, {
                    onClick: e => {
                        e.stopPropagation();
                        setOpen(prev => !prev);
                    },
                    setReferenceElement,
                })}
            </div>
            {open ? (
                <div
                    ref={setPopperElement}
                    style={popperStyles.popper}
                    className={styles.popper}
                    {...attributes.popper}
                >
                    <div className={styles.dropdownContainer}>
                        {dropdownElements}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default DropdownButton;
