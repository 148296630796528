import React, {useCallback, useEffect, useState} from 'react';
import {OnboardingPageType} from 'types/onboarding';
import {useLanguage} from 'context/language-context';
import {
    getItemFromStorage,
    removeItemFromStorage,
    setItemToStorage,
} from 'helpers/localStorage';
import {getPrivacyPolicy} from 'helpers/termsAndPrivacy';
import {LOCAL_STORAGE_ACCEPT_IDENTIFICATION_TERMS_KEY} from 'constants/common';
import {CONTRACT_NOT_NEEDED_KEY} from 'components/pages/OnboardingPage/steps/ContractOverview';
import {IDefaultStepProps} from '../useOnboarding';
import {ReactComponent as Illustration} from '../components/illustrations/2.svg';
import StepTitle from '../components/StepTitle';
import NextStepButton from '../components/NextStepButton';
import AcceptTermsCheckbox from '../components/AcceptTermsCheckbox';

import styles from './Steps.module.scss';

const StartOnboarding = (props: IDefaultStepProps) => {
    const {setOnboardingPageType, onSuccess, activeBusinessPartner} = props;
    const {selectedLanguage} = useLanguage();
    const [error, setError] = useState<boolean>(false);
    const [acceptTerms, setAcceptTerms] = useState<number>(0);

    useEffect(() => {
        setAcceptTerms(
            getItemFromStorage(
                LOCAL_STORAGE_ACCEPT_IDENTIFICATION_TERMS_KEY,
            ) === '1'
                ? 1
                : 0,
        );
    }, []);

    useEffect(() => {
        setOnboardingPageType(OnboardingPageType.BLUE);

        return () => setOnboardingPageType(OnboardingPageType.WHITE);
    }, [setOnboardingPageType]);

    const handleAccept = (e: React.ChangeEvent<HTMLInputElement>) => {
        const termsValue = e.target.checked ? '1' : '0';
        setItemToStorage(
            LOCAL_STORAGE_ACCEPT_IDENTIFICATION_TERMS_KEY,
            termsValue,
        );
        setAcceptTerms(+termsValue);

        if (e.target.checked) setError(false);
        else setError(true);
    };

    const handleNextStepClick = () => {
        if (
            activeBusinessPartner.permission?.admin ||
            getItemFromStorage(
                LOCAL_STORAGE_ACCEPT_IDENTIFICATION_TERMS_KEY,
            ) === '1'
        ) {
            removeItemFromStorage(CONTRACT_NOT_NEEDED_KEY);
            onSuccess();
        } else {
            setError(true);
        }
    };

    const getPrivacyPoliciyUrl = useCallback(
        () => getPrivacyPolicy(selectedLanguage),
        [selectedLanguage],
    );

    return (
        <div className={styles.form}>
            <div className={styles.centered}>
                <Illustration className={styles.centeredIllustration} />
            </div>
            <p className={styles.stepIllustrationSummary}>
                Section <var data-var="currentSectionNumber">2</var> of{' '}
                <var data-var="totalSectionNumber">4</var>
            </p>

            <StepTitle
                title="Done, your contract is drawn up! Just identify and sign."
                subTitle="Please have the following ready for the next section:"
            />
            <div className={styles.stepIllustrationCard}>
                <p className={styles.listItem}>
                    Valid identification document (passport, identity card)
                </p>
                <p className={styles.listItem}>Smartphone (phone number)</p>
                <p className={styles.listItem}>
                    Camera (smartphone, webcamera, etc...)
                </p>
            </div>
            {activeBusinessPartner.permission?.admin ? null : (
                <AcceptTermsCheckbox
                    onChange={handleAccept}
                    linkToTerms={getPrivacyPoliciyUrl()}
                    isError={error}
                    value={acceptTerms}
                    className={styles.acceptTermsCheckbox}
                    termsName="data protection policy"
                />
            )}
            <NextStepButton
                onClick={handleNextStepClick}
                className={styles.bluePageButton}
                containerClassName={styles.bluePageButtonContainer}
            />
        </div>
    );
};

export default StartOnboarding;
