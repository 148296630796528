import React from 'react';

const CheckmarkCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 11 11"
        {...props}
    >
        <circle cx="5.457" cy="5.432" r="5" fill="currentColor" />
        <g clipPath="url(#a)">
            <path
                fill="#fff"
                d="m4.922 6.188 2.32-2.185.358.337-2.678 2.52-1.608-1.512.358-.336 1.25 1.176Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M2.6 2.575h5.714v5.714H2.6z" />
            </clipPath>
        </defs>
    </svg>
);

export default CheckmarkCircle;
