import React from 'react';
import {useFormContext} from 'react-hook-form';

import {ICrpBpListItem} from 'api/business-partner';
import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import {translate} from 'helpers/localize';
import {BusinessPartner} from 'types/api';

import styles from './OwnerForm.module.scss';

const assetsOwnerItems = [
    {
        label: 'No',
        value: 'no',
    },
    {
        label: (
            <span>
                Yes. <br /> The relevant information regarding the beneficial
                owner has to be obtained by filling in a separate form.
            </span>
        ),
        value: 'yes',
    },
];

interface IOwnerProps {
    activeBusinessPartner: BusinessPartner;
    crpList: ICrpBpListItem[];
    isCrpFetched: boolean;
    isSinglePerson: boolean;
    children: React.ReactNode;
}

const OwnerForm = ({
    activeBusinessPartner,
    isSinglePerson,
    children,
}: IOwnerProps) => {
    const {watch} = useFormContext();
    const formOfControl = watch('gwgInfo.formOfControl');

    if (!isSinglePerson && !formOfControl) return null;

    const childrenWithProps = React.Children.map(children, child => {
        if (React.isValidElement(child)) {
            return React.cloneElement(
                child as React.ReactElement<{formOfControl: number}>,
                {formOfControl},
            );
        }
        return child;
    });

    const choosePersonTextOptions = {
        1: translate(
            'Please list the person(s) who own equal to or more than 25% of shares in %{companyName}? If not listed please use the "add another person" button to do so.',
            {companyName: activeBusinessPartner.companyName},
        ),
        2: translate(
            'Identify the persons who otherwise control %{companyName}',
            {companyName: activeBusinessPartner.companyName},
        ),
        3: translate(
            'Please tell us the managing director(s) of %{companyName}',
            {companyName: activeBusinessPartner.companyName},
        ),
    };

    return (
        <>
            <div className={styles.form}>
                {choosePersonTextOptions[formOfControl] ? (
                    <>
                        <div className={styles.choosePersonTextContainer}>
                            <span
                                className={styles.choosePersonTextlabel}
                                data-notranslate
                            >
                                {choosePersonTextOptions[formOfControl]}
                            </span>
                        </div>
                    </>
                ) : null}
            </div>
            {childrenWithProps}
            <div className={styles.form}>
                <CheckBoxGroup
                    defaultValue={
                        activeBusinessPartner?.gwgInfo?.assetsOwner
                            ? 'yes'
                            : 'no'
                    }
                    options={assetsOwnerItems}
                    type="radio"
                    label="Is a third person the beneficial owner of the assets booked under the above relationship?"
                    name="gwgInfo.assetsOwner"
                    variant="dark"
                    size="large"
                />
            </div>
        </>
    );
};

export default OwnerForm;
