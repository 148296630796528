import React from 'react';
import {useHistory} from 'react-router';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';
import {Alert, Modal, Table} from 'react-bootstrap';

import {useCollectEBill} from 'api/deal-request';
import {IBusinessPartnerWallet} from 'types/api';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import CollectEBill from 'components/ui/CollectEBill/CollectEBill';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {useBusinessPartner} from 'context/business-partner-context';
import {ebillValidationSchema} from 'form/validation/ebill';
import {removeThousandSeparator} from 'helpers/common';
import Button from 'components/ui/Button';
import {PATH} from 'constants/common';
import useModal from 'components/ui/ModalContainer/useModal';
import useAccountDetailsModal from './useAccountDetailsModal';
import noTrx from '../../../assets/images/illustrations/table/no-transaction.png';

import styles from './AccountDetailsModal.module.scss';

export interface IAccountDetailsModalProps {
    wallet: IBusinessPartnerWallet;
}

const AccountDetailsModal = ({wallet}: IAccountDetailsModalProps) => {
    const {activeBusinessPartner} = useBusinessPartner();
    const {topUpModelData, isLoading} = useAccountDetailsModal(wallet);
    const history = useHistory();
    const {closeModal} = useModal();
    const [
        collectEBill,
        {isLoading: isEbillLoading, isSuccess: isEbillSuccess},
    ] = useCollectEBill();

    const form = useForm({
        shouldUnregister: false,
        defaultValues: {
            amount: '',
            invoiceDate: '',
        },
        resolver: yupResolver(ebillValidationSchema),
    });

    const renderEbill = () => {
        const currency = wallet?.currency.split('/').pop();

        const handleSendEbill = ({
            values,
            onSuccess,
        }: {
            values: {amount: unknown; invoiceDate: string};
            onSuccess?: () => void;
        }) => {
            const data = {
                ...values,
                currency: `/web_api/currencies/${currency}`,
                businessPartner: activeBusinessPartner['@id'],
                amount: removeThousandSeparator(values.amount),
            };

            collectEBill(data, {
                onSuccess: () => {
                    if (onSuccess) onSuccess();
                },
            });
        };

        if (
            (currency !== 'CHF' && currency !== 'EUR') ||
            activeBusinessPartner.amnisLegalEntityCountry.split('/').pop() !==
                'CH'
        )
            return null;

        return (
            <>
                {activeBusinessPartner.ebillSubscription?.enabled ? (
                    <CollectEBill
                        currencyFrom={currency}
                        form={form}
                        isLoading={isEbillLoading}
                        isSuccess={isEbillSuccess}
                        handleSubmitEbill={handleSendEbill}
                    />
                ) : (
                    <Alert variant="info">
                        We can send you also an EBill. Please proceed to your
                        E-Banking and search for &quot;Amnis Treasury Services
                        AG&quot;
                    </Alert>
                )}
                <div className={styles.divider}>OR</div>
            </>
        );
    };

    const renderBody = () => {
        if (isLoading) {
            return <Loader />;
        }

        if (!topUpModelData) {
            return (
                <Alert variant="warning">
                    We don&apos;t allow top-up in{' '}
                    <CurrencyFlag code={wallet.currency} />
                </Alert>
            );
        }
        // ID 6431 and 8737 are special amnis demo accounts in production
        if (
            (activeBusinessPartner.sandbox ||
                !activeBusinessPartner.identified) &&
            ![6431, 8737].includes(activeBusinessPartner.id)
        ) {
            return (
                <div className={styles.sandboxModal}>
                    <p>
                        You will see your account details as soon as the
                        onboarding is completed
                    </p>
                    <div className={styles.image}>
                        <img src={noTrx} alt="illustration" />
                    </div>
                    <Button
                        onClick={() => {
                            closeModal('accountDetails');
                            history.push(PATH.ONBOARDING_OVERVIEW);
                        }}
                    >
                        Get onboarded
                    </Button>
                </div>
            );
        }

        return (
            <>
                {renderEbill()}
                {topUpModelData.map(data => (
                    <div key={`${data.iban}${data.routingType}`}>
                        <h2 className={styles.topUpTableHeading}>
                            {data.title}
                        </h2>
                        <h3 className={styles.topUpTableSubTitle}>
                            {data.subTitle}
                        </h3>
                        <Table>
                            <tbody>
                                <tr>
                                    <td className={styles.key}>Currency</td>
                                    <td>
                                        <CurrencyFlag code={data.currency} />
                                    </td>
                                </tr>
                                <tr>
                                    <td className={styles.key}>
                                        Account Holder Name
                                    </td>
                                    <td data-notranslate className={styles.key}>
                                        {data.owner ??
                                            activeBusinessPartner.companyName}
                                    </td>
                                </tr>
                                {data.beneficiaryAddress ? (
                                    <tr>
                                        <td>Beneficiary address</td>
                                        <td
                                            data-notranslate
                                            className={styles.key}
                                        >
                                            {data.beneficiaryAddress}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td className={styles.key}>IBAN/Account</td>
                                    <td data-notranslate>{data.iban}</td>
                                </tr>
                                {data.routingType &&
                                data.routingType !== 'bic' &&
                                data.routingCode ? (
                                    <tr>
                                        <td className={styles.key}>
                                            {data.routingType}
                                        </td>
                                        <td data-notranslate>
                                            {data.routingCode}
                                        </td>
                                    </tr>
                                ) : null}
                                <tr>
                                    <td className={styles.key}>Bank</td>
                                    <td
                                        data-notranslate
                                        className={styles.bankInfo}
                                    >
                                        <span>{data.bank}</span>
                                        <span>
                                            {data.address.replace(
                                                ', The Steward Building',
                                                '',
                                            )}
                                        </span>
                                        <span>{data.country}</span>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                ))}
            </>
        );
    };

    return <Modal.Body>{renderBody()}</Modal.Body>;
};

export default AccountDetailsModal;
