import React, {useCallback, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers';

import {smsValidationSchema} from 'form/validation/login';
import {
    useConfirmSMS,
    useConfirmMobileRegistration,
    useEnrollRegistration,
} from 'api/futurae';
import {obfuscatePhone, parseJwt} from 'helpers/common';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import VerifyError from 'components/ui/Errors/VerifyError';
import {useAuth} from 'context/auth-context';
import {useUpdateUser} from 'api/users';
import {handleFormError} from 'helpers/handleFormError';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import {ICardDetails} from 'types/api';

import {useCountdown} from 'hooks/common';
import Button from 'components/ui/Button';
import PinInput from 'components/ui/Form/PinInput/PinInput';
import usePinInput from 'components/ui/Form/PinInput/usePinInput';
import Form, {FormOnSubmit, WithFormError} from '../Form/Form/Form';
import TwoFAPhoneEdit, {
    ITwoFAPhoneEditFormValues,
} from '../TwoFAPhoneEdit/TwoFAPhoneEdit';

import styles from './ConfirmationMobile.module.scss';

export interface IConfirmationMobileProps {
    onSuccessfulSmsAuth: (
        isSuccessful: boolean,
        newToken?: string,
        cardDetails?: ICardDetails,
        pin?: string,
    ) => void;
    verifyErrorProps: {
        title: string;
        text: string;
        btnTitle: string;
        btnSize: string;
    };
    token: string;
    enroll: boolean;
    onboarding?: boolean;
    requestNewSms: () => void;
    isRequestSmsLoading: boolean;
    smsMfaClaimUUID: string | null;
}

const RESEND_SMS_AFTER_SECONDS = 60;

export interface IConfirmationMobileFormValues {
    pin: string;
}

const ConfirmationMobile = ({
    onSuccessfulSmsAuth,
    token,
    enroll = false,
    verifyErrorProps,
    onboarding,
    requestNewSms,
    isRequestSmsLoading,
    smsMfaClaimUUID,
}: IConfirmationMobileProps) => {
    const {user} = useAuth();
    const [verifyErrorCount, setVerifyErrorCount] = useState<number>(0);
    const [showEditNumber, setShowEditNumber] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [deviceId, setDeviceId] = useState<string>('');
    const [futuraeId, setFuturaeId] = useState(null);
    const {startCounter, timeLeft, stopCounter, restartCounter} = useCountdown(
        RESEND_SMS_AFTER_SECONDS,
        false,
    );

    const pinMethods = usePinInput(6);

    const formMethods = useForm<WithFormError<IConfirmationMobileFormValues>>({
        resolver: yupResolver(smsValidationSchema),
    });

    const {watch, handleSubmit, setError, setValue, reset} = formMethods;
    const pin = watch('pin');

    useEffect(() => {
        if (enroll) {
            setShowEditNumber(true);
        } else {
            setShowEditNumber(false);
            startCounter();
        }
    }, [enroll, startCounter]);

    useEffect(() => {
        if (user) {
            setPhoneNumber(user.phone);
        }
    }, [user]);

    useEffect(() => {
        return () => stopCounter();
    }, [stopCounter]);

    const [updateUser, {isLoading: isUpdateUserLoading}] = useUpdateUser();

    const [
        enrollNewUser,
        {isLoading: isLoadingEnroll},
    ] = useEnrollRegistration();

    const handleEnrollNewUser = () => {
        enrollNewUser(
            {token},
            {
                onSuccess: response => {
                    setShowEditNumber(false);
                    startCounter();
                    setFuturaeId(response['@id'].split('/').pop());
                    setDeviceId(response.deviceId);
                },
                onError: response => {
                    handleFormError(response, setError);
                },
            },
        );
    };

    const handlePhoneEditSubmit: FormOnSubmit<ITwoFAPhoneEditFormValues> = (
        values,
        setPhoneError,
    ) => {
        const {phone} = values;
        if (phone && phone !== user.phone) {
            setPhoneNumber(phone);
            setPhoneError('phone', {message: ''});
            updateUser(
                {'@id': user['@id'], phone},
                {
                    onSuccess: () => handleEnrollNewUser(),
                    onError: response => {
                        handleFormError(response, setPhoneError);
                    },
                },
            );
        } else {
            setPhoneNumber(user.phone);
            handleEnrollNewUser();
        }
    };

    const [confirmSms, {isLoading: isLoadingconfirmSms}] = useConfirmSMS();
    const [
        confirmMobileRegistration,
        {isLoading: isLoadingRegistration},
    ] = useConfirmMobileRegistration();

    const getNewSms = () => {
        reset({}, {});
        pinMethods.resetPinInput();
        if (enroll) {
            handleEnrollNewUser();
        } else {
            requestNewSms();
        }
        setVerifyErrorCount(0);
        restartCounter();
    };

    const setWrongSms = useCallback(() => {
        setVerifyErrorCount(currentCount => currentCount + 1);
        if (setError) {
            setError('formError', {
                message: 'Wrong Sms Code',
                type: 'error',
            });
        }
    }, [setError]);

    const onSubmit = useCallback(
        (values: IConfirmationMobileFormValues) => {
            setValue('pin', '');
            if (enroll && futuraeId) {
                confirmMobileRegistration(
                    {
                        passCode: values.pin,
                        deviceId,
                        futuraeId,
                        token,
                    },
                    {
                        onSuccess: data =>
                            onSuccessfulSmsAuth(true, data.token),
                        onError: (confirmErrors: any) => {
                            handleFormError(confirmErrors, setError);
                        },
                    },
                );
            } else {
                const decodedToken = parseJwt(token);
                confirmSms(
                    {
                        user: decodedToken['@id'],
                        token,
                        passCode: String(values.pin),
                        mfaClaimUUID: smsMfaClaimUUID,
                    },
                    {
                        onSuccess: data => {
                            if (data.result !== 'deny') {
                                if (data.pin) {
                                    onSuccessfulSmsAuth(
                                        true,
                                        undefined,
                                        undefined,
                                        data.pin,
                                    );
                                } else if (
                                    data.pan &&
                                    data.cvv2 &&
                                    data.expiry
                                ) {
                                    onSuccessfulSmsAuth(true, undefined, {
                                        pan: data.pan,
                                        cvv2: data.cvv2,
                                        expiry: data.expiry,
                                        variant: 'default',
                                    });
                                } else {
                                    onSuccessfulSmsAuth(true, data.token);
                                }

                                return;
                            }

                            setWrongSms();
                        },
                        onError: () => setWrongSms(),
                    },
                );
            }
        },
        [
            confirmMobileRegistration,
            confirmSms,
            deviceId,
            enroll,
            futuraeId,
            onSuccessfulSmsAuth,
            smsMfaClaimUUID,
            token,
            setError,
            setWrongSms,
            setValue,
        ],
    );

    useEffect(() => {
        if (pin?.length === 6) {
            handleSubmit(onSubmit)();
        }
    }, [handleSubmit, onSubmit, pin]);

    if (isRequestSmsLoading) {
        return <Loader />;
    }

    if (verifyErrorCount === 5) {
        return (
            <VerifyError
                {...verifyErrorProps}
                onClick={() => onSuccessfulSmsAuth(false)}
            />
        );
    }

    const decodedToken = parseJwt(token);

    if (showEditNumber) {
        return (
            <TwoFAPhoneEdit
                phone={phoneNumber}
                handleFormSubmit={handlePhoneEditSubmit}
                isLoading={isUpdateUserLoading || isLoadingEnroll}
                onboarding={onboarding}
            />
        );
    }

    return (
        <Form<IConfirmationMobileFormValues>
            formMethods={formMethods}
            onSubmit={onSubmit}
            className={styles.form}
        >
            {onboarding ? null : (
                <>
                    <p className={styles.title}>Enter Verification Code</p>
                    <p className={styles.text}>
                        We’ve sent you a text with the confirmation code to your
                        phone &nbsp;
                        <var data-var="phone-number">
                            {phoneNumber
                                ? obfuscatePhone(phoneNumber)
                                : decodedToken?.phoneNumber}
                        </var>
                    </p>
                </>
            )}
            <PinInput methods={pinMethods} />

            <div className={styles.buttonRow}>
                {timeLeft === 0 ? (
                    <Button className={styles.button} onClick={getNewSms}>
                        Get New SMS
                    </Button>
                ) : (
                    <>
                        <NextStepButton
                            loading={
                                isLoadingRegistration || isLoadingconfirmSms
                            }
                        />
                        <p className={styles.countDown}>
                            Code didn&apos;t arrive? You can retry in&nbsp;
                            <var data-var="timeleft">
                                00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}
                            </var>
                        </p>
                    </>
                )}
            </div>
        </Form>
    );
};

export default ConfirmationMobile;
