import {queryCache, useMutation, useQuery} from 'react-query';
import {useClient} from 'context/auth-context';
import {CRP_BP_LIST_CACHE, IDENTIFICATION_LIST_CACHE} from 'constants/cache';
import {IdentificationChannel} from 'components/pages/OnboardingPage/steps/Identification';

const useIdentification = () => {
    const client = useClient();
    return useMutation(({channel}: {channel: IdentificationChannel}) => {
        return client('/web_api/identifications', {
            data: {
                channel,
            },
            method: 'POST',
        });
    });
};

export interface IIdentification {
    '@id': string;
    '@type': 'Identification';
    id: number;
    externalId: string;
    crp: string;
    url: string;
    status: string;
    channel: string;
}

const useGetCurrentIdentification = crp => {
    const client = useClient();

    return useQuery<IIdentification>(
        [IDENTIFICATION_LIST_CACHE, {crp: crp ? crp['@id'] : null}],
        () =>
            client(`${crp['@id']}/identifications?current=1`, {}).then(
                response => response[0] ?? null,
            ),
        {
            enabled: crp,
            onSuccess: () => queryCache.invalidateQueries([CRP_BP_LIST_CACHE]),
        },
    );
};

export {useIdentification, useGetCurrentIdentification};
