import React from 'react';

const SvgClose = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="m7.371 17.5-.871-.87L11.129 12 6.5 7.372l.871-.872L12 11.13l4.629-4.63.871.872L12.871 12l4.629 4.63-.871.87L12 12.871l-4.629 4.63Z"
        />
    </svg>
);

export default SvgClose;
