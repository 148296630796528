import {useMutation} from 'react-query';
import {useClient} from 'context/auth-context';
import {useNotification} from 'context/notification-context';
import {BusinessPartner} from 'types/api';

export type ExportType = 'PDF' | 'CSV' | 'XLSX' | 'XML';
export interface IUseGetExportFile {
    type: ExportType;
    url: string;
    params?: Record<string, any>;
}

export interface IUseGetExportFileResponse {
    fileName: string;
    content: string;
    contentType?: string;
}

const useGetExportFile = () => {
    const client = useClient();

    return useMutation(
        ({url, type, params}: IUseGetExportFile) =>
            client(url, {
                params,
            }),
        {
            onSuccess: (data: IUseGetExportFileResponse) => {
                // get part of string after the last dot
                const extension = data.fileName.split('.').pop();
                const link = document.createElement('a');
                link.href = `data:application/${extension.toLowerCase()};base64, ${escape(
                    data.content,
                )}`;
                link.target = '_blank';
                link.download = data.fileName;
                link.click();
                link.remove();
            },
        },
    );
};

interface IUseExportZipRequest {
    businessPartner: BusinessPartner;
    month: string;
    card?: string;
}

const useExportZip = () => {
    const client = useClient();
    const {addNotification} = useNotification();

    return useMutation<unknown, unknown, IUseExportZipRequest>(
        ({businessPartner, month, card}) =>
            client('/web_api/card_transaction_export_requests', {
                method: 'POST',
                data: {
                    businessPartner: businessPartner['@id'],
                    month,
                    card,
                },
            }),
        {
            onSuccess: () =>
                addNotification(
                    {
                        area: 'debitCards',
                        status: 'success',
                        message: 'ZIP export will be sent to your email',
                    },
                    'zip_export',
                ),
        },
    );
};

export {useGetExportFile, useExportZip};
