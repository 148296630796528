import React, {useState, useCallback, useMemo, useEffect} from 'react';
import * as Sentry from '@sentry/react';
import {useGetUser, User} from 'api/users';
import Loader from 'components/ui/AmnisLoader/AmnisLoader';
import {client} from 'helpers/api-client';
import {queryCache} from 'react-query';
import {
    getItemFromStorage,
    removeItemFromStorage,
    setItemToStorage,
} from 'helpers/localStorage';
import {LOCAL_STORAGE_AUTH_KEY, PATH} from 'constants/common';
import {useHistory} from 'react-router';
import {useBusinessPartner} from 'context/business-partner-context';
import {useResolveInviteStore} from 'components/pages/ResolveInvitePage/resolveInviteStore';
import {useLanguage} from './language-context';

const AuthContext = React.createContext(null);
AuthContext.displayName = 'AuthContext';

function AuthProvider(props) {
    const history = useHistory<{from?: string}>();
    const [user, setUser] = useState<User>(null);
    const {updateLanguageById} = useLanguage();
    const [isAfterRegistration, setIsAfterRegistration] = useState<boolean>(
        false,
    );
    const [afterRegRedirectUrl, setAfterRegRedirectUrl] = useState<string>();
    const [token, setToken] = useState(
        getItemFromStorage(LOCAL_STORAGE_AUTH_KEY),
    );
    const [loginScreenErrorMessage, setLoginScreenErrorMessage] = useState<
        string
    >('');
    const hash = useResolveInviteStore(state => state.hash);
    const type = useResolveInviteStore(state => state.type);

    const onSuccess = useCallback(
        (data: User) => {
            if (data.language) {
                updateLanguageById(data.language);
            }
            setUser(data);

            if (hash) {
                const path =
                    type === 'business-partner'
                        ? `/business-partner-invite/${hash}`
                        : `/contact-invite/${hash}`;

                history.push(path);
            }
        },
        [hash, history, type, updateLanguageById],
    );

    const logout = useCallback((errorMessage?: string) => {
        removeItemFromStorage(LOCAL_STORAGE_AUTH_KEY);
        useResolveInviteStore.persist.clearStorage();
        setLoginScreenErrorMessage(errorMessage);
        setToken(null);
        setUser(null);
    }, []);

    useEffect(() => {
        if (!user && !token) {
            queryCache.clear();
        }
    }, [user, token]);

    const {data: userData, isIdle, isLoading, isSuccess} = useGetUser(
        token,
        logout,
    );

    useEffect(() => {
        if (userData) onSuccess(userData);
    }, [onSuccess, userData]);

    const login = useCallback(
        (data, isAfter, redirectUrl) => {
            if (!data || typeof data !== 'string') {
                Sentry.captureException(new Error(`Invalid token: ${data}`));
                setLoginScreenErrorMessage(
                    'There was an error during login, please try again or get in touch with us. Error code I-004.',
                );
                history.push(PATH.LOGIN);
                return;
            }
            setLoginScreenErrorMessage('');
            setItemToStorage(LOCAL_STORAGE_AUTH_KEY, data);
            setToken(data);
            if (redirectUrl) {
                setAfterRegRedirectUrl(redirectUrl);
                history.push('');
            }
            if (isAfter) {
                setIsAfterRegistration(true);
            }

            if (
                history.location.state?.from &&
                redirectUrl !== PATH.DASHBOARD
            ) {
                history.push(history.location.state.from);
            }
        },
        [history],
    );

    const updateUserWith2fa = useCallback((futuraeId: string) => {
        setUser(prev => ({...prev, futurae: futuraeId}));
    }, []);

    const values = useMemo(
        () => ({
            user,
            isAfterRegistration,
            token,
            loginScreenErrorMessage,
            logout,
            login,
            setToken,
            redirectUrl: afterRegRedirectUrl,
            updateUserWith2fa,
        }),
        [
            user,
            isAfterRegistration,
            token,
            loginScreenErrorMessage,
            logout,
            login,
            setToken,
            afterRegRedirectUrl,
            updateUserWith2fa,
        ],
    );

    if (isLoading) {
        return <Loader big />;
    }

    if (isSuccess || isIdle) {
        if (userData && !user) onSuccess(userData);
        return <AuthContext.Provider value={values} {...props} />;
    }

    if (logout) {
        logout();
    }
}

const useAuth = (): {
    user: User;
    token: string;
    isAfterRegistration: boolean;
    loginScreenErrorMessage: string;
    logout: (errorMessage?: string) => void;
    login: (
        token: string,
        isAfterRegistration?: boolean,
        redirectUrl?: string,
    ) => void;
    redirectUrl: string;
    setToken: (token: string) => void;
    updateUserWith2fa: (futuraeId: string) => void;
} => {
    const context = React.useContext(AuthContext);
    if (context === undefined) {
        throw new Error(`useAuth must be used within a AuthProvider`);
    }
    return context;
};

function useClient() {
    const auth = useAuth();
    const businessPartner = useBusinessPartner();

    return React.useCallback(
        (
            endpoint: string,
            config,
            fullResponse?: boolean,
            fullError?: boolean,
        ) => {
            const headers = {...config.headers};

            if (businessPartner && businessPartner.activeBusinessPartner) {
                headers['Business-Partner'] =
                    businessPartner.activeBusinessPartner.id;
            }
            return client(
                endpoint,
                {
                    ...config,
                    token: auth?.token,
                    logout: auth?.logout,
                    headers,
                },
                fullResponse,
                fullError,
            );
        },
        [auth, businessPartner],
    );
}

export {AuthProvider, useAuth, useClient};
