import {useMutation, useQuery} from 'react-query';
import {client} from 'helpers/api-client';
import {RegistrationType} from 'types/user';
import {INVITE_HASH_CACE} from 'constants/cache';
import {IConnectInviteBusinessPartner} from 'types/api';
import {BusinessPartnerInvite} from 'types/api/business-partner-invite';
import {
    InviteType,
    useResolveInviteStore,
} from 'components/pages/ResolveInvitePage/resolveInviteStore';
import {useHistory} from 'react-router';
import {PATH} from 'constants/common';

function useRegisterUser() {
    return useMutation(
        ({
            requestData,
            recaptchaToken,
            currentLanguageId,
        }: {
            requestData: RegistrationType;
            recaptchaToken: string;
            currentLanguageId: string;
        }) => {
            const data = {
                ...requestData,
                captcha: recaptchaToken,
                gender: Number(requestData.gender),
                language: currentLanguageId,
            };

            return client('/web_api/users', {data, method: 'POST'});
        },
    );
}

function useUpdateUserInfo({
    registerToken,
    userId,
}: {
    registerToken?: string;
    userId?: string;
}) {
    return useMutation((data: any) => {
        return client(userId, {
            data,
            token: registerToken,
            headers: {
                'Content-Type': 'application/vnd.api+json',
            },
            method: 'PATCH',
        });
    });
}

export type InviteData = IConnectInviteBusinessPartner | BusinessPartnerInvite;

function useGetInviteData({hash, type}: {hash: string; type: InviteType}) {
    const history = useHistory();
    let url: string;

    switch (type) {
        case 'business-partner': {
            url = 'business_partner_invites';
            break;
        }
        case 'contact': {
            url = 'contact_invites';
            break;
        }
        case null: {
            break;
        }
        default: {
            throw new Error(`No API url for invite type: ${type}`);
        }
    }

    return useQuery<InviteData>(
        [INVITE_HASH_CACE, {url}],
        () => client(`/web_api/${url}/${hash}`, {}),
        {
            enabled: !!hash && !!type,
            onError: errorResponse => {
                if (errorResponse?.['hydra:description'] === 'Not Found') {
                    useResolveInviteStore.persist.clearStorage();
                    history.push(PATH.DASHBOARD);
                }
            },
        },
    );
}

export {useRegisterUser, useUpdateUserInfo, useGetInviteData};
