import React, {useState} from 'react';
import {useHistory} from 'react-router';
import classNames from 'classnames';

import {IBusinessPartnerWallet} from 'types/api';
import useSidebarWalletDropdown from 'components/layout/Sidebar/components/SidebarWalletDropdown/useSidebarWalletDropdown';
import {PATH} from 'constants/common';
import {formatNumber, formatNumberWithAbbreviation} from 'helpers/common';
import SidebarWalletDropdownLoader from 'components/layout/Sidebar/components/SidebarWalletDropdown/SidebarWalletDropdownLoader';
import {useBusinessPartner} from 'context/business-partner-context';
import IconSVG from 'components/ui/Icon/IconSVG';
import {translate} from 'helpers/localize';
import WithTooltip from 'components/ui/WithTooltip/WithTooltip';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import styles from './SidebarWalletDropdown.module.scss';

interface ISidebarWalletDropdownProps {
    onClickWallet?: () => void;
}

const SidebarWalletDropdown = ({
    onClickWallet,
}: ISidebarWalletDropdownProps) => {
    const {
        currency,
        overallBalance,
        wallets,
        isLoading,
    } = useSidebarWalletDropdown();
    const {activeBusinessPartner} = useBusinessPartner();
    const history = useHistory();
    const [open, setOpen] = useState<boolean>(false);

    const handleChangeWallet = (targetWallet: IBusinessPartnerWallet) => {
        if (onClickWallet) {
            onClickWallet();
        }
        setOpen(false);
        history.push({
            pathname: `${PATH.ACCOUNTS}/${targetWallet.currency
                .split('/')
                .pop()}`,
            search: history.location.search,
        });
    };

    return (
        <>
            <button
                className={classNames(styles.button, {
                    [styles.dropdownOpen]: open,
                })}
                onClick={e => {
                    setOpen(prev => !prev);
                }}
            >
                {isLoading ? (
                    <SidebarWalletDropdownLoader />
                ) : (
                    <div className={styles.buttonWrapper}>
                        <div className={styles.titleRow}>
                            <p>Your total balance</p>
                            <IconSVG name="balance" className={styles.icon} />
                        </div>
                        <div
                            className={classNames(styles.overallBalanceRow, {
                                [styles.smaller]: overallBalance > 99_999.99,
                                [styles.smallest]:
                                    overallBalance > 9_999_999_999.99,
                            })}
                            data-notranslate
                        >
                            <h4>
                                {formatNumber(overallBalance)}&nbsp;
                                {currency?.code}
                            </h4>
                            {open ? (
                                <IconSVG name="keyboardArrowUp" />
                            ) : (
                                <IconSVG name="keyboardArrowDown" />
                            )}
                        </div>
                    </div>
                )}
            </button>
            {!isLoading && open ? (
                <div className={styles.list}>
                    <div className={styles.listScrollContainer}>
                        {wallets
                            .filter(w => w['@id'] !== currency['@id'])
                            .map(w => {
                                return (
                                    <button
                                        key={w['@id']}
                                        className={styles.walletButton}
                                        onClick={() => handleChangeWallet(w)}
                                        data-notranslate
                                    >
                                        <div className={styles.amountRow}>
                                            <CurrencyFlag
                                                code={w.currency}
                                                title={false}
                                            />
                                            <span>
                                                {activeBusinessPartner.referenceCurrency ===
                                                w.currency ? (
                                                    <WithTooltip
                                                        tooltip={translate(
                                                            'Reference Currency',
                                                        )}
                                                    >
                                                        <IconSVG
                                                            name="bookmark"
                                                            filled
                                                            customDimensions
                                                            className={
                                                                styles.referenceCurrency
                                                            }
                                                        />
                                                    </WithTooltip>
                                                ) : null}
                                                &nbsp;
                                                {formatNumberWithAbbreviation(
                                                    w.availableBalance,
                                                )}
                                                &nbsp;
                                                {w.currency.split('/').pop()}
                                            </span>
                                        </div>
                                    </button>
                                );
                            })}
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default SidebarWalletDropdown;
