import {useMutation, useQuery, queryCache} from 'react-query';
import {useClient} from 'context/auth-context';
import {
    COMPANY_CACHE,
    BP_CONTRACTS_CACHE,
    PERMISSIONS_CACHE,
    BP_LIST_CACHE,
    SINGLE_BP_CACHE,
    CRP_BP_LIST_CACHE,
    CRP_BP_CACHE,
    CONTRACT_SIGNING_URL_CACHE,
    DASHBOARD_NOTIFICATION_CACHE,
    BUSINESS_ACTIVITIES,
    PAYMENT_TYPES,
    CARD_PURPOSE,
    BP_DOCUMENTS,
    REQUIRED_DOCUMENTS,
    CONTACTS_LIST_CACHE,
} from 'constants/cache';
import {IDocument} from 'types/documents';
import {DashboardLayoutState} from 'types/ui/dashboardLayoutState';
import {
    BusinessPartner,
    BusinessPartnerList,
    Permission,
    PermissionSettings,
    EnableEmailTypes,
    IUpdateBusinessPartnerOnboarding,
    ICompany,
    ICompanyRegistryByName,
    BusinessActivity,
    BusinessPartnerPaymentTypes,
    BusinessPartnerCardPurpose,
    BusinessPartnerRequiredDocument,
    IBusinessPartnerReviewProcess,
} from 'types/api';
import {transformStepTypeToNamed} from 'components/pages/OnboardingPage/onboardingSteps';
import {useNotification} from 'context/notification-context';
import {useBusinessPartner} from 'context/business-partner-context';
import {PATCH_HEADERS} from './constants';
import {User} from './users';

const BUSINESS_PARTNERS_ENDPOINT = '/web_api/business_partners';

const useAddBusinessPartner = () => {
    const client = useClient();

    return useMutation<BusinessPartner, unknown, any>(data => {
        const {purpose, gwgInfo, ...rest} = data;
        return client(BUSINESS_PARTNERS_ENDPOINT, {
            data: {
                ...rest,
                gwgInfo: {
                    ...gwgInfo,
                    purpose,
                },
            },
            method: 'POST',
        });
    });
};

const useUpdateBusinessPartnerOnboarding = () => {
    const client = useClient();

    return useMutation<
        BusinessPartner,
        unknown,
        IUpdateBusinessPartnerOnboarding
    >(
        data => {
            const {businessPartnerId, step, ...rest} = data;
            const updateStep = transformStepTypeToNamed(step);
            return client(`${businessPartnerId}/onboarding`, {
                data: {
                    ...rest,
                    onboardingStepName: updateStep,
                },
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
        {
            onSuccess: response => {
                queryCache.cancelQueries([SINGLE_BP_CACHE]);
                queryCache.setQueryData(
                    [SINGLE_BP_CACHE, {bpId: response.id, withGwgInfo: true}],
                    () => response,
                );
                queryCache.setQueryData(
                    [SINGLE_BP_CACHE, {bpId: response.id}],
                    () => response,
                );
            },
        },
    );
};

export interface IOnboardingCard {
    cardTermsAccepted: boolean;
    normalizedCompanyName: string;
    referenceCurrency: string;
    payInCardCurrency: boolean;
}

const useUpdateCardOnboarding = () => {
    const client = useClient();

    return useMutation<
        unknown,
        unknown,
        {
            bpIri: string;
            cardOnboardingData: IOnboardingCard;
        }
    >(
        data => {
            const {bpIri, cardOnboardingData} = data;
            return client(`${bpIri}/card_onboarding`, {
                data: cardOnboardingData,
                method: 'PATCH',
                ...PATCH_HEADERS,
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries(SINGLE_BP_CACHE);
            },
        },
    );
};

const useUpdatePostalAddress = () => {
    const client = useClient();

    return useMutation<
        BusinessPartner,
        unknown,
        IUpdateBusinessPartnerOnboarding
    >(data => {
        const {businessPartnerId, ...rest} = data;
        return client(`${businessPartnerId}/update_postal_address`, {
            data: rest,
            method: 'PATCH',
            ...PATCH_HEADERS,
        });
    });
};

const useGetCompaniesList = () => {
    const client = useClient();

    return useMutation<ICompany[], unknown, ICompanyRegistryByName>(request =>
        client('/web_api/company_registry/search_by_name', {
            data: request,
            method: 'POST',
        }),
    );
};

export interface IUseGetCompanyData {
    id: string;
    country: string;
}

export interface IUseGetCompanyDataResponse {
    city: string;
    houseNumber: string;
    name: string;
    purpose: string;
    street: string;
    zipCode: string;
    dateOfIncorporation: string | null;
    incorporationNumber: string;
    stateOrProvince: string;
}

const useGetCompanyData = (request: IUseGetCompanyData) => {
    const client = useClient();

    return useQuery<IUseGetCompanyDataResponse>(
        [COMPANY_CACHE, {id: request.id, country: request.country}],
        () =>
            client(`/web_api/company_registry/search_by_id`, {
                data: request,
                method: 'POST',
            }).then(data => data[0]),
        {enabled: request.country && request.id},
    );
};

interface IUseGetBusinessPartnerList {
    user: User;
    onlyDirectAccessWithoutOnlyCardUser?: boolean;
    onlyAvailablePeers?: boolean;
    onlyAdmin?: boolean;
}

const useGetBusinessPartnersList = ({
    user,
    onlyDirectAccessWithoutOnlyCardUser,
    onlyAvailablePeers,
    onlyAdmin,
}: IUseGetBusinessPartnerList) => {
    const client = useClient();
    return useQuery<BusinessPartnerList>(
        [
            BP_LIST_CACHE,
            {user, onlyDirectAccessWithoutOnlyCardUser, onlyAvailablePeers},
        ],
        () =>
            client('/web_api/available_business_partners', {
                params: {
                    onlyDirectAccessWithoutOnlyCardUser,
                    onlyAvailablePeers,
                    onlyAdmin,
                },
            }),
        {enabled: !!user},
    );
};

/**
 * DO NOT USE THIS, use the value from the context: useBusinessPartner()
 * @param bpId number
 * @returns BusinessPartner resource
 */
const useGetBusinessPartner = (bpId: number, withGwgInfo: boolean) => {
    const client = useClient();

    return useQuery<BusinessPartner>(
        [SINGLE_BP_CACHE, {bpId, withGwgInfo}],
        () =>
            client(`/web_api/business_partners/${bpId}`, {
                params: {
                    groups: withGwgInfo ? ['GwgInfoView'] : undefined,
                },
            }),
        {enabled: !!bpId},
    );
};

export type IOnboardingContract = {
    '@id': string;
    '@type': 'OnboardingContract';
    current: boolean;
    externalIntegrationType: 'skribble' | 'docuSign';
    id: number;
    signingUrl: string;
    status: 'signed';
};

const useGetCurrentContract = (activeBusinessPartner: BusinessPartner) => {
    const client = useClient();

    return useQuery<IOnboardingContract>(
        [BP_CONTRACTS_CACHE, {bpId: activeBusinessPartner?.['@id']}],
        () =>
            client(
                `/web_api/onboarding_contracts?current=1`,
                {},
            ).then((response: IOnboardingContract[]) =>
                response?.find(c => c.current),
            ),
        {
            enabled: !!activeBusinessPartner && !activeBusinessPartner.sandbox,
        },
    );
};

const useGetContractSigningUrl = (contractId?: string, enabled?: boolean) => {
    const client = useClient();

    return useQuery<{signingUrl: string}>(
        [CONTRACT_SIGNING_URL_CACHE, {id: contractId}],
        () => client(`/web_api/contract/${contractId}/signing-url`, {}),
        {
            enabled: !!contractId && enabled,
        },
    );
};

const useGenerateContract = () => {
    const client = useClient();

    return useMutation((businessPartnerId: string) => {
        return client(`${businessPartnerId}/generate-contract`, {
            data: {},
            method: 'PATCH',
            ...PATCH_HEADERS,
        });
    });
};

export interface ISendBusinessPartnerInviteRequest {
    firstName: string;
    lastName: string;
    email: string;
    gender: string;
    language: string;
    businessPartner: string;
}

const useSendBusinessPartnerInvite = () => {
    const client = useClient();
    return useMutation<unknown, unknown, ISendBusinessPartnerInviteRequest>(
        data => {
            return client(
                `/web_api/business_partner_invites`,
                {
                    data,
                    method: 'POST',
                },
                false,
                true,
            );
        },
    );
};

const useResendBusinessPartnerInvite = () => {
    const {addNotification} = useNotification();
    const client = useClient();

    return useMutation<unknown, unknown, {iri: string}>(
        ({iri}) => {
            return client(`${iri}/resend`, {
                ...PATCH_HEADERS,
                data: {},
                method: 'PATCH',
            });
        },
        {
            onSuccess: () => {
                addNotification(
                    {
                        area: 'general_settings',
                        message: 'User re-invite sent.',
                        status: 'success',
                    },
                    'user-re-invited',
                );
                queryCache.invalidateQueries([PERMISSIONS_CACHE]);
            },
        },
    );
};

const useUpdateBusinessPartnerRes = () => {
    const client = useClient();
    return useMutation((hash: string) => {
        return client(`/web_api/business_partner_invites/${hash}/consume`, {
            data: {},
            method: 'PATCH',
            ...PATCH_HEADERS,
        });
    });
};

const useSuspendAllCards = () => {
    const client = useClient();
    return useMutation(
        (data: {businessPartnerIri: string; suspendAll: boolean}) =>
            client(`${data.businessPartnerIri}/suspend_all_cards`, {
                data: {
                    allCardsSuspended: data.suspendAll,
                },
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        {
            onSuccess: _ => {
                queryCache.invalidateQueries(SINGLE_BP_CACHE);
            },
        },
    );
};

export interface IConnectedPermissionsResponse {
    '@id': string;
    '@type': string;
    admin: boolean;
    allowedToSign: boolean;
    amountLimit: number;
    businessPartner: {
        '@id': string;
        '@type': string;
        createdAt: string;
        updatedAt: string;
    };
    businessPartnerInvites?: Partial<User>[]; // TODO-CARD
    forwardDaysLimit: number;
    fourEyeSignRequired: boolean;
    fxTrade: boolean;
    limitTrade: boolean;
    swapTrade: boolean;
    createContact: boolean;
    // crp is not string here
    user?: User & {crp: ICrp};
    fullName: string;
    cardAdmin: boolean;
    onlyCardUser: boolean;
    cardHolder: {
        '@id': string;
        '@type': 'CardHolder';
        embossedName: string;
    };
    cardHolderInActivation: boolean;
}

const useGetConnectedPermissions = (activeBpIri: string) => {
    const client = useClient();

    return useQuery<IConnectedPermissionsResponse[]>(
        [PERMISSIONS_CACHE, {activeBpIri}],
        () => client('/web_api/permissions', {params: {pagination: false}}),
    );
};
export interface IUseUpdateUserSettingsRequest {
    businessPartner: BusinessPartner;
    enableEmailTypes?: EnableEmailTypes;
    dashboardLayout?: DashboardLayoutState;
    settings?: PermissionSettings;
}

const useUpdateUserSettings = () => {
    const client = useClient();

    return useMutation<Permission, unknown, IUseUpdateUserSettingsRequest>(
        data => {
            const {businessPartner, ...rest} = data;
            return client(`${businessPartner.permission['@id']}/user_update`, {
                data: rest,
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([SINGLE_BP_CACHE]);
            },
            onSettled: () => {
                queryCache.invalidateQueries([DASHBOARD_NOTIFICATION_CACHE]);
            },
        },
    );
};

const useDeletePermission = () => {
    const client = useClient();

    return useMutation(
        ({bpPermissionId}: {bpPermissionId: string}) =>
            client(bpPermissionId, {
                data: {},
                method: 'DELETE',
            }),
        {
            onSuccess: () => queryCache.invalidateQueries(PERMISSIONS_CACHE),
        },
    );
};

export interface ICrp {
    '@id': string;
    '@type': 'Crp';
    lastName: string;
    firstName: string;
    email: string;
    dateOfBirth: string;
    gender: string;
    country: string;
    street: string;
    zip: string;
    city: string;
    phoneNumber: string;
    nationality: string;
    user: string | null;
    identifiedFirstName: string | null;
    identifiedLastName: string | null;
    identified: boolean;
    idType: string | null;
    idNumber: string | null;
    idExpiryDate: string | null;
    pep: null | boolean;
    pepDescription?: string;
    needIdentification: boolean;
    canChangeName: boolean;
    highestEducation?: string;
}

export interface ICrpBpListItem {
    '@id': string;
    '@type': 'CrpBusinessPartner';
    businessPartner: string;
    id: number;
    crp: ICrp;
    directOwner: boolean;
    ownerPercentage: string;
    role: string;
    isOwner: boolean;
    hasSigningRights: boolean;
    contractSigner: boolean;
    dateOfBirth?: string;
    nationality?: string;
    /**
     * null means no signature needed from this crp
     */
    contractSigned: boolean | null;
    collectiveSigningRight: boolean;
    admin: boolean;
    user?: string;
    confidential: boolean;
    documents: IDocument[];
    gender?: string;
    professionalPosition?: string;
}

const useGetBusinessPartnerCrpList = (
    activeBusinessPartner: BusinessPartner,
) => {
    const client = useClient();

    return useQuery<ICrpBpListItem[]>(
        [CRP_BP_LIST_CACHE, {id: activeBusinessPartner?.['@id']}],
        () =>
            client('/web_api/crp_business_partners', {
                headers: {
                    'Business-Partner': activeBusinessPartner?.id,
                },
            }),
        {enabled: !!activeBusinessPartner},
    );
};

const useGetCrpBusinessPartner = (crpBpId?: string) => {
    const client = useClient();

    return useQuery<ICrpBpListItem>(
        [CRP_BP_CACHE, {id: crpBpId}],
        () => client(crpBpId, {}),
        {
            enabled: !!crpBpId,
        },
    );
};

const useDownloadBusinessPartnerDocuments = (bpId: string) => {
    const client = useClient();

    return useQuery<IDocument[]>(
        [BP_DOCUMENTS, {bpId}],
        () => client('/web_api/documents', {}),
        {
            enabled: !!bpId,
        },
    );
};

type UpdateReferenceCurrency = {
    businessPartner: BusinessPartner;
    referenceCurrency: string;
};
type UpdateCardCurrency = {
    businessPartner: BusinessPartner;
    payInCardCurrency: boolean;
};
type Update4EyeSignRequiredForOwnAccounts = {
    businessPartner: BusinessPartner;
    fourEyeOwnPaymentSignRequired: boolean;
};
export type UpdateBusinessPartner =
    | UpdateReferenceCurrency
    | UpdateCardCurrency
    | Update4EyeSignRequiredForOwnAccounts;

const useUpdateBusinessPartner = () => {
    const client = useClient();

    return useMutation<BusinessPartner, unknown, UpdateBusinessPartner>(
        ({businessPartner, ...data}) =>
            client(businessPartner['@id'], {
                data,
                method: 'PATCH',
                ...PATCH_HEADERS,
            }),
        {
            onSuccess: () => {
                queryCache.invalidateQueries([SINGLE_BP_CACHE]);
            },
        },
    );
};

export interface IUploadBusinessPartnerDocument {
    files: File[];
    type: string;
    activeBpId: string;
    description?: string;
    category?: string;
    uniqueId?: string;
}

const useUploadBusinessPartnerDocument = () => {
    const client = useClient();

    return useMutation<unknown, unknown, IUploadBusinessPartnerDocument>(
        ({files, type, description, activeBpId, category, uniqueId}) => {
            const formData = new FormData();
            files.forEach(file => {
                formData.append('file', file);
            });
            formData.append('type', type);
            if (description) {
                formData.append('description', description);
            }
            if (category) {
                formData.append('category', category);
            }
            if (uniqueId) {
                formData.append('uniqueId', uniqueId);
            }

            return client(`${activeBpId}/documents/upload`, {
                data: formData,
                method: 'POST',
                headers: {'Content-Type': 'multipart/form-data'},
                keepOriginal: true,
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([BP_DOCUMENTS]);
            },
        },
    );
};

const useGetBusinessActivities = () => {
    const client = useClient();

    return useQuery<BusinessActivity[]>([BUSINESS_ACTIVITIES], () =>
        client(`/web_api/business_activities`, {}),
    );
};

const useGetBusinessPartnerPaymentTypes = () => {
    const client = useClient();

    return useQuery<BusinessPartnerPaymentTypes[]>([PAYMENT_TYPES], () =>
        client(`/web_api/payment_types`, {}),
    );
};

const useGetBusinessPartnerCardPurposes = () => {
    const client = useClient();

    return useQuery<BusinessPartnerCardPurpose[]>([CARD_PURPOSE], () =>
        client(`/web_api/card_usage_purposes`, {}),
    );
};

const useGetBusinessPartnerRequiredDocuments = (
    businessPartner: BusinessPartner,
) => {
    const client = useClient();

    return useQuery<BusinessPartnerRequiredDocument[]>(
        [REQUIRED_DOCUMENTS, {bpId: businessPartner['@id']}],
        () =>
            client(`${businessPartner['@id']}/onboarding-needed-documents`, {}),
        {
            enabled: !!businessPartner,
        },
    );
};

const useTriggerReviewProcess = () => {
    const {activeBusinessPartner} = useBusinessPartner();
    const client = useClient();

    return useMutation<IBusinessPartnerReviewProcess, unknown>(
        () =>
            client('/web_api/review_processes', {
                method: 'POST',
                data: {
                    businessPartner: activeBusinessPartner['@id'],
                },
            }),
        {
            onSuccess: response => {
                queryCache.cancelQueries([SINGLE_BP_CACHE]);
                queryCache.setQueryData(
                    [
                        SINGLE_BP_CACHE,
                        {bpId: activeBusinessPartner.id, withGwgInfo: false},
                    ],
                    (prev: BusinessPartner) => ({
                        ...prev,
                        reviewProcess: response,
                    }),
                );
                queryCache.setQueryData(
                    [
                        SINGLE_BP_CACHE,
                        {bpId: activeBusinessPartner.id, withGwgInfo: true},
                    ],
                    (prev: BusinessPartner) => ({
                        ...(prev ?? activeBusinessPartner),
                        reviewProcess: response,
                    }),
                );
                queryCache.invalidateQueries([SINGLE_BP_CACHE]);
            },
        },
    );
};

interface IUseAddPeerBusinessPartners {
    activeBusinessPartner: BusinessPartner;
    businessPartnerIds: string[];
}

const useAddPeerBusinessPartners = () => {
    const client = useClient();

    return useMutation<unknown, unknown, IUseAddPeerBusinessPartners>(
        ({activeBusinessPartner, businessPartnerIds}) =>
            client(`${activeBusinessPartner['@id']}/add_peers`, {
                method: 'POST',
                data: {
                    ids: businessPartnerIds,
                },
            }),
        {
            onSuccess: () =>
                queryCache.invalidateQueries([CONTACTS_LIST_CACHE]),
        },
    );
};

export {
    useGetCurrentContract,
    useAddBusinessPartner,
    useUpdateBusinessPartnerOnboarding,
    useGetCompaniesList,
    useGetCompanyData,
    useGenerateContract,
    useSendBusinessPartnerInvite,
    useUpdateBusinessPartnerRes,
    useGetConnectedPermissions,
    useUpdateUserSettings,
    useDeletePermission,
    useGetBusinessPartnersList,
    useGetBusinessPartner,
    useGetBusinessPartnerCrpList,
    useGetCrpBusinessPartner,
    useUploadBusinessPartnerDocument,
    useUpdatePostalAddress,
    useGetContractSigningUrl,
    useGetBusinessActivities,
    useGetBusinessPartnerPaymentTypes,
    useGetBusinessPartnerCardPurposes,
    useGetBusinessPartnerRequiredDocuments,
    useSuspendAllCards,
    useDownloadBusinessPartnerDocuments,
    useUpdateCardOnboarding,
    useUpdateBusinessPartner,
    useResendBusinessPartnerInvite,
    useTriggerReviewProcess,
    useAddPeerBusinessPartners,
};
