import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import Async from 'react-select/async';
// eslint-disable-next-line import/no-extraneous-dependencies
import {throttle} from 'lodash';
import {ariaLiveMessages} from 'constants/reactSelectAriaMessages';
import {translate} from 'helpers/localize';
import classNames from 'classnames';
import InfoPopover from 'components/ui/InfoPopover/InfoPopover';
import {getDeepValue} from 'helpers/common';
import {GroupTypeBase, OptionTypeBase, Props, components} from 'react-select';
import {IDefaultControlProps} from '../Form/Form';

import styles from './AutoComplete.module.scss';

// TODO fix <<any>> types - make the component itself generic maybe (?)
interface IAsyncAutoCompleteProps extends IDefaultControlProps {
    isLoading: boolean;
    onSelect?: (value: any) => void;
    onSearch?: (
        inputValue: string,
        callback: (options: ReadonlyArray<any | any>) => void,
    ) => Promise<ReadonlyArray<any | any>> | void;
    valueKey?: string;
    labelKey?: string;
}

const AsyncAutoComplete = <
    OptionType extends OptionTypeBase,
    IsMulti extends boolean = false,
    GroupType extends GroupTypeBase<OptionType> = GroupTypeBase<OptionType>
>(
    props: Props<OptionType, IsMulti, GroupType> & IAsyncAutoCompleteProps,
) => {
    const {control, errors} = useFormContext();
    const {
        label,
        name,
        onSelect,
        onSearch,
        info,
        rounded = true,
        variant,
        disabled,
        size,
        placeholder,
        valueKey = '@id',
        labelKey = 'label',
    } = props;

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const throttledSearch = React.useCallback(
        throttle(onSearch, 300, {
            leading: true,
            trailing: true,
        }),
        [],
    );

    return (
        <div className={styles.container}>
            <label htmlFor={name} className={styles.label}>
                {label}
                {info ? <InfoPopover content={info} /> : null}
            </label>
            <div data-notranslate>
                <Controller
                    control={control}
                    name={name}
                    render={(field, state) => (
                        <Async
                            cacheOptions
                            className={classNames(styles.input, {
                                [styles.invalid]: getDeepValue(errors, name),
                                [styles.dark]: variant === 'dark',
                                [styles.rounded]: rounded,
                                [styles.disabled]: disabled,
                                [styles.large]: size === 'large',
                            })}
                            components={{
                                NoOptionsMessage,
                                ...props.components,
                            }}
                            isDisabled={disabled}
                            ariaLiveMessages={ariaLiveMessages}
                            classNamePrefix="amnis-new"
                            defaultOptions
                            loadOptions={throttledSearch}
                            getOptionLabel={option => option[labelKey]}
                            getOptionValue={option => option[valueKey]}
                            onChange={inputValue => {
                                field.onChange(inputValue[valueKey]);
                                if (onSelect) {
                                    onSelect(inputValue);
                                }
                            }}
                            placeholder={translate(
                                placeholder || 'Please select',
                            )}
                        />
                    )}
                />
                {getDeepValue(errors, name)?.message ? (
                    <p className={styles.errorMessage}>
                        {errors && getDeepValue(errors, name).message}
                    </p>
                ) : null}
            </div>
        </div>
    );
};

const NoOptionsMessage = props => {
    return (
        <components.NoOptionsMessage {...props}>
            <span>{translate('No options')}</span>
        </components.NoOptionsMessage>
    );
};

export default AsyncAutoComplete;
