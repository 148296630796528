import {useQuery, queryCache, QueryResult} from 'react-query';

import {useClient} from 'context/auth-context';

export interface IParameter {
    id: string;
    name: string;
    value: {[key: string]: string}[];
}

const parametersQueryConfig = {
    staleTime: 1000 * 60 * 60,
    cacheTime: 1000 * 60 * 60,
};

interface IUseParameters extends QueryResult<IParameter[], unknown> {
    parameters: IParameter[];
}

function useParameters(): IUseParameters {
    const client = useClient();

    const result = useQuery(
        'parameters',
        () => client('/web_api/parameters', {}),
        {
            ...parametersQueryConfig,
            onSuccess(parameters: IParameter[]) {
                parameters.forEach(parameter => {
                    queryCache.setQueryData(
                        ['parameters', {parameterId: parameter.name}],
                        parameter,
                        parametersQueryConfig,
                    );
                });
            },
        },
    );

    return {...result, parameters: result.data ?? []};
}

export {useParameters};
