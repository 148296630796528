import {
    useCancelDealRequest,
    useConfirmDealRequest,
    useDealRequest,
} from 'api/deal-request';
import {DEAL_TIMEOUT} from 'constants/order';
import {useBusinessPartner} from 'context/business-partner-context';
import useModal from 'components/ui/ModalContainer/useModal';
import {useCountdown} from 'hooks/common';
import {useState} from 'react';
import {ActiveDeal} from 'types/order';

interface IUseSimpleOrderConfirmationModal {
    activeDealRequest: ActiveDeal;
}

const useSimpleOrderConfirmationModal = ({
    activeDealRequest,
}: IUseSimpleOrderConfirmationModal) => {
    const [error, setError] = useState('');
    const {openModalWithContent, closeModal} = useModal();
    const {activeBusinessPartner} = useBusinessPartner();
    const {restartCounter, timeLeft} = useCountdown(DEAL_TIMEOUT);
    const [
        refreshDealRequest,
        {isLoading: isRefreshLoading, data: refreshedDealRequest},
    ] = useDealRequest();

    const [
        confirmDealRequest,
        {isLoading: isLoadingConfirm, data: confirmedDealRequest},
    ] = useConfirmDealRequest();
    const [cancelDeal, {isLoading: isLoadingCancel}] = useCancelDealRequest();

    const handleRefresh = (dealRequestParams: any) => {
        handleCancelDeal(() =>
            refreshDealRequest(dealRequestParams, {
                onSuccess: () => restartCounter(),
            }),
        );
    };

    const handleConfirm = (
        onSuccess: (confirmedDealRequest: ActiveDeal) => void,
    ) => {
        confirmDealRequest(refreshedDealRequest || activeDealRequest, {
            onSuccess,
        });
    };

    const handleCancelDeal = (
        finishedCallback: (confirmedDealRequest: ActiveDeal) => void,
    ) => {
        setError('');
        if (!confirmedDealRequest) {
            cancelDeal(
                refreshedDealRequest?.['@id'] || activeDealRequest?.['@id'],
                {
                    onSuccess: () =>
                        finishedCallback(
                            refreshedDealRequest || activeDealRequest,
                        ),
                },
            );
        }
        if (confirmedDealRequest) {
            finishedCallback(confirmedDealRequest);
        }
    };

    return {
        activeBusinessPartner,
        restartCounter,
        timeLeft,
        handleRefresh,
        isRefreshLoading,
        isLoadingConfirm,
        isLoadingCancel,
        refreshDealRequest,
        refreshedDealRequest,
        handleConfirm,
        openModalWithContent,
        closeModal,
        handleCancelDeal,
        error,
    };
};

export default useSimpleOrderConfirmationModal;
