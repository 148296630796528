import React from 'react';

const RecurringTransactionIcon = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 17"
        {...props}
    >
        <path
            fill="currentColor"
            d="M9.643.015a8.382 8.382 0 0 1 6.174 3.34c1.02 1.358 1.632 3.124 1.642 4.74.003.446 0 .478-.078.615-.173.307-.502.447-.87.368a.699.699 0 0 1-.515-.371c-.078-.143-.094-.218-.117-.577-.046-.697-.108-1.078-.258-1.616-.508-1.805-1.818-3.352-3.535-4.173a6.704 6.704 0 0 0-2.974-.665c-1.137 0-2.075.212-2.975.671-.423.219-.98.613-1.234.873l-.144.15h.73c.785.004.883.02 1.088.176.264.199.346.645.173.942a.77.77 0 0 1-.41.345c-.15.05-3.672.052-3.806 0a.768.768 0 0 1-.417-.365l-.081-.166V2.468c0-2.004-.003-1.968.189-2.17a.73.73 0 0 1 .563-.235c.183 0 .238.014.375.098a.88.88 0 0 1 .258.258l.097.16.01.762c.006.417.016.759.026.759.01 0 .13-.098.274-.215C4.769 1.106 6.19.422 7.363.18a9.088 9.088 0 0 1 2.28-.166ZM1.993 7.329a.778.778 0 0 1 .369.423c.02.053.052.31.068.57.062 1.014.244 1.724.665 2.59.941 1.946 2.752 3.3 4.92 3.682.924.163 2.08.111 3.026-.137.876-.228 1.766-.713 2.342-1.273l.157-.154-1.593-.032-.14-.098c-.297-.208-.414-.521-.32-.854a.791.791 0 0 1 .486-.491c.11-.033.59-.04 1.97-.033l1.825.01.124.075a.846.846 0 0 1 .212.228l.088.153.01 1.76c.006 1.221 0 1.81-.026 1.928a.742.742 0 0 1-1.072.512.717.717 0 0 1-.391-.505c-.02-.088-.036-.457-.036-.818v-.652l-.173.144a8.64 8.64 0 0 1-3.085 1.655 8.322 8.322 0 0 1-5.972-.541C2.808 14.148 1.104 11.649.83 8.698.765 8.004.801 7.72.974 7.495c.192-.254.684-.332 1.02-.166Z"
        />
    </svg>
);

export default RecurringTransactionIcon;
