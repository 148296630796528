import React from 'react';

import styles from './CrpSignerInfo.module.scss';

const CrpSignerInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Authorized person</h3>
            <p>
                Who is allowed to sign on behalf of the company according to
                commercial register. List all authorized individuals here.
            </p>
            <h5>What is an authorized person?</h5>
            <p data-isolate>
                An <span className={styles.strong}>authorized person</span> of a
                company is an individual who has been granted legal authority or
                permission to act on behalf of the company. Authorized persons
                in this context are typically listed in the commercial registry.
            </p>
        </div>
    );
};

export default CrpSignerInfo;
