import React from 'react';
import classNames from 'classnames';
import {useFormContext} from 'react-hook-form';
import {getDeepValue} from 'helpers/common';
import {IDefaultControlProps} from '../Form/Form';

import styles from './Checkbox.module.scss';

export interface ICheckboxProps extends IDefaultControlProps {
    className?: string;
    afterLabel?: React.ReactNode;
    color?: string;
    checked?: boolean;
    onClick?: React.MouseEventHandler<HTMLInputElement>;
}

const Checkbox = ({
    onChange,
    className,
    registerOptions,
    name,
    checked,
    color,
    label,
    afterLabel,
    onClick,
}: ICheckboxProps) => {
    const {register, errors} = useFormContext();
    return (
        <div className={classNames(styles.checkboxContainer, className)}>
            <div className={styles.labelControlContainer}>
                {label ? (
                    <label htmlFor={name} className={styles.formLabel}>
                        {label}
                    </label>
                ) : null}
                <div className={styles.inputContainer}>
                    <input
                        ref={register(registerOptions)}
                        name={name}
                        type="checkbox"
                        onChange={onChange}
                        className={classNames(styles.input, {
                            [styles.invalid]: getDeepValue(errors, name),
                        })}
                        onClick={onClick}
                        checked={checked}
                        id={name}
                    />
                </div>
            </div>
            {afterLabel ? (
                <label
                    htmlFor={name}
                    className={classNames(styles.label, styles.afterLabel, {
                        [styles.invalid]: getDeepValue(errors, name),
                    })}
                >
                    {afterLabel}
                </label>
            ) : null}
        </div>
    );
};

export default Checkbox;
