import {useQuery} from 'react-query';

import {translate} from 'helpers/localize';
import {useClient} from 'context/auth-context';
import {BUSINESS_INDUSTRIES} from 'constants/cache';

export interface IBusinessIndustry {
    '@id': string;
    id: string;
    value: string;
    label: string;
}

function useBusinessIndustries() {
    const client = useClient();

    return useQuery<IBusinessIndustry[]>(BUSINESS_INDUSTRIES, () => {
        return client('/web_api/business_industries', {
            params: {
                'order[position]': 'ASC',
                pagination: false,
            },
        }).then((businessIndustries: IBusinessIndustry[]) => {
            return businessIndustries.map(businessIndustry => {
                const label = translate(businessIndustry.value);
                return {
                    ...businessIndustry,
                    id: businessIndustry['@id'].split('/').pop(),
                    label,
                };
            });
        });
    });
}

export {useBusinessIndustries};
