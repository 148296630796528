import React from 'react';

const Block = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12.002 21.5a9.255 9.255 0 0 1-3.705-.748 9.596 9.596 0 0 1-3.018-2.03 9.591 9.591 0 0 1-2.03-3.016 9.245 9.245 0 0 1-.749-3.704c0-1.314.25-2.549.748-3.705a9.597 9.597 0 0 1 2.03-3.017 9.592 9.592 0 0 1 3.016-2.031 9.245 9.245 0 0 1 3.704-.749c1.314 0 2.55.25 3.705.748a9.597 9.597 0 0 1 3.018 2.03 9.592 9.592 0 0 1 2.03 3.016A9.245 9.245 0 0 1 21.5 12c0 1.314-.25 2.549-.748 3.705a9.597 9.597 0 0 1-2.03 3.017 9.592 9.592 0 0 1-3.016 2.031 9.247 9.247 0 0 1-3.704.748ZM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.234 20 12a7.78 7.78 0 0 0-.48-2.71 8.124 8.124 0 0 0-1.374-2.382L6.908 18.147a7.933 7.933 0 0 0 2.382 1.377A7.839 7.839 0 0 0 12 20Zm-6.146-2.907L17.092 5.854a7.62 7.62 0 0 0-2.375-1.382A7.947 7.947 0 0 0 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 .939.159 1.842.476 2.71a7.93 7.93 0 0 0 1.378 2.383Z"
        />
    </svg>
);

export default Block;
