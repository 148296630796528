import React, {useEffect, useRef} from 'react';
import {Card, Container} from 'react-bootstrap';
import SidebarLayout from 'components/layout/SidebarLayout/SidebarLayout';
import PageTitle from 'components/ui/PageTitle/PageTitle';
import {useAuth} from 'context/auth-context';
import {useHistory} from 'react-router';
import {UnregisterCallback} from 'history';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import TextArea from 'components/ui/Form/TexArea/TextArea';
import Button from 'components/ui/Button';
import {PATH} from 'constants/common';
import * as Sentry from '@sentry/react';

type FormValues = {
    userInput?: string;
};

const ErrorPage = ({error, resetErrorBoundary}) => {
    const {user} = useAuth();
    const history = useHistory();
    const historyListener = useRef<UnregisterCallback>();

    useEffect(() => {
        if (!historyListener.current) {
            historyListener.current = history.listen((location, action) => {
                resetErrorBoundary();
            });
        }

        return () =>
            historyListener.current ? historyListener.current() : undefined;
    }, [history, resetErrorBoundary]);

    const onSubmit: FormOnSubmit<FormValues> = (values, setError) => {
        Sentry.captureException(error, scope => {
            scope.setContext('User input', {
                userInput: values.userInput,
            });
            return scope;
        });
        resetErrorBoundary();
    };

    const renderContent = () => (
        <Container className="mt-4">
            <Card body>
                <PageTitle>Something went wrong:</PageTitle>
                <Form<FormValues> onSubmit={onSubmit}>
                    <TextArea
                        label="Please provide any information regarding the error that occured"
                        name="userInput"
                    />
                    <Button type="submit" className="mr-2">
                        Send report & Try again
                    </Button>
                    <Button
                        variant="outline"
                        onClick={() => history.push(PATH.DASHBOARD)}
                    >
                        Go to Dashboard
                    </Button>
                </Form>
            </Card>
        </Container>
    );

    return user ? (
        <SidebarLayout>{renderContent()}</SidebarLayout>
    ) : (
        renderContent()
    );
};

export {ErrorPage};
