import React, {useMemo, useState} from 'react';
import FA from 'react-fontawesome';
import {Button, Col, Modal, Row, Table} from 'react-bootstrap';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import FilteredDatePicker from 'components/ui/FilteredDatePicker/FilteredDatePicker';
import SubmitButton from 'components/ui/Button/SubmitButton';
import Alert from 'components/ui/Alert/Alert';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {IDealRequest} from 'types/order';
import {FIELD_SELL_AMOUNT, FIELD_BUY_AMOUNT} from 'constants/currencies';
import {SWAP_DEAL_TIMEOUT} from 'constants/order';
import {useCountdown} from 'hooks/common';
import {formatNumber} from 'helpers/common';
import {IFxTransactionDetail} from 'types/transactions';

import {useConfirmDealRequest, useSplitDealRequest} from 'api/deal-request';
import useModal from 'components/ui/ModalContainer/useModal';
import ModalOverlay from 'components/ui/Overlays/ModalOverlay/ModalOverlay';
import {TIMEOUT_DEAL_ERROR} from 'constants/common';
import {formatDateInZurichTZ} from 'helpers/dates';
import styles from './DrawdownModal.module.scss';
import OrderStatusModal from '../OrderStatusModal/OrderStatusModal';

type FieldsType = {
    [FIELD_SELL_AMOUNT]: string;
    [FIELD_BUY_AMOUNT]: string;
};

interface IProps {
    transaction: IFxTransactionDetail;
    onClose: () => void;
}

const NUMBER_INPUT_ERROR_MESSAGE =
    'Invalid request - The requested amount cannot be zero.';
const DATE_INPUT_ERROR_MESSAGE = 'Date can not be empty.';

const DrawdownModal: React.FC<IProps> = ({transaction, onClose}) => {
    const [activeDeal, setActiveDeal] = useState<IDealRequest>();
    const {
        valueDate: currentDate,
        id,
        amountTo,
        amountFrom,
        currencyFrom,
        currencyTo,
        dealType,
        forwardPips,
        spotRate,
        platformRate,
    } = transaction;

    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [valueDate, setValueDate] = useState(
        formatDateInZurichTZ(currentDate),
    );
    const [fields, setFields] = useState<FieldsType>({
        [FIELD_BUY_AMOUNT]: '',
        [FIELD_SELL_AMOUNT]: '',
    });
    const {openModalWithContent, closeModal} = useModal();
    const {startCounter, restartCounter, timeLeft} = useCountdown(
        SWAP_DEAL_TIMEOUT,
        false,
    );

    const [
        splitDealRequest,
        {isLoading: isSplitLoading},
    ] = useSplitDealRequest();
    const [confirmDealRequest] = useConfirmDealRequest();

    const isConfirmation = Boolean(activeDeal);

    const pickerProps = {
        currencyFrom,
        currencyTo,
        value: valueDate,
        onChange: setValueDate,
    };

    const onAmountChange = (value, name) => {
        const newValue: FieldsType = {
            [FIELD_SELL_AMOUNT]: name === FIELD_SELL_AMOUNT ? value : '',
            [FIELD_BUY_AMOUNT]: name === FIELD_BUY_AMOUNT ? value : '',
        };

        setFields(newValue);
    };

    const handleError = (e: any) => {
        const message = e['hydra:description'] || 'Something wrong';
        setErrorMessage(message);
    };

    const setErrorMessage = (message: string) => {
        setError(message);
        setTimeout(() => setError(''), TIMEOUT_DEAL_ERROR);
    };

    const handleSplitDeal = (onSuccess?: () => void) => {
        const transactionWithNewFields = {...transaction, ...fields, valueDate};

        splitDealRequest(transactionWithNewFields, {
            onSuccess: (deal: IDealRequest) => {
                setActiveDeal(deal);
                if (onSuccess) {
                    onSuccess();
                }
            },
            onError: handleError,
        });
    };

    const handleRefresh = () => {
        setLoading(true);

        handleSplitDeal(() => {
            setLoading(false);
            restartCounter();
        });

        splitDealRequest(
            {...transaction, ...fields},
            {
                onSuccess: (deal: IDealRequest) => {
                    setLoading(false);
                    restartCounter();
                    setActiveDeal(deal);
                },
                onError: handleError,
            },
        );
    };

    const handleConfirmChange = () => {
        if (activeDeal) {
            confirmDealRequest(activeDeal as any, {
                onSuccess: () => {
                    closeModal('drawDown');
                    openModalWithContent(
                        'orderStatus',
                        <ModalOverlay>
                            <OrderStatusModal
                                text="You have successfully amended the transaction. Please be aware that any corresponding payments also have to be adjusted."
                                onConfirm={() => closeModal('orderStatus')}
                            />
                        </ModalOverlay>,
                    );
                },
                onError: handleError,
            });
        }
    };

    const handleConfirm = () => {
        if (valueDate === '') {
            setErrorMessage(DATE_INPUT_ERROR_MESSAGE);
            return;
        }
        if (
            fields[FIELD_BUY_AMOUNT] === '' &&
            fields[FIELD_SELL_AMOUNT] === ''
        ) {
            setErrorMessage(NUMBER_INPUT_ERROR_MESSAGE);
            return;
        }
        setErrorMessage('');
        if (isConfirmation) {
            handleConfirmChange();
        } else {
            handleSplitDeal(startCounter);
        }
    };

    const realRate = useMemo(
        () =>
            (+amountTo < +amountFrom && +platformRate > 1) ||
            (+amountTo > +amountFrom && +platformRate < 1)
                ? +platformRate
                : 1 / +platformRate,
        [amountFrom, amountTo, platformRate],
    );

    const sellAmount = useMemo(() => {
        if (fields[FIELD_SELL_AMOUNT]) {
            return +amountFrom - +fields[FIELD_SELL_AMOUNT];
        }

        return (+amountTo - +fields[FIELD_BUY_AMOUNT]) * realRate;
    }, [amountFrom, amountTo, fields, realRate]);

    const buyAmount = useMemo(() => {
        if (fields[FIELD_BUY_AMOUNT]) {
            return +amountTo - +fields[FIELD_BUY_AMOUNT];
        }
        return (+amountFrom - +fields[FIELD_SELL_AMOUNT]) / realRate;
    }, [amountFrom, amountTo, fields, realRate]);

    const numberInputHasWarningBorder = () => {
        return (
            fields[FIELD_BUY_AMOUNT] === '' &&
            fields[FIELD_SELL_AMOUNT] === '' &&
            error !== ''
        );
    };

    const dateInputHasWarningBorder = () => {
        return valueDate === '' && error !== '';
    };

    const renderTable = () => {
        return (
            <Col md={12}>
                <Table className={styles.table} responsive borderless>
                    <thead>
                        <tr>
                            <th />
                            <th>Fixed Deal</th>
                            <th>New Trade no.</th>
                            <th>Residual Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Value date</td>
                            <td data-notranslate>
                                {formatDateInZurichTZ(currentDate)}
                            </td>
                            <td>
                                {isConfirmation ? (
                                    <span data-notranslate>{valueDate}</span>
                                ) : (
                                    <div className={styles.inputContainer}>
                                        <FA
                                            name="calendar"
                                            className={styles.icon}
                                        />
                                        <div
                                            className={styles.datePickerWrapper}
                                        >
                                            <FilteredDatePicker
                                                {...pickerProps}
                                                className={
                                                    dateInputHasWarningBorder()
                                                        ? styles.warning
                                                        : null
                                                }
                                            />
                                        </div>
                                    </div>
                                )}
                            </td>
                            <td data-notranslate>
                                {isConfirmation
                                    ? formatDateInZurichTZ(currentDate)
                                    : ''}
                            </td>
                        </tr>
                        <tr>
                            <td>Spot</td>
                            <td data-notranslate>
                                {formatNumber(spotRate, 4)}
                            </td>
                            <td data-notranslate>
                                {isConfirmation
                                    ? formatNumber(activeDeal.spotRate, 4)
                                    : ''}
                            </td>
                            <td data-notranslate>
                                {isConfirmation
                                    ? formatNumber(spotRate, 4)
                                    : ''}
                            </td>
                        </tr>
                        {dealType === 'Forward' ? (
                            <tr>
                                <td>Forward points</td>
                                <td data-notranslate>
                                    {formatNumber(forwardPips, 4)}
                                </td>
                                <td data-notranslate>
                                    {isConfirmation
                                        ? formatNumber(
                                              activeDeal.forwardPips,
                                              4,
                                          )
                                        : ''}
                                </td>
                                <td data-notranslate>
                                    {isConfirmation
                                        ? formatNumber(forwardPips, 4)
                                        : ''}
                                </td>
                            </tr>
                        ) : null}
                        <tr>
                            <td className={styles.bold}>Your exchange rate</td>
                            <td className={styles.bold} data-notranslate>
                                {formatNumber(platformRate, 4)}
                            </td>
                            <td className={styles.bold} data-notranslate>
                                {isConfirmation
                                    ? formatNumber(activeDeal.platformRate, 4)
                                    : ''}
                            </td>
                            <td className={styles.bold} data-notranslate>
                                {isConfirmation
                                    ? formatNumber(platformRate, 4)
                                    : ''}
                            </td>
                        </tr>

                        <tr>
                            <td>You buy</td>
                            <td>
                                <CurrencyFlag
                                    code={currencyTo}
                                    text={formatNumber(amountTo)}
                                />
                            </td>
                            <td>
                                <div className={styles.inputContainer}>
                                    <CurrencyFlag code={currencyTo} />
                                    {isConfirmation ? (
                                        <span data-notranslate>
                                            {formatNumber(activeDeal.amountTo)}
                                        </span>
                                    ) : (
                                        <NumberFormat
                                            data-notranslate
                                            thousandSeparator="’"
                                            className={classNames(
                                                'form-control ml-2',
                                                numberInputHasWarningBorder()
                                                    ? styles.warning
                                                    : null,
                                            )}
                                            value={fields[FIELD_BUY_AMOUNT]}
                                            placeholder={
                                                fields[FIELD_SELL_AMOUNT]
                                                    ? 'Request'
                                                    : ''
                                            }
                                            name={FIELD_BUY_AMOUNT}
                                            onValueChange={v =>
                                                onAmountChange(
                                                    v.value,
                                                    FIELD_BUY_AMOUNT,
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </td>
                            {isConfirmation ? (
                                <td data-notranslate>
                                    <CurrencyFlag code={currencyTo} />
                                    <span>{formatNumber(buyAmount)}</span>
                                </td>
                            ) : (
                                <td />
                            )}
                        </tr>

                        <tr>
                            <td>You sell</td>
                            <td>
                                <CurrencyFlag
                                    code={currencyFrom}
                                    text={formatNumber(amountFrom)}
                                />
                            </td>
                            <td>
                                <div className={styles.inputContainer}>
                                    <CurrencyFlag code={currencyFrom} />{' '}
                                    {isConfirmation ? (
                                        <span data-notranslate>
                                            {formatNumber(
                                                activeDeal.amountFrom,
                                            )}
                                        </span>
                                    ) : (
                                        <NumberFormat
                                            data-notranslate
                                            thousandSeparator="’"
                                            className={classNames(
                                                'form-control ml-2',
                                                numberInputHasWarningBorder()
                                                    ? styles.warning
                                                    : null,
                                            )}
                                            value={fields[FIELD_SELL_AMOUNT]}
                                            placeholder={
                                                fields[FIELD_BUY_AMOUNT]
                                                    ? 'Request'
                                                    : ''
                                            }
                                            name={FIELD_SELL_AMOUNT}
                                            onValueChange={v =>
                                                onAmountChange(
                                                    v.value,
                                                    FIELD_SELL_AMOUNT,
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            </td>
                            {isConfirmation ? (
                                <td>
                                    <CurrencyFlag code={currencyFrom} />
                                    <span data-notranslate>
                                        {formatNumber(sellAmount)}
                                    </span>
                                </td>
                            ) : (
                                <td />
                            )}
                        </tr>
                    </tbody>
                </Table>
            </Col>
        );
    };

    const renderAlert = () => (
        <Col md={12}>
            <Alert
                className="text-center"
                variant="warning"
                message="This offer is valid for two minutes. Please confirm the adjustments."
            />
        </Col>
    );

    return (
        <>
            <Modal.Header>
                <Modal.Title as="h5">
                    Amended transaction no.{' '}
                    <var data-var="transaction-id">{id}</var>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    {isConfirmation ? renderAlert() : null}
                    {renderTable()}
                </Row>

                <Row>
                    {isConfirmation && timeLeft ? (
                        <div className={styles.timeLeft}>
                            Your offer is valid for{' '}
                            <var data-var="time-left-on-offer">
                                <b>{timeLeft}</b>
                            </var>
                        </div>
                    ) : (
                        false
                    )}
                </Row>
                {error ? (
                    <Row>
                        <Col md={12}>
                            <Alert message={error} className="text-center" />
                        </Col>
                    </Row>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onClose}>
                    Cancel
                </Button>
                {timeLeft ? (
                    <SubmitButton
                        onClick={handleConfirm}
                        disabled={isSplitLoading}
                        isLoading={isSplitLoading}
                    >
                        {isConfirmation ? 'Confirm' : 'Request change'}
                    </SubmitButton>
                ) : (
                    <Button
                        variant="primary"
                        onClick={handleRefresh}
                        disabled={loading}
                    >
                        Refresh
                    </Button>
                )}
            </Modal.Footer>
        </>
    );
};

export default DrawdownModal;
