import {ICrpBpListItem} from 'api/business-partner';
import {
    useCreateAdmin,
    useCreateOwner,
    useCreateSigner,
    useSetAdmin,
    useUpdateContractSigner,
    useUpdateOwner,
    useUpdateSigner,
} from 'api/crp';
import {findOnboardingStepByType} from 'components/pages/OnboardingPage/onboardingSteps';
import {WithFormError} from 'components/ui/Form/Form/Form';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import {CRP_BP_LIST_CACHE} from 'constants/cache';
import {useBusinessPartner} from 'context/business-partner-context';
import useModal from 'components/ui/ModalContainer/useModal';
import {extract} from 'helpers/common';
import {handleTypedFormError} from 'helpers/handleFormError';
import {useMemo} from 'react';
import {UseFormMethods} from 'react-hook-form';
import {queryCache} from 'react-query';
import {StepTypes} from 'types/onboarding';
import {useCrpStore} from 'components/pages/OnboardingPage/components/store/crpStore';
import {ICrpFormValues} from '../CrpForm';

interface ICrpUpdateValues {
    '@id': string;
    lastName: string;
    firstName: string;
    email: string;
    phoneNumber?: string;
    street?: string;
    city?: string;
    zip?: string;
    country?: string;
    gender?: string;
    nationality?: string;
    dateOfBirth?: string;
    role: string;
    businessPartner: string;
}

const extractCrpUpdateValues = (confidential: boolean) => {
    if (confidential) {
        return extract<
            Pick<ICrpUpdateValues, 'firstName' | 'lastName' | 'email'>
        >({
            lastName: true,
            firstName: true,
            email: true,
        });
    }
    return extract<Omit<ICrpUpdateValues, 'businessPartner' | '@id'>>({
        lastName: true,
        firstName: true,
        phoneNumber: true,
        street: true,
        city: true,
        zip: true,
        country: true,
        email: true,
        gender: true,
        nationality: true,
        dateOfBirth: true,
        role: true,
    });
};

export type CrpChangeProp = keyof Pick<
    ICrpBpListItem,
    'admin' | 'isOwner' | 'hasSigningRights' | 'contractSigner'
>;
export const getChangeProp = (variant: CrpVariants): CrpChangeProp => {
    switch (variant) {
        case CrpVariants.OWNER:
            return 'isOwner';
        case CrpVariants.SIGNER:
            return 'hasSigningRights';
        case CrpVariants.CONTRACT_SIGNER:
            return 'contractSigner';
        case CrpVariants.ADMIN:
            return 'admin';
        default:
            throw new Error(`Invalid CrpCardsVariant: ${variant}`);
    }
};

interface IUseCrpTable {
    crpList: ICrpBpListItem[];
    currentStep: number;
    isIndividual?: boolean;
}

const useCrpTable = ({crpList, currentStep, isIndividual}: IUseCrpTable) => {
    const {closeModal} = useModal();
    const {activeBusinessPartner} = useBusinessPartner();
    const [updateOwner, {isLoading: isUpdateOwnerLoading}] = useUpdateOwner();
    const [createOwner, {isLoading: isCreateOwnerLoading}] = useCreateOwner();
    const [
        updateSigner,
        {isLoading: isUpdateSignerLoading},
    ] = useUpdateSigner();
    const [
        createSigner,
        {isLoading: isCreateSignerLoading},
    ] = useCreateSigner();
    const [
        updateContractSigner,
        {isLoading: isUpdateContractSignerLoading},
    ] = useUpdateContractSigner();
    const [setAdmin, {isLoading: isSetAdminLoading}] = useSetAdmin();
    const [createAdmin, {isLoading: isCreateAdminLoading}] = useCreateAdmin();
    const setCrpLoading = useCrpStore(state => state.setIsLoading);

    const updateFn = (variant: CrpVariants) => {
        switch (variant) {
            case CrpVariants.ADMIN:
                return setAdmin;
            case CrpVariants.OWNER:
                return updateOwner;
            case CrpVariants.SIGNER:
                return updateSigner;
            case CrpVariants.CONTRACT_SIGNER:
                return updateContractSigner;
            default:
                throw new Error(
                    `CrpVariant is not valid for update: ${variant}`,
                );
        }
    };

    const createFn = (variant: CrpVariants) => {
        switch (variant) {
            case CrpVariants.ADMIN:
                return createAdmin;
            case CrpVariants.OWNER:
                return createOwner;
            case CrpVariants.SIGNER:
                return createSigner;
            default:
                throw new Error(
                    `CrpVariant is not valid for create: ${variant}`,
                );
        }
    };

    const handleCrpEdit = (
        variant: CrpVariants,
        isLoggedInUser: boolean,
        values: ICrpFormValues,
        newFlag: boolean,
        crp: ICrpBpListItem,
        setError?: UseFormMethods['setError'],
    ) => {
        const changeProp = getChangeProp(variant);

        let directOwner: boolean | null;
        if (values.directOwner === 'null') {
            directOwner = null;
        } else {
            directOwner = values.directOwner
                ? values.directOwner === 'yes'
                : crp?.directOwner;
        }

        const crpData: Record<string, unknown> & {
            businessPartner: string;
            loggedInUser?: boolean;
        } = {
            ...extractCrpUpdateValues(crp?.confidential)({
                ...crp,
                ...crp?.crp,
                ...values,
            }),
            phoneNumber:
                variant === CrpVariants.OWNER ? undefined : values.phoneNumber,
            '@id': values['@id'],
            role: values.role ?? crp?.role,
            businessPartner: activeBusinessPartner['@id'],
            directOwner,
            collectiveSigningRight: values.collectiveSigningRight
                ? values.collectiveSigningRight === 'collective'
                : crp?.collectiveSigningRight,
            ownerPercentage:
                values.ownerPercentage || crp?.ownerPercentage || '0',
            highestEducation:
                values.highestEducation || crp?.crp?.highestEducation,
            professionalPosition:
                values.professionalPosition || crp?.professionalPosition,
            [changeProp]: newFlag,
        };

        if (isLoggedInUser) {
            crpData.loggedInUser = true;
            if (!crpList.some(crpBp => crpBp.crp['@id'] === crpData['@id'])) {
                delete crpData['@id'];
            }
        }

        setCrpLoading(true);
        return crpData['@id']
            ? updateFn(variant)(crpData, {
                  onSuccess: () => {
                      setCrpLoading(false);
                      queryCache.invalidateQueries(CRP_BP_LIST_CACHE);
                      closeModal();
                  },
                  onError: (requestErrors: any) => {
                      if (setError) {
                          setCrpLoading(false);
                          handleTypedFormError(
                              requestErrors,
                              setError,
                              values as WithFormError<ICrpFormValues>,
                          );
                      }
                  },
              })
            : createFn(variant)(crpData, {
                  onSuccess: () => {
                      setCrpLoading(false);
                      queryCache.invalidateQueries(CRP_BP_LIST_CACHE);
                      closeModal();
                  },
                  onError: (requestErrors: any) => {
                      if (setError) {
                          setCrpLoading(false);
                          handleTypedFormError(
                              requestErrors,
                              setError,
                              values as WithFormError<ICrpFormValues>,
                          );
                      }
                  },
              });
    };

    const maxSignersReached = useMemo(() => {
        return crpList?.filter(crp => crp.contractSigner)?.length === 2;
    }, [crpList]);

    const showSignersColumn = useMemo(() => {
        return (
            !isIndividual &&
            (currentStep >=
                findOnboardingStepByType(StepTypes.SIGNER_CRPS).number ||
                crpList?.some(crp => crp.hasSigningRights))
        );
    }, [crpList, currentStep, isIndividual]);

    const showContractSignersColumn = useMemo(() => {
        return (
            !isIndividual &&
            (currentStep >=
                findOnboardingStepByType(StepTypes.CONTRACT_SIGNER_CRPS)
                    .number ||
                crpList?.some(crp => crp.contractSigner))
        );
    }, [crpList, currentStep, isIndividual]);

    const showAdminColumn = useMemo(() => {
        return (
            !isIndividual &&
            (currentStep >=
                findOnboardingStepByType(StepTypes.ADMIN_CRPS).number ||
                crpList?.some(crp => crp.admin))
        );
    }, [crpList, currentStep, isIndividual]);

    return {
        isLoading:
            isUpdateOwnerLoading ||
            isCreateOwnerLoading ||
            isUpdateSignerLoading ||
            isCreateSignerLoading ||
            isSetAdminLoading ||
            isCreateAdminLoading ||
            isUpdateContractSignerLoading,
        handleCrpEdit,
        maxSignersReached,
        showSignersColumn,
        showContractSignersColumn,
        showAdminColumn,
    };
};

export default useCrpTable;
