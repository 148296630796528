import React from 'react';
import {useHistory} from 'react-router';
import {FormGroup} from 'react-bootstrap';
import Select, {components, OptionProps} from 'react-select';

import {BusinessPartner, BusinessPartnerListItem} from 'types/api';
import {PATH} from 'constants/common';

interface IProps {
    className?: string;
    businessPartners: BusinessPartner[];
    activeBusinessPartner: BusinessPartner;
    setSelectedBpId: React.Dispatch<React.SetStateAction<number>>;
}

const SelectBusinessPartner: React.FC<IProps> = ({
    className,
    businessPartners,
    activeBusinessPartner,
    setSelectedBpId,
}) => {
    const history = useHistory();

    const onBusinessPartnerChange = (event: BusinessPartnerListItem) => {
        setSelectedBpId(event.id);
        history.push(`${PATH.DASHBOARD}`);
    };

    const Option = (props: OptionProps<BusinessPartner, false>) => {
        const {children} = props;
        return (
            <>
                <components.Option {...props}>
                    <span data-notranlsate>{children}</span>
                </components.Option>
            </>
        );
    };

    return (
        <FormGroup className={className} data-notranslate>
            <Select
                classNamePrefix="amnis"
                options={businessPartners}
                getOptionLabel={option => option.companyName}
                getOptionValue={option => option.id.toString()}
                isSearchable={businessPartners.length > 5}
                isClearable={false}
                value={businessPartners.find(
                    obj => obj.id === activeBusinessPartner.id,
                )}
                components={{Option}}
                onChange={onBusinessPartnerChange}
            />
        </FormGroup>
    );
};

export default SelectBusinessPartner;
