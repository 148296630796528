import {CollectionVariants} from 'components/pages/CollectionsPage/useCollectionsPage';
import {NewAutomationPageSearchParams} from 'components/pages/NewAutomationPage/useNewAutomationPage';
import {IPaymentPageSearchParams} from 'components/pages/PaymentsPage/PaymentsPage';
import {TransationDetailsSearchParams} from 'components/ui/TransactionDetails/TransactionDetails';
import {PATH} from 'constants/common';
import {IAdminLanguages} from 'constants/languages';
import {getQueryWithParams} from 'helpers/common';
import {formatDateInZurichTZ} from 'helpers/dates';
import {IBusinessPartnerWallet} from 'types/api';
import {PayoutType} from 'types/payments';

type CreatePayoutUrl = {
    type: PayoutType;
    contactIri: string;
    currency?: string;
    senderWallet?: string;
    amount?: string | number;
    comment?: string;
    bankAccountIri?: string;
    reference?: string;
    executionDate?: string;
    disableDate?: boolean;
    disableAmount?: boolean;
    qrReference?: string;
};
export const createPayoutUrl = (data: CreatePayoutUrl) => {
    const paramsObj: Record<string, string> = {
        contactId: data.contactIri.split('/').pop(),
        step: '1',
        paymentType: data.type,
        currency: data.currency?.split('/').pop(),
        amount: data.amount ? Math.abs(+data.amount).toString() : undefined,
        executionDate: formatDateInZurichTZ(data.executionDate),
        disableDate: data.disableDate ? 'true' : undefined,
        disableAmount: data.disableAmount ? 'true' : undefined,
    };

    if (data.type === PayoutType.PEER) {
        paramsObj.senderWallet = data.senderWallet?.split('/').pop();
        paramsObj.comment = data.comment;
    }
    if (data.type === PayoutType.TRADITIONAL) {
        paramsObj.bankAccount = data.bankAccountIri?.split('/').pop();
        paramsObj.reference = data.reference;
        paramsObj.qrReference = data.qrReference;
    }

    const params = new URLSearchParams(getQueryWithParams(paramsObj));

    return {
        pathname: PATH.PAYOUT,
        search: params.toString(),
    };
};

type CreatePaymentRequestEditUrl = {
    /**
     * can be both ID or IRI
     */
    contactId: string;
    paymentRequestIri: string;
};
export const createPaymentRequestEditUrl = (
    data: CreatePaymentRequestEditUrl,
) => {
    const params = new URLSearchParams({
        contactId: data.contactId.split('/').pop(),
        paymentRequestId: data.paymentRequestIri,
        step: '1',
    });

    return {
        pathname: PATH.PAYMENT_REQUEST,
        search: params.toString(),
    };
};

type CreatePaymentRequestReconcileUrl = {
    paymentRequestId: string;
};
export const createPaymentRequestReconcileUrl = (
    /**
     * can be both ID or IRI
     */
    data: CreatePaymentRequestReconcileUrl,
) => {
    return {
        pathname: `${
            PATH.PAYMENT_REQUEST_RECONCILIATION
        }/${data.paymentRequestId.split('/').pop()}`,
    };
};

type CreatePaymentRequestPayUrl = {
    paymentRequestHash: string;
    /**
     * can be both ID or IRI
     */
    contactId: string;
};
export const createPaymentRequesPayUrl = ({
    paymentRequestHash,
    contactId,
}: CreatePaymentRequestPayUrl) => {
    const params = new URLSearchParams(
        getQueryWithParams({
            step: '1',
            paymentType: PayoutType.PEER,
            requestHash: paymentRequestHash,
            contactId: contactId.split('/').pop(),
        }),
    );
    return {
        pathname: PATH.PAYOUT,
        search: params.toString(),
    };
};

export const createPaymentsPageUrl = (data: IPaymentPageSearchParams) => {
    const paramsObj = getQueryWithParams(data);
    const params = new URLSearchParams(paramsObj);

    return {
        pathname: PATH.PAYMENTS,
        search: params.toString(),
    };
};

export const createTransactionDetailsUrl = (
    data: TransationDetailsSearchParams,
) => {
    const paramsObj = getQueryWithParams(data);
    const params = new URLSearchParams(paramsObj);

    return {
        search: params.toString(),
    };
};

export const createPaymentRequestListUrl = (variant: CollectionVariants) => {
    const params = new URLSearchParams({
        variant,
    });
    return {
        pathname: PATH.COLLECTIONS,
        search: params.toString(),
    };
};

export const createStatementsUrl = (wallet: IBusinessPartnerWallet) => {
    const params = new URLSearchParams(
        getQueryWithParams(
            {
                walletId: wallet['@id'],
            },
            {
                removeNulls: true,
            },
        ),
    );

    return {
        pathname: PATH.STATEMENTS,
        search: params.toString(),
    };
};

const BASE_FAQ_LINK = 'https://help.amnistreasury.com/';
export const createFaqLink = (languageParams: IAdminLanguages, url: string) => {
    return BASE_FAQ_LINK + languageParams.faqLanguage + url;
};

export const createAutomationUrl = (data: NewAutomationPageSearchParams) => {
    const params = new URLSearchParams(data);

    return {
        pathname: PATH.NEW_AUTOMATION,
        search: params.toString(),
    };
};
