import React from 'react';
import {useCurrencies} from 'api/currencies';
import StepTitle from 'components/pages/OnboardingPage/components/StepTitle';
import {IDefaultStepProps} from 'components/pages/OnboardingPage/useOnboarding';
import AutoComplete from 'components/ui/Form/AutoComplete/AutoComplete';
import CurrencyAutoComplete from 'components/ui/Form/AutoComplete/CurrencyAutoComplete';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import {currencyVolumeData} from 'constants/businessPartnerAttributes';
import NextStepButton from 'components/pages/OnboardingPage/components/NextStepButton';
import {useUpdateBusinessPartnerOnboarding} from 'api/business-partner';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import {
    FxStepFormValues,
    fxValidationSchema,
} from 'form/validation/businessPartner';
import {translate} from 'helpers/localize';
import {LegalType} from 'types/onboarding';
import styles from './Steps.module.scss';

const FxStep = (props: IDefaultStepProps) => {
    const {
        activeBusinessPartner,
        currentStep,
        onSuccess,
        setActiveBusinessPartner,
        legalType,
    } = props;

    const [
        addAdditionalInfo,
        {isLoading},
    ] = useUpdateBusinessPartnerOnboarding();

    const handleSubmit: FormOnSubmit<any> = (data, setError) => {
        const {currencyVolume, ...rest} = data.gwgInfo;
        const [currencyVolumeLower, currencyVolumeUpper] = currencyVolume.split(
            '-',
        );
        addAdditionalInfo(
            {
                gwgInfo: {
                    ...rest,
                    currencyVolumeLower,
                    currencyVolumeUpper,
                    id: activeBusinessPartner.gwgInfo?.id,
                },
                businessPartnerId: activeBusinessPartner['@id'],
                step: findOnboardingStepByNumber(currentStep)?.type,
            },
            {
                onSuccess: response => {
                    setActiveBusinessPartner(response);
                    onSuccess();
                },
                onError: (requestErrors: any) => {
                    // handleTypedFormError(requestErrors, setError, data);
                },
            },
        );
    };

    const {data: currencies} = useCurrencies();

    const currencyVolumeList = currencyVolumeData(legalType);
    return (
        <>
            <StepTitle title="Currency exchange" />
            <Form<FxStepFormValues>
                onSubmit={handleSubmit}
                className={styles.form}
                validationSchema={fxValidationSchema}
                defaultValues={{
                    gwgInfo: {
                        currencyVolume:
                            activeBusinessPartner?.gwgInfo &&
                            activeBusinessPartner.gwgInfo
                                .currencyVolumeUpper !== null &&
                            activeBusinessPartner.gwgInfo
                                .currencyVolumeLower !== null
                                ? `${activeBusinessPartner.gwgInfo.currencyVolumeLower}-${activeBusinessPartner.gwgInfo.currencyVolumeUpper}`
                                : null,
                        relevantCurrencies:
                            activeBusinessPartner?.gwgInfo
                                ?.relevantCurrencies || [],
                    },
                }}
            >
                <AutoComplete
                    label={
                        <span data-notranslate>
                            {translate(
                                'How much do you estimate your foreign currency exchange volume per year in %{currency}?',
                                {
                                    currency: activeBusinessPartner.mainCurrency
                                        ?.split('/')
                                        .pop(),
                                },
                            )}
                        </span>
                    }
                    name="gwgInfo.currencyVolume"
                    placeholder="Please select"
                    options={currencyVolumeList}
                    sortKey="@id"
                    numericSort
                    variant="dark"
                    translateInputContent={false}
                    size="large"
                />
                <CurrencyAutoComplete
                    name="gwgInfo.relevantCurrencies"
                    placeholder="Select currencies"
                    label={`Which currencies are relevant for your ${
                        legalType === LegalType.COMPANY ? 'business' : 'private'
                    } activities?`}
                    variant="dark"
                    options={currencies}
                    isMulti
                    size="large"
                />
                <NextStepButton loading={isLoading} />
            </Form>
        </>
    );
};

export default FxStep;
