import React from 'react';

import {Controller, useFormContext} from 'react-hook-form';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';
import {UsePinInput} from 'components/ui/Form/PinInput/usePinInput';
import styles from './PinInput.module.scss';

interface IPinInputProps {
    methods: UsePinInput;
    name?: string;
    autoFocusIndex?: number;
    autoFocusActive?: boolean;
    lastFieldFilled?: () => void;
}

const PinInput = ({
    methods: {
        fieldNr,
        getPinValue,
        pinControl,
        pinFields,
        setPinValue,
        elements,
    },
    lastFieldFilled,
    autoFocusIndex = 0,
    autoFocusActive = true,
    name = 'pin',
}: IPinInputProps) => {
    // TODO-CARD: commented out until decided if we want to have this extra logic or not
    // const [automaticFocus, setAutomaticFocus] = useState(false);
    const {setValue, register} = useFormContext();

    const handleKeyUp = (key: string, index: number) => {
        const {rawValues} = getPinValue();
        if (index < fieldNr - 1) {
            if (!!key && !Number.isNaN(+key)) {
                elements[index + 1].focus();
            }
        } else {
            setValue(
                name,
                rawValues.reduce((acc, curr) => {
                    if (curr.value !== undefined && curr.value !== null) {
                        return acc + curr.value;
                    }

                    return acc;
                }, ''),
            );
            if (lastFieldFilled) {
                lastFieldFilled();
            }
        }
    };

    register(name);

    const onFocus = (index: number) => {
        if (getPinValue().rawValues?.[index]?.value !== null) {
            setPinValue(`rawValues.${index}.value`, null);
        }
    };

    return (
        <div className={styles.continer}>
            {pinFields.map((item, index) => {
                return (
                    <Controller
                        key={item.id}
                        name={`rawValues.${index}.value`}
                        control={pinControl}
                        defaultValue={item.value}
                        render={controllerProps => {
                            return (
                                <NumberFormat
                                    getInputRef={(_ref: HTMLInputElement) => {
                                        // TODO
                                        // eslint-disable-next-line no-param-reassign
                                        elements[index] = _ref;
                                    }}
                                    {...controllerProps}
                                    onChange={e => {
                                        e.persist();
                                        setPinValue(
                                            `rawValues.${index}.value`,
                                            e.target.value,
                                        );
                                        // TODO - this is disgusting but didn't find any other way yet to handle REALLY fast typings
                                        setTimeout(() => {
                                            handleKeyUp(e.target.value, index);
                                        });
                                    }}
                                    fixedDecimalScale
                                    className={classNames(
                                        styles.input,
                                        styles.amountInput,
                                    )}
                                    data-notranslate
                                    onFocus={() => onFocus(index)}
                                    onKeyDown={e => {
                                        e.persist();
                                        if (
                                            e.key === 'Backspace' &&
                                            !getPinValue().rawValues?.[index]
                                                ?.value &&
                                            index > 0
                                        ) {
                                            setTimeout(() => {
                                                elements[index - 1].focus();
                                            });
                                        }
                                    }}
                                    maxLength={1}
                                    autoFocus={
                                        index === autoFocusIndex &&
                                        autoFocusActive
                                    }
                                />
                            );
                        }}
                    />
                );
            })}
        </div>
    );
};

export default PinInput;
