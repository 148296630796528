import React from 'react';

const DebitCards = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M3.825 3.732h19.549v12.965h-2.178V15.38h.861V5.05H5.142v.86H3.825V3.733Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M1.943 8.418h16.611v2.066H1.943V8.418Zm0 4.842v5.88h16.611v-5.88H1.943ZM.626 7.1h19.245v13.403H.626V7.101Z"
            clipRule="evenodd"
        />
        <path
            fill="currentColor"
            d="M6.298 17.21a1.656 1.656 0 0 1-3.039-.916 1.658 1.658 0 0 1 3.039-.917 1.656 1.656 0 0 1 3.039.917 1.658 1.658 0 0 1-3.039.916Z"
        />
    </svg>
);

export default DebitCards;
