import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {useLocation} from 'react-router';
import {Switch, Route} from 'react-router-dom';
import Logo from 'components/ui/Logo/Logo';
import {PATH} from 'constants/common';
import globe from 'assets/images/Outsourcing.png';
import {isIframe} from 'helpers/common';
import InfoBlock from './InfoBlock';
import styles from './UnauthorisedLayout.module.sass';

export enum LayoutVariants {
    DEFAULT = 'DEFAULT',
    WIR = 'WIR',
    A352 = 'A352',
}

const UnauthorisedLayout = ({children}) => {
    const [smallWidth, setSmallWidth] = useState<boolean>(false);
    const {pathname} = useLocation();

    const registrationPath = [
        PATH.REGISTRATION,
        PATH.REGISTRATION_WIR,
        PATH.REGISTRATION_A352,
        PATH.REGISTRATION_BEXIO,
        PATH.REGISTRATION_RATE_ALERT,
    ].includes(pathname);

    useEffect(() => {
        const updateScreenSize = () => {
            if (window.innerWidth <= 720) {
                setSmallWidth(true);
            } else {
                setSmallWidth(false);
            }
        };
        updateScreenSize();
        window.addEventListener('resize', updateScreenSize);
        return () => window.removeEventListener('resize', updateScreenSize);
    }, []);

    return (
        <div
            className={classNames(styles.container, {
                [styles.pricing]: pathname === PATH.PRICING,
            })}
        >
            {isIframe() ? null : (
                <Logo
                    blue={!registrationPath || smallWidth}
                    className={styles.logo}
                />
            )}

            {registrationPath ? <InfoBlock /> : null}
            <div
                className={classNames(styles.withContent, {
                    [styles.side]: registrationPath,
                    [styles.withoutInfo]: !registrationPath,
                })}
            >
                <div
                    className={classNames(styles.content, {
                        [styles.pricingContent]: pathname === PATH.PRICING,
                        [styles.narrow]: registrationPath,
                    })}
                >
                    <Switch>
                        <Route
                            path={PATH.LOGIN_WIR}
                            component={() => (
                                <Logo variant={LayoutVariants.WIR} />
                            )}
                        />
                        <Route
                            path={PATH.REGISTRATION_WIR}
                            component={() => (
                                <Logo variant={LayoutVariants.WIR} />
                            )}
                        />
                        <Route
                            path={PATH.LOGIN_A352}
                            component={() => (
                                <Logo variant={LayoutVariants.A352} />
                            )}
                        />
                        <Route
                            path={PATH.REGISTRATION_A352}
                            component={() => (
                                <Logo variant={LayoutVariants.A352} />
                            )}
                        />
                        <Route path={PATH.PRICING} exact />
                        <Route
                            path="/"
                            component={() => (
                                <img
                                    src={globe}
                                    alt="globe"
                                    className={classNames(styles.globeImg, {
                                        [styles.hidden]: registrationPath,
                                    })}
                                />
                            )}
                        />
                    </Switch>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default UnauthorisedLayout;
