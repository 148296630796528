import React from 'react';

const StatementsIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 200 200"
        {...props}
    >
        <path
            fill="none"
            stroke="#0f3e82"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M35.07 90V35.26L165.02 35v75m0 10v45H34.98v-65"
        />
        <path
            fill="none"
            stroke="#b5e3d8"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M60 65h80"
        />
        <path
            fill="none"
            stroke="#0f3e82"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M60 105h80m-80 20h70m-70 19.99h50"
        />
    </svg>
);

export default StatementsIcon;
