import {useGetMercureToken} from 'api/notifications';
import React, {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import {EventSourcePolyfill, MessageEvent} from 'event-source-polyfill';
import {parseJwt} from 'helpers/common';
import {useAuth} from './auth-context';

export interface IEventSourceContext {
    eventSource: EventSourcePolyfill | null;
    registerListener: RegisterListener;
    removeListener: (listenerKey: string) => void;
    registerNotifications: RegisterAllListener;
}

export type ListenerHandler = {
    area: string;
    callback: (data: IMessage) => void;
};

export type RegisterListener = (
    listenerKey: string,
    listenerHandler: ListenerHandler,
    mfaClaimUUID?: string,
) => () => void;

export type RegisterAllListener = (
    listenerKey: string,
    callback: (event: MessageEvent) => void,
) => () => void;

const EventSourceContext = createContext<IEventSourceContext>(null);
EventSourceContext.displayName = 'EventSourceContext';

export const allListeners = 'allListeners';
export interface IEventSourceProviderProps {
    children: React.ReactNode;
}

export interface IMessage {
    area: string;
    status: 'success' | 'error';
    message: string;
    payload: Record<string, any>;
    mfaClaimUUID: string;
}

const EventSourceProvider = ({children}: IEventSourceProviderProps) => {
    const {token} = useAuth();
    const [mercureToken, setMercureToken] = useState<string>(null);
    const listenerMap = useRef<Map<string, (event: MessageEvent) => void>>(
        new Map(),
    );
    const eventSource = useRef<EventSourcePolyfill | null>(null);

    const [getMercureToken] = useGetMercureToken();

    useEffect(() => {
        if (token)
            getMercureToken(token, {
                onSuccess: response => setMercureToken(response.token),
            });
    }, [token, getMercureToken]);

    const registerToMercure = useCallback(() => {
        const parsedToken = parseJwt(token);
        if (
            parsedToken &&
            mercureToken &&
            !eventSource.current &&
            process.env.REACT_APP_MERCURE_URL
        ) {
            const url = new URL(process.env.REACT_APP_MERCURE_URL);
            url.searchParams.set('topic', `/users/${parsedToken.id}`);

            eventSource.current = new EventSourcePolyfill(url.toString(), {
                lastEventIdQueryParameterName: 'Last-Event-ID',
                headers: {
                    Authorization: `Bearer ${mercureToken}`,
                },
            });

            if (process.env.NODE_ENV === 'development') {
                eventSource.current.addEventListener('message', event => {
                    console.log(JSON.parse(event.data));
                });
            }
        }

        return () => {
            if (eventSource && eventSource.current) {
                eventSource.current.close();
                eventSource.current = null;
            }
        };
    }, [mercureToken, token]);

    const removeListener = useCallback(
        (listenerKey: string) => {
            if (eventSource.current) {
                const listener = listenerMap.current.get(listenerKey);
                eventSource.current.removeEventListener('message', listener);
                listenerMap.current.delete(listenerKey);
            }
        },
        [eventSource],
    );

    const unregisterListeners = useCallback(() => {
        listenerMap.current.forEach(listener =>
            eventSource.current.removeEventListener('message', listener),
        );
    }, [eventSource]);

    const registerListeners = useCallback(() => {
        if (eventSource.current) {
            unregisterListeners();
            listenerMap.current.forEach(listener =>
                eventSource.current.addEventListener('message', listener),
            );
        }
    }, [eventSource, unregisterListeners]);

    const registerListener: RegisterListener = useCallback(
        (listenerKey, listenerHandler, mfaClaimUUID) => {
            const listener = (event: MessageEvent) => {
                const parsed = JSON.parse(event.data) as IMessage;
                if (
                    parsed.area === listenerHandler.area &&
                    parsed.mfaClaimUUID === mfaClaimUUID
                ) {
                    listenerHandler.callback(parsed);
                }
            };
            listenerMap.current.delete(listenerKey);
            listenerMap.current.set(listenerKey, listener);
            registerListeners();

            return () => removeListener(listenerKey);
        },
        [registerListeners, removeListener],
    );

    const registerNotifications: RegisterAllListener = useCallback(
        (listenerKey, callback) => {
            const listener = (event: MessageEvent) => {
                callback(event);
            };
            listenerMap.current.delete(listenerKey);
            listenerMap.current.set(listenerKey, listener);
            registerListeners();

            return () => removeListener(listenerKey);
        },
        [registerListeners, removeListener],
    );

    useEffect(() => {
        if (!mercureToken) return () => null;

        const unRegister = registerToMercure();
        registerListeners();

        return () => unRegister();
    }, [registerToMercure, mercureToken, registerListeners]);

    useEffect(() => {
        registerListeners();
    }, [token, registerListeners]);

    return (
        <EventSourceContext.Provider
            value={{
                eventSource: eventSource.current,
                registerListener,
                removeListener,
                registerNotifications,
            }}
        >
            {children}
        </EventSourceContext.Provider>
    );
};

const useEventSource = () => {
    const context = useContext(EventSourceContext);

    if (context === undefined) {
        throw new Error(
            `useEventSource must be used within a EventSourceProvider`,
        );
    }
    return context;
};

export {EventSourceProvider, useEventSource};
