import CheckBoxGroup from 'components/ui/Form/OnboardingCheckBoxGroup/OnboardingCheckBoxGroup';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import React from 'react';
import {ICrp, ICrpBpListItem} from 'api/business-partner';
import {useAddNewCrpBp, useSearchCrp} from 'api/crp';
import {useBusinessPartner} from 'context/business-partner-context';
import {handleTypedFormError} from 'helpers/handleFormError';
import Button from 'components/ui/Button';
import IconSVG from 'components/ui/Icon/IconSVG';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {getChangeProp} from 'components/pages/OnboardingPage/components/hooks/useCrpTable';
import {CrpVariants} from 'constants/businessPartnerAttributes';
import styles from './AddNewCrpForm.module.scss';
import NextStepButton from './NextStepButton';
import StepTitle from './StepTitle';

export interface IAddNewCrpFormValues {
    crp?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

export type AddNewCrpBp = (crpBp?: ICrpBpListItem) => void;

export interface IAddNewCrpFormProps {
    handleSubmit: AddNewCrpBp;
    connectedCrps: ICrp[];
    crpBpList: ICrpBpListItem[];
    variant: CrpVariants;
}

const AddNewCrpForm = (props: IAddNewCrpFormProps) => {
    const {handleSubmit, connectedCrps, crpBpList, variant} = props;
    const {activeBusinessPartner} = useBusinessPartner();
    const [addNewCrpBp, {isLoading: isAddLoading}] = useAddNewCrpBp();
    const [searchCrp, {isLoading: isSearchLoading}] = useSearchCrp();

    const crpOptions = connectedCrps.map(crp => ({
        label: `${crp.firstName} ${crp.lastName}`,
        value: crp['@id'],
        disabled:
            crpBpList?.find(crpBp => crpBp.crp['@id'] === crp['@id']) !==
            undefined,
    }));

    const handleAddCrpBP: FormOnSubmit<IAddNewCrpFormValues> = (
        data,
        setError,
    ) => {
        if (data.crp) {
            addNewCrpBp(
                {
                    businessPartner: activeBusinessPartner['@id'],
                    crp: data.crp,
                },
                {
                    onSuccess: response => handleSubmit(response),
                    onError: (err: any) =>
                        handleTypedFormError(err, setError, data),
                },
            );
        } else {
            const {email, firstName, lastName} = data;
            searchCrp(
                {
                    email,
                    firstName,
                    lastName,
                    [getChangeProp(variant)]: true,
                    onboardBusinessPartner: activeBusinessPartner['@id'],
                },
                {
                    onSuccess: response => handleSubmit(response),
                    onError: (err: any) =>
                        handleTypedFormError(err, setError, data),
                },
            );
        }
    };

    return (
        <div className={styles.container}>
            <StepTitle title="Add a new person" />

            <Form<IAddNewCrpFormValues> onSubmit={handleAddCrpBP}>
                <p className={styles.subTitle}>
                    Select an already existing one from the list:
                </p>
                <div className={styles.crpList} data-notranslate>
                    <CheckBoxGroup
                        defaultValue=""
                        label=""
                        type="radio"
                        options={crpOptions}
                        name="crp"
                        variant="dark"
                        className={styles.checkItem}
                    />
                </div>
                <div className={styles.divider}>OR</div>
                <p className={styles.subTitle}>
                    Already existing, but not on the list
                </p>
                <TextInput
                    label="First name"
                    name="firstName"
                    variant="dark"
                    placeholder="First name"
                    size="large"
                />
                <TextInput
                    label="Last name"
                    name="lastName"
                    variant="dark"
                    placeholder="Last name"
                    size="large"
                />
                <TextInput
                    label="Please enter the email address the person used to register with Amnis"
                    name="email"
                    variant="dark"
                    placeholder="Enter email"
                    size="large"
                />
                <div className={styles.divider}>OR</div>
                <div className={styles.addNewRow}>
                    <p className={styles.subTitle}>Add a new person</p>
                    <Button
                        onClick={() => handleSubmit()}
                        icon={<IconSVG name="plus" />}
                    >
                        New
                    </Button>
                </div>
                <NextStepButton loading={isAddLoading || isSearchLoading} />
            </Form>
        </div>
    );
};

export default AddNewCrpForm;
