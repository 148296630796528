import React from 'react';

const PaymentTypePeer = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 58 58"
        {...props}
    >
        <path
            fill="#fff"
            stroke="#33F"
            strokeWidth="2"
            d="M57 29c0 15.464-12.536 28-28 28S1 44.464 1 29 13.536 1 29 1s28 12.536 28 28Z"
        />
        <path
            fill="#33F"
            d="M41.031 18.375a4.781 4.781 0 0 1-7.965 3.567l-7.539 4.398a7.402 7.402 0 0 1 1.13 3.941c0 .65-.084 1.28-.24 1.881l9.055 5.175a5.313 5.313 0 1 1-.973 1.532l-8.76-5.007a7.438 7.438 0 1 1-1.369-8.946l7.59-4.427a4.781 4.781 0 1 1 9.07-2.114Z"
        />
    </svg>
);

export default PaymentTypePeer;
