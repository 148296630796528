import React from 'react';

const Pause = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill="currentColor"
            fillRule="evenodd"
            d="M8 19V5h2v14H8Zm6 0V5h2v14h-2Z"
            clipRule="evenodd"
        />
    </svg>
);

export default Pause;
