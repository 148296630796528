import React from 'react';

const SvgSwap = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 29 28"
        {...props}
    >
        <path
            fill="currentColor"
            d="M8.563.313.405 6.59l8.156 6.279V8.404h18.455V4.779H8.563V.313ZM20.673 27.37l8.156-6.279-8.156-6.278v4.466H2.218v3.625h18.455v4.466Z"
        />
    </svg>
);

export default SvgSwap;
