import React from 'react';

import styles from './CrpAdminInfo.module.scss';

const CrpAdminInfo = () => {
    return (
        <div className={styles.container}>
            <h3>Administrator</h3>
            <p>
                Administrator in this context is the user who will have admin
                access rights to the amnis application. The administrator can be
                edited or changed later.
            </p>
            <h5>What&apos;s the role of administrators?</h5>
            <p data-isolate>
                Users with <span className={styles.strong}>administrator</span>
                &nbsp;rights will have access to the amnis application.
                Depending on the settings (which can be edited in the
                application and granted individually) they will have the rights
                to perform some or all of the amnis functions within the app
                such as adding, editing or deleting users, perform currency
                exchanges, execute payments or issuing and managing debit cards.
            </p>
            <p>
                Note that during onboarding&nbsp;
                <span className={styles.strong}>only one administrator</span>
                &nbsp;can be defined. Administrators can be edited, changed or
                added once the onboarding process is finished if required.
            </p>
        </div>
    );
};

export default CrpAdminInfo;
