import React from 'react';
import {Button, Spinner, ButtonProps} from 'react-bootstrap';

interface IProps {
    isLoading: boolean;
    className?: string;
    onClick?(): void;
}

const SubmitButton: React.FC<ButtonProps & IProps> = ({
    isLoading,
    children,
    onClick,
    className = null,
    disabled = false,
    ...rest
}) => (
    <Button
        type="submit"
        disabled={isLoading || disabled}
        className={className}
        onClick={onClick}
        style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        }}
        {...rest}
    >
        {isLoading ? (
            <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                style={{marginRight: '5px'}}
            />
        ) : null}
        {children}
    </Button>
);

export default SubmitButton;
