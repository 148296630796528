import React from 'react';
import classNames from 'classnames';
import styles from './CurrencyFlag.module.scss';

interface ICurrencyFlagProps {
    className?: string;
    code: string;
    title?: boolean;
    text?: string;
    size?: 'sm' | 'lg' | 'xl' | 'xxl';
}

const CurrencyFlag: React.FC<ICurrencyFlagProps> = ({
    className,
    code,
    title = true,
    text,
    size = 'sm',
}) => {
    const realCode = code?.split('/').pop();
    const lowerCasedCode =
        realCode === 'CNH' ? 'cny' : realCode?.toLocaleLowerCase();
    return (
        <span className={classNames(className, styles.currency)}>
            <span
                className={`currency-flag currency-flag-${lowerCasedCode} currency-flag-${size}`}
            />
            {title ? (
                <span className={styles.country} data-notranslate>
                    {realCode}
                </span>
            ) : null}
            {text ? (
                <span className={styles.text} data-notranslate>
                    {text}
                </span>
            ) : null}
        </span>
    );
};

export default CurrencyFlag;
