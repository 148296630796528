import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import TextInput from 'components/ui/Form/TextInput/TextInput';
import {bpIndividualValidationSchema} from 'form/validation/businessPartner';
import {
    LEGAL_FORM_NATURAL_PERSON,
    LOCAL_STORAGE_BP_KEY,
} from 'constants/common';
import {useAuth} from 'context/auth-context';
import {
    useAddBusinessPartner,
    useUpdateBusinessPartnerOnboarding,
} from 'api/business-partner';
import {handleFormError} from 'helpers/handleFormError';
import {setItemToStorage} from 'helpers/localStorage';
import CountryAutoComplete from 'components/ui/Form/AutoComplete/CountryAutoComplete';
import {useFilteredCountries} from 'hooks/countries';
import PhoneInput from 'components/ui/Form/PhoneInput/PhoneInput';
import {BusinessPartner} from 'types/api';
import Form, {FormOnSubmit} from 'components/ui/Form/Form/Form';
import {OnboardingSuccessCallback} from 'types/onboarding';
import useAnalytics from 'hooks/useAnalytics';
import {findOnboardingStepByNumber} from 'components/pages/OnboardingPage/onboardingSteps';
import NextStepButton from './NextStepButton';
import StepTitle from './StepTitle';

import styles from './IndividualForm.module.scss';

interface IIndividualFormValues {
    countryOfIncorporation: string;
    address: string;
    zip: string;
    city: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    legalForm: string;
}

interface IIndividualFormProps {
    isEdit: boolean;
    activeBusinessPartner: BusinessPartner;
    onSuccess: OnboardingSuccessCallback;
    className?: string;
    setActiveBusinessPartner: Dispatch<SetStateAction<BusinessPartner>>;
    shouldBlockNavigation?: boolean;
    currentStep: number;
}

const IndividualForm = (props: IIndividualFormProps) => {
    const [defaultValues, setDefaultValues] = useState<IIndividualFormValues>({
        countryOfIncorporation: '',
        address: '',
        zip: '',
        city: '',
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        legalForm: LEGAL_FORM_NATURAL_PERSON,
    });
    const {
        activeBusinessPartner,
        onSuccess,
        setActiveBusinessPartner,
        isEdit,
        className,
        shouldBlockNavigation,
        currentStep,
    } = props;
    const {countries, isLoading: countriesIsLoading} = useFilteredCountries(
        'onboardingIndividual',
    );
    const {user} = useAuth();
    const {addEvent} = useAnalytics();

    useEffect(() => {
        if (activeBusinessPartner && user) {
            setDefaultValues({
                // hard-code Natural Person @id
                legalForm: LEGAL_FORM_NATURAL_PERSON,

                countryOfIncorporation:
                    activeBusinessPartner.countryOfIncorporation,
                address: activeBusinessPartner.address,
                zip: activeBusinessPartner.zip,
                city: activeBusinessPartner.city,

                // Individual fields
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phoneNumber: user.phone,
            });
        }
    }, [activeBusinessPartner, user]);

    const [addBusinessPartner, {isLoading}] = useAddBusinessPartner();
    const [
        addAdditionalInfo,
        {isLoading: isEditLoading},
    ] = useUpdateBusinessPartnerOnboarding();

    const handleAddBusinessPartner: FormOnSubmit<IIndividualFormValues> = (
        values,
        setError,
    ) => {
        if (isEdit) {
            addAdditionalInfo(
                {
                    businessPartnerId: activeBusinessPartner['@id'],
                    ...values,
                    step: findOnboardingStepByNumber(currentStep)?.type,
                },
                {
                    onSuccess: response => {
                        setActiveBusinessPartner(response);
                        onSuccess();
                    },
                    onError: (requestErrors: any) => {
                        handleFormError(requestErrors, setError);
                    },
                },
            );
        } else {
            addBusinessPartner(values, {
                onSuccess: data => {
                    // Set the new Business Partner like active
                    setActiveBusinessPartner(data);
                    setItemToStorage(LOCAL_STORAGE_BP_KEY, data['@id']);
                    addEvent('open_private_account');

                    onSuccess();
                },
                onError: (requestErrors: any) => {
                    handleFormError(requestErrors, setError);
                },
            });
        }
    };

    const AdditionalFields = () => {
        return (
            <>
                <TextInput
                    label="First name(s)"
                    name="firstName"
                    placeholder="First name(s)"
                    info="Please provide all your first names according to your passport"
                    variant="dark"
                    size="large"
                />
                <TextInput
                    label="Last name"
                    name="lastName"
                    placeholder="Last name"
                    variant="dark"
                    size="large"
                />
                <TextInput
                    label="Street"
                    name="address"
                    placeholder="Street and house number"
                    variant="dark"
                    size="large"
                />
                <Form.Group>
                    <TextInput
                        label="Post Code"
                        name="zip"
                        placeholder="Postal code"
                        className={styles.zip}
                        variant="dark"
                        size="large"
                    />
                    <TextInput
                        label="City"
                        name="city"
                        placeholder="Zurich"
                        className={styles.city}
                        variant="dark"
                        size="large"
                    />
                </Form.Group>
                <PhoneInput
                    name="phoneNumber"
                    label="Mobile number"
                    variant="dark"
                    size="large"
                />
                <TextInput
                    label="E-mail address"
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    variant="dark"
                    size="large"
                />
            </>
        );
    };

    return (
        <>
            <StepTitle title="Private Individual" />
            <Form<IIndividualFormValues>
                onSubmit={handleAddBusinessPartner}
                validationSchema={bpIndividualValidationSchema}
                defaultValues={defaultValues}
                customFields={['legalForm']}
                className={className}
                confirmLeave={shouldBlockNavigation !== false}
            >
                <CountryAutoComplete
                    name="countryOfIncorporation"
                    label="Country of residence"
                    placeholder="Switzerland"
                    isLoading={countriesIsLoading}
                    options={countries}
                    variant="dark"
                    size="large"
                />
                <AdditionalFields />
                <NextStepButton loading={isLoading || isEditLoading} />
            </Form>
        </>
    );
};

export default IndividualForm;
