import React from 'react';

const SvgRegisterCheckmark = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 200 200"
        {...props}
    >
        <path
            fill="none"
            stroke="#b5e3d8"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M164.9 95a64.94 64.94 0 1 0 0 10"
        />
        <path
            fill="none"
            stroke="#fff"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="m126.52 62.01-37.89 68.27-18.85-15.51"
        />
    </svg>
);

export default SvgRegisterCheckmark;
