import React from 'react';
import ICONS, {Icons} from './IconSVGConfig';

export interface IIconSVG extends Omit<React.SVGProps<SVGSVGElement>, 'name'> {
    name: Icons;
    filled?: boolean;
    customDimensions?: boolean;
}

const IconSVG: React.FC<IIconSVG> = ({name, customDimensions, ...props}) => {
    if (!name) return null;

    const Component = ICONS[name.toString()];
    if (!Component) return null;

    const style = customDimensions
        ? null
        : {
              width: '1.5rem',
              height: 'auto',
          };

    return <Component style={style} {...props} data-notranslate />;
};

IconSVG.defaultProps = {customDimensions: false};

export default IconSVG;
