import React from 'react';

const SvgRegisterCard = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...props}>
        <path
            fill="none"
            fillRule="evenodd"
            stroke="#B3DFD8"
            strokeMiterlimit="10"
            strokeWidth="10"
            d="M35 75v65h130V60H30"
        />
        <path
            d="M110 110h30M58.81 90H140"
            fill="none"
            strokeMiterlimit="10"
            strokeWidth="10px"
            stroke="#B3DFD8"
        />

        <circle
            cx="100.5"
            cy="100.5"
            r="100"
            fill="none"
            stroke="#B3DFD8"
            strokeMiterlimit="10"
        />
    </svg>
);

export default SvgRegisterCard;
