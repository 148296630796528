import {createMaps} from 'helpers/common';

export const COLLATERAL_PAYOUT_PARAM = 'collateral-payout';
export const COLLATERAL_PAYIN_PARAM = 'collateral-payin';

export const ROUTING_TYPE_MAPPING = createMaps({
    bic_swift: 'BIC/Swift',
    sort_code: 'Sort code',
    wire_routing_number: 'Wire routing number',
    ach_routing_number: 'ACH routing number',
    bic: '',
});
