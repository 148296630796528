import React, {
    createContext,
    useContext,
    useState,
    useEffect,
    useMemo,
    useCallback,
} from 'react';
import {ADMIN_LANGUAGES, IAdminLanguages} from 'constants/languages';
import {
    getAvailableLocalizeLanguages,
    getLocalizeLanguage,
    prefetchLocalizeLanguage,
    setLocalizeLanguage,
} from 'helpers/localize';

export type LocalizeLanguages = {
    code: string;
    name: string;
    id: string;
};

const LanguageContext = createContext(null);
LanguageContext.displayName = 'LanguageContext';

const LanguageProvider = props => {
    const [availableLanguages, setAvailableLanguage] = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
    const [selectedLanguageId, setSelectedLanguageId] = useState<string>(
        '/web_api/languages/1',
    );
    const [selectedLanguageParams, setSelectedLanguageParams] = useState<
        IAdminLanguages
    >(ADMIN_LANGUAGES[0]);

    const updateLanguage = useCallback(language => {
        const position = ADMIN_LANGUAGES.findIndex(
            lang => lang.code === language,
        );

        if (position === -1) return;

        setSelectedLanguageId(ADMIN_LANGUAGES[position].id);
        setSelectedLanguage(language);
        setSelectedLanguageParams(ADMIN_LANGUAGES[position]);
        setLocalizeLanguage(language);
    }, []);

    const updateLanguageById = useCallback(
        id => {
            const pos = ADMIN_LANGUAGES.findIndex(lang => lang.id === id);

            updateLanguage(ADMIN_LANGUAGES[pos].code);
        },
        [updateLanguage],
    );

    useEffect(() => {
        prefetchLocalizeLanguage(ADMIN_LANGUAGES.map(l => l.code));
    }, []);

    useEffect(() => {
        setAvailableLanguage(getAvailableLocalizeLanguages());

        const localizeLanguageSetting = getLocalizeLanguage();

        if (localizeLanguageSetting !== 'source') {
            updateLanguage(localizeLanguageSetting);
        } else {
            const browserLanguage = navigator.language.split('-')[0];
            const isValidLanguage =
                ADMIN_LANGUAGES.findIndex(
                    lang => lang.code === browserLanguage,
                ) >= 0;

            if (isValidLanguage) {
                updateLanguage(browserLanguage);
            }
        }
    }, [updateLanguage]);

    const values = useMemo(
        () => ({
            availableLanguages,
            selectedLanguage,
            selectedLanguageId,
            updateLanguage,
            updateLanguageById,
            selectedLanguageParams,
        }),
        [
            availableLanguages,
            selectedLanguage,
            selectedLanguageId,
            updateLanguage,
            updateLanguageById,
            selectedLanguageParams,
        ],
    );

    return <LanguageContext.Provider value={values} {...props} />;
};

const useLanguage = (): {
    availableLanguages: LocalizeLanguages[];
    selectedLanguage: string;
    selectedLanguageId: string;
    updateLanguage: (lang: string) => void;
    updateLanguageById: (id: string) => void;
    selectedLanguageParams: IAdminLanguages;
} => {
    const context = useContext(LanguageContext);

    if (context === undefined) {
        throw new Error(`useLanguage must be used within a LanguageProvider`);
    }
    return context;
};

export {LanguageProvider, useLanguage};
