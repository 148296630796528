import {useQuery} from 'react-query';
import {getToday} from 'helpers/dates';
import {useClient} from 'context/auth-context';

const useGetHolidays = (currencies: string[]) => {
    const client = useClient();
    const queryParams = [];
    const after = getToday();

    queryParams.push(`date[after]=${after}`);
    currencies.forEach(curr => {
        if (curr) {
            queryParams.push(`currency[]=${curr}`);
        }
    });

    return useQuery(
        ['holidays', {...currencies, after}],
        () =>
            client(
                `/web_api/holidays?${queryParams.join('&')}`,
                {},
            ).then(response => response.map(holiday => holiday.date)),
        {enabled: currencies.length},
    );
};

export {useGetHolidays};
