import React from 'react';
import {useHistory} from 'react-router';
import classNames from 'classnames';
import {Alert, Button, Col, Modal, Row} from 'react-bootstrap';
import {formatDateInZurichTZ} from 'helpers/dates';
import CurrencyFlag from 'components/ui/CurrencyFlag/CurrencyFlag';
import {formatNumber} from 'helpers/common';
import {PATH} from 'constants/common';
import {PayoutType} from 'types/payments';
import FaqLink from 'components/ui/FaqLink/FaqLink';
import useModal from 'components/ui/ModalContainer/useModal';
import styles from './TransactionConfirmationModal.module.scss';

interface IProps {
    activeDeal?: any;
    isFromPayment: boolean;
    onConfirm: (resetForm?: boolean) => void;
    activeBpId: string;
    contactId?: string;
    showPayToOwn?: boolean;
    isEbillEnabled?: boolean;
}

const allowedCurrencies = ['CHF', 'EUR'];

const TransactionConfirmationModal = ({
    activeDeal,
    isFromPayment,
    contactId,
    showPayToOwn = false,
    isEbillEnabled = false,
    onConfirm,
}: IProps) => {
    const history = useHistory();
    const {closeModal} = useModal();

    if (!activeDeal) return null;

    const {
        amountFrom,
        amountTo,
        currencyFrom,
        currencyTo,
        spotRate,
        valueDate,
        savedAmount,
        transaction: {id},
    } = activeDeal;

    const payOwn = () => {
        history.push({
            pathname: PATH.PAYOUT,
            search: new URLSearchParams({
                contactId,
                step: '1',
                paymentType: PayoutType.TRADITIONAL,
                currency: currencyTo,
                amount: amountTo,
                executionDate: formatDateInZurichTZ(valueDate),
                reference: `FX-${id}`,
            }).toString(),
        });
        closeModal();
    };

    const handleClose = () => {
        if (onConfirm) onConfirm();
        closeModal();
    };

    const selectBeneficiary = () => {
        history.push(PATH.PAYOUT);
        closeModal();
    };
    const isShowCollect =
        allowedCurrencies.indexOf(currencyFrom) !== -1 && isEbillEnabled;

    return (
        <>
            <Modal.Header closeButton={false}>
                <Modal.Title as="h5" className={styles.modalHeader}>
                    Confirmation Transaction No.{' '}
                    <var data-var="transactionId">{id}</var>
                    <FaqLink url="/articles/5744684-what-do-i-need-to-do-after-confirming-the-currency-exchange-how-can-i-send-money-to-amnis" />
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row>
                    <Col md={12}>
                        <Alert variant="success" className={styles.message}>
                            <div>
                                The transaction was completed successfully
                            </div>
                            <small>
                                Compared to standard banking margins, you have
                                just saved{' '}
                                <var data-var="amountInCurrency">
                                    {currencyFrom} {savedAmount}
                                </var>
                                .
                            </small>
                        </Alert>
                    </Col>
                </Row>

                <Row>
                    <Col
                        className={classNames(styles.colData, styles.bold)}
                        xs={6}
                    >
                        <div>You buy (credit)</div>
                    </Col>
                    <Col
                        className={classNames(styles.colData, styles.bold)}
                        xs={6}
                    >
                        <div>
                            <CurrencyFlag code={currencyTo} />{' '}
                            <span data-notranslate>
                                {formatNumber(amountTo)}
                            </span>
                        </div>
                    </Col>
                    <Col
                        className={classNames(styles.colData, styles.bold)}
                        xs={6}
                    >
                        <div>You sell (debit)</div>
                    </Col>
                    <Col
                        className={classNames(styles.colData, styles.bold)}
                        xs={6}
                    >
                        <div>
                            <CurrencyFlag code={currencyFrom} />{' '}
                            <span data-notranslate>
                                {formatNumber(amountFrom)}
                            </span>
                        </div>
                    </Col>
                    <Col className={styles.colData} xs={6}>
                        <div>Your exchange rate</div>
                    </Col>
                    <Col className={styles.colData} xs={6}>
                        <div data-notranslate>{formatNumber(spotRate, 4)}</div>
                    </Col>
                    <Col className={styles.colData} xs={6}>
                        <div>Value date</div>
                    </Col>
                    <Col className={styles.colData} xs={6}>
                        <div data-notranslate>
                            {formatDateInZurichTZ(valueDate)}
                        </div>
                    </Col>
                </Row>

                <div className="mt-3">
                    {isShowCollect ? (
                        <>
                            <div>
                                <b>We will send you an eBill shortly.</b>
                            </div>
                            <br />
                        </>
                    ) : null}

                    {isFromPayment ? (
                        <div>
                            The {currencyTo} amount will be credited on your
                            currency account.
                        </div>
                    ) : (
                        <>
                            <div>
                                The {currencyTo} amount will be credited on your
                                currency account. Do you want to schedule a new
                                payment?
                            </div>

                            <div className={styles.buttons}>
                                {showPayToOwn ? (
                                    <>
                                        <Button
                                            variant="primary"
                                            onClick={payOwn}
                                        >
                                            Pay To Own Account
                                        </Button>

                                        <span className="ml-2 mr-2">OR</span>
                                    </>
                                ) : null}
                                <Button
                                    className="btn btn-primary"
                                    onClick={selectBeneficiary}
                                >
                                    Select Beneficiary
                                </Button>
                            </div>
                        </>
                    )}
                </div>
            </Modal.Body>

            <Modal.Footer>
                <div className="d-flex justify-content-end">
                    <Button variant="outline-primary" onClick={handleClose}>
                        Pay to Amnis funding balance
                    </Button>
                </div>
            </Modal.Footer>
        </>
    );
};

export default TransactionConfirmationModal;
