import {useMutation, queryCache} from 'react-query';
import {useClient} from 'context/auth-context';
import {IDealRequest} from 'types/order';
import {formatDateInZurichTZ} from 'helpers/dates';
import {
    PENDING_TRANSACTIONS_CACHE,
    PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE,
} from 'constants/cache';
import {IFxTransactionDetail} from 'types/transactions';
import {PATCH_HEADERS} from './constants';

const useDealRequest = () => {
    const client = useClient();

    return useMutation(data =>
        client('/web_api/deal_requests', {data, method: 'POST'}).then(
            response => {
                return {
                    ...response,
                    currencyFrom: response.currencyFrom.split('/').pop(),
                    currencyTo: response.currencyTo.split('/').pop(),
                };
            },
        ),
    );
};

const useConfirmDealRequest = () => {
    const client = useClient();

    return useMutation(
        (dealRequest: IDealRequest) =>
            client(`${dealRequest['@id']}/confirm`, {
                data: {},
                method: 'PATCH',
                ...PATCH_HEADERS,
            }).then(response => {
                return {
                    ...response,
                    currencyFrom: response.currencyFrom.split('/').pop(),
                    currencyTo: response.currencyTo.split('/').pop(),
                };
            }),
        {
            onSuccess: () =>
                queryCache.invalidateQueries([PENDING_TRANSACTIONS_CACHE]),
        },
    );
};

const useChangeDealDateRequest = () => {
    const client = useClient();

    return useMutation((transaction: IFxTransactionDetail) =>
        client(`${transaction.dealRequest['@id']}/change`, {
            data: {
                valueDate: transaction.valueDate,
            },
            method: 'PATCH',
            ...PATCH_HEADERS,
        }).then(response => ({
            ...response,
            valueDate: formatDateInZurichTZ(response.valueDate),
        })),
    );
};

const useSplitDealRequest = () => {
    const client = useClient();

    return useMutation(
        (transaction: IFxTransactionDetail) =>
            client(`${transaction.dealRequest['@id']}/split`, {
                data: {
                    amountFrom: transaction.amountFrom,
                    amountTo: transaction.amountTo,
                    valueDate: transaction.valueDate,
                },
                method: 'PATCH',
                ...PATCH_HEADERS,
            }).then(response => ({
                ...response,
                valueDate: formatDateInZurichTZ(response.valueDate),
            })),
        {
            onSuccess: () =>
                queryCache.invalidateQueries(PENDING_TRANSACTIONS_CACHE),
        },
    );
};

const useCancelDealRequest = () => {
    const client = useClient();
    return useMutation(
        (id: string) => {
            return client(`${id}/cancel`, {
                data: {},
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/vnd.api+json',
                },
            });
        },
        {
            onSuccess: () => {
                queryCache.invalidateQueries([PENDING_TRANSACTIONS_CACHE]);
                queryCache.invalidateQueries([
                    PENDING_TRANSACTIONS_DETAILS_LIMIT_ORDERDS_CACHE,
                ]);
            },
        },
    );
};

const useCollectEBill = () => {
    const client = useClient();

    // TODO type - for some reason wouldn't work without an explicit type
    return useMutation((data: any) => {
        return client('/web_api/ebills', {
            data,
            method: 'POST',
        });
    });
};

export {
    useDealRequest,
    useConfirmDealRequest,
    useChangeDealDateRequest,
    useSplitDealRequest,
    useCancelDealRequest,
    useCollectEBill,
};
